<template>
  <div class="my wh100">
    <!-- <navBar title="个人中心"></navBar> -->
    <div class="my_content">
      <div class="my_top">
        <div class="top_avatar" @click="goPersonData">
          <img :src="info.avatarUrl?info.avatarUrl:defaultAvatar" alt="" />
        </div>
        <div class="top_info">
          <div class="info_top">
            <div class="top_left" @click="goPersonData">
              <span>{{ info.nickname? info.nickname:'匿名'}}</span>
              <i class="iconfont icon-bi"></i>
            </div>
            <!-- <div class="top_right" @click="goRecharge">
              <span>去充值</span>
            </div> -->
          </div>
          <div class="ID">
            <span>ID:</span><span>{{ info.id }}</span>
          </div>
          <!-- <div class="info_bottom">
            <div class="bottom_item">
              <span>祭祀币:</span>
              <span>{{info.sacrificeCoinNum}}</span>
            </div>
            <div class="bottom_item">
              <span>纪念币:</span>
              <span>{{info.commemorateCoinNum}}</span>
            </div>
          </div> -->
        </div>
      </div>
      <div class="my_bottom">
        <div class="bottom_wrap">
          <div class="memorial_halls">
            <div class="halls_item" @click="goMyManage">
              <i class="iconfont icon-management"></i>
              <span>我管理的纪念馆</span>
            </div>
            <div class="halls_item" @click="goMyFollow">
              <i class="iconfont icon-xihuan"></i>
              <span>我关注的纪念馆</span>
            </div>
          </div>
          <div class="menus">
            <ul class="menus_list">
              <!-- <li v-for="(item, index) in menus" :key="index">
                <router-link class="list_item" :to="item.path">
                  <div class="item_left">
                    <i class="iconfont" :class="item.icon"></i>
                    <span>{{ item.title }}</span>
                  </div>
                  <div class="item_right">
                    <van-icon name="arrow" />
                  </div>
                </router-link>
              </li>
              <li>
                <router-link class="list_item" to="/my/myHome">
                  <div class="item_left">
                    <i class="iconfont icon-jiating" ></i>
                    <span>我的家园</span>
                  </div>
                  <div class="item_right">
                    <van-icon name="arrow" />
                  </div>
                </router-link>
              </li> -->
              <li>
                <router-link class="list_item" to="/my/messageManagement">
                  <div class="item_left">
                    <i class="iconfont icon-liuyanfill"></i>
                    <span>留言管理</span>
                  </div>
                  <div class="item_right">
                    <van-icon name="arrow" />
                  </div>
                </router-link>
              </li>
              <li>
                <router-link class="list_item" to="/my/eulogyManagement">
                  <div class="item_left">
                    <i class="iconfont icon-dingdanliebiao-tianchong"></i>
                    <span>悼文管理</span>
                  </div>
                  <div class="item_right">
                    <van-icon name="arrow" />
                  </div>
                </router-link>
              </li>
              <li>
                <router-link class="list_item" to="/my/personalData">
                  <div class="item_left">
                    <i class="iconfont icon-ziliao"></i>
                    <span>个人资料</span>
                  </div>
                  <div class="item_right">
                    <van-icon name="arrow" />
                  </div>
                </router-link>
              </li>
              <li>
                <router-link class="list_item" :to="forgetPasswordPath">
                  <div class="item_left">
                    <i class="iconfont icon-xiugaimima"></i>
                    <span>修改密码</span>
                  </div>
                  <div class="item_right">
                    <van-icon name="arrow" />
                  </div>
                </router-link>
              </li>
              <!-- <li
                class="list_item"
                @click="goExamineList"
                v-if="info.userType == 'manage'"
              >
                <div class="item_left">
                  <i class="iconfont icon-yuyuejilu1"></i>
                  <span>审核列表</span>
                </div>
                <div class="item_right">
                  <van-icon name="arrow" />
                </div>
              </li> -->
              <!-- && isWeXin -->
              <li
                class="list_item"
                @click="wxScanCode"
                v-if="info.userType == 'manage'"
              >
                <div class="item_left">
                  <i class="iconfont icon-Scan-code"></i>
                  <span>预约核销</span>
                </div>
                <div class="item_right">
                  <van-icon name="arrow" />
                </div>
              </li>
              
            </ul>
          </div>
          <div class="btn_box" @click="goOut">
            <div class="logout_btn"><span>退出登录</span></div>
          </div>
        </div>
      </div>
    </div>
    <tabBar :activeId="3"/>
  </div>
</template>

<script>
import { clearLocalStorage } from "@/utils/auth";
import { logout } from "@/api/user";
import navBar from "@/components/navBar";
import tabBar from "@/components/tabBar";
import wx from "weixin-jsapi";
import { wxSignatureVerification } from "@/api/wx";
import { writeOff } from "@/api/my";
import { getUserInfo } from "@/api/user";
export default {
  name: "my",
  data() {
    return {
      menus: [
        { id: 1, title: "我的家园", icon: "icon-jiating", path: "/my/myHome" },
        {
          id: 2,
          title: "留言管理",
          icon: "icon-liuyanfill",
          path: "/my/messageManagement",
        },
        {
          id: 3,
          title: "悼文管理",
          icon: "icon-dingdanliebiao-tianchong",
          path: "/my/eulogyManagement",
        },
        {
          id: 4,
          title: "个人资料",
          icon: "icon-ziliao",
          path: "/my/personalData",
        },
        {
          id: 5,
          title: "陵园绑定",
          icon: "icon-yuanqufuwu1",
          path: "/my/cemeteryBinding",
        },
        {
          id: 6,
          title: "修改密码",
          icon: "icon-xiugaimima",
          path: `/forgetPassword`,
        },
      ],
      defaultAvatar: require("../../assets/imgs/avatar.png"),
      info: {},
      forgetPasswordPath: "",
    };
  },
  components: {
    navBar,
    tabBar,
  },
  created() {
    this.getUserInfo();
  },
  computed: {
    isWeXin () {
      let ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true
      }else {
        return false
      }
    }
  },
  mounted() {
    // var ua = window.navigator.userAgent.toLowerCase();
    // if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //   var u = navigator.userAgent,
    //     app = navigator.appVersion;
    //   var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    //   if (isIOS) {
    //     if (window.location.href.indexOf("#reloaded") == -1) {
    //       window.location.href = window.location.href + "#reloaded";
    //       window.location.reload();
    //     }
    //   }
    // }
  },
  methods: {
    //管理员审核列表
    goExamineList(){
      this.$router.push('/my/examineList')
    },
    async getUserInfo() {
      let res = await getUserInfo({});
      console.log(res, "用户信息");
      if (res.code != "2000") {
        this.$toast.fail(res.msg || "数据请求失败");
      }
      this.info = res.data;
      // this.forgetPasswordPath=`/forgetPassword/${res.data.phone}`
      this.forgetPasswordPath = `/alterPassword/${res.data.phone}`;
      if (res.data.userType == "manage") {
        //document.getElementById('cnzz_stat_icon_1279803362').style.display='block'
      }
    },
    //退出登录
    goOut() {
      this.$dialog
        .confirm({
          title: "退出登录?",
          message: "确定退出吗",
        })
        .then(async () => {
          //console.log(id, "退出");
          let res = await logout({});
          if (res.code == "2000") {
            this.$toast.success("退出成功");
            localStorage.removeItem("token");
            localStorage.removeItem("code");
            // clearLocalStorage();
            this.$router.replace("/login");
          } else {
            this.$toast.fail(res.msg || "数据请求失败");
          }
        })
        .catch(() => {
          this.$toast.fail("取消退出登录");
        });
    },
    //去充值
    goRecharge() {
      this.$toast("暂未开放");
      // this.$router.push("/my/recharge");
    },
    //
    goPersonData() {
      this.$router.push("/my/personalData");
    },
    //我管理的纪念馆
    goMyManage() {
      this.$router.push("/my/iManageMemorialHalls");
    },
    //我关注的纪念馆
    goMyFollow() {
      this.$router.push("/my/iFollowMemorialHalls");
    },
    //微信扫一扫
    wxScanCode() {
      window.location.href = '/my/reservationWriteOff'
    //   let params = {
    //     url: window.location.href.split("#")[0],
    //   };
    //   //调后端接口返回所需要的参数
    //   wxSignatureVerification({ ...params }).then((res) => {
    //     console.log("rescode", res);
    //     // 后端返回的参数
    //     if (res.code != "2000") return this.$toast.fail("微信验签失败");
    //     let result = res.data.wxConfig;
    //     wx.config({
    //       debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //       appId: res.data.appId, // 必填，公众号的唯一标识
    //       timestamp: result.timestamp, // 必填，生成签名的时间戳
    //       nonceStr: result.nonceStr, // 必填，生成签名的随机串
    //       signature: result.signature, // 必填，签名
    //       jsApiList: [
    //         "checkJsApi",
    //         "onMenuShareTimeline",
    //         "onMenuShareAppMessage",
    //         "onMenuShareQQ",
    //         "onMenuShareWeibo",
    //         "hideMenuItems",
    //         "showMenuItems",
    //         "hideAllNonBaseMenuItem",
    //         "showAllNonBaseMenuItem",
    //         "translateVoice",
    //         "startRecord",
    //         "stopRecord",
    //         "onRecordEnd",
    //         "playVoice",
    //         "pauseVoice",
    //         "stopVoice",
    //         "uploadVoice",
    //         "downloadVoice",
    //         "chooseImage",
    //         "previewImage",
    //         "uploadImage",
    //         "downloadImage",
    //         "getNetworkType",
    //         "openLocation",
    //         "getLocation",
    //         "hideOptionMenu",
    //         "showOptionMenu",
    //         "closeWindow",
    //         "scanQRCode",
    //         "chooseWXPay",
    //         "openProductSpecificView",
    //         "addCard",
    //         "chooseCard",
    //         "openCard",
    //       ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    //     });
    //     wx.ready(() => {
    //       wx.scanQRCode({
    //         needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
    //         scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
    //         success: async (res) => {
    //           var result = window.atob(res.resultStr);
    //           let params = {
    //             appointmentCode: result,
    //           };
    //           let res1 = await writeOff(params);
    //           if (res1.code != "2000") {
    //             this.$toast.fail(res1.msg);
    //             return;
    //           }
    //           this.$router.push({
    //             path: `/appointment/appointmentInfo/${res1.data.userAppointmentInfoId}`,
    //           });
    //         },
    //         error: (res) => {
    //           //console.log(res);
    //         },
    //       });
    //     });
    //   });
    },
  },
  beforeDestroy() {
   // document.getElementById("cnzz_stat_icon_1279803362").style.display = "none";
  },
};
</script>
<style lang="scss" scoped>
.my {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  /deep/ .van-hairline--bottom::after {
    border-bottom-width: 0;
    border: none;
  }
  .my_content {
    flex: 1;
    overflow-y: scroll;
    padding-bottom: 0.5rem;
    .my_top {
      padding-top: 0.33rem;
      padding-left: 0.18rem;
      padding-bottom: 0.2rem;
      color: #fff;
      background: $defaultColor;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      border-bottom-right-radius: 0.3rem;
      .top_avatar {
        width: 0.6rem;
        height: 0.6rem;
        margin-right: 0.08rem;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .top_info {
        flex: 1;
        .info_top {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -moz-justify-content: space-between;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
          .top_left {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -moz-align-items: center;
            -webkit-align-items: center;
            align-items: center;
            font-size: 15px;
            line-height: 0.4rem; 
            .iconfont {
              margin-left: 0.05rem;
            }
          }
          .top_right {
            width: 0.75rem;
            height: 0.25rem;
            border-top-left-radius: 0.125rem;
            border-bottom-left-radius: 0.125rem;
            background: #83d09f;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -moz-justify-content: center;
            -webkit-justify-content: center;
            justify-content: center;
            -moz-align-items: center;
            -webkit-align-items: center;
            align-items: center;
            font-size: 14px;
          }
        }
        .ID {
          font-size: 12px;
          line-height: 0.17rem;
          margin-bottom: 0.06rem;
        }
        .info_bottom {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
          .bottom_item {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -moz-align-items: center;
            -webkit-align-items: center;
            align-items: center;
            margin-right: 0.18rem;
            font-size: 12px;
            line-height: 0.17rem;
          }
        }
      }
    }
    .my_bottom {
      background: $defaultColor;
      .bottom_wrap {
        width: 100%;
        background: #fff;
        border-top-left-radius: 0.3rem;
        padding: 0.15rem;
        .memorial_halls {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          justify-content: space-around;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
          .halls_item {
            width: 1.65rem;
            height: 0.6rem;
            border: 1px solid #f7f7f7;
            border-radius: 0.08rem;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -moz-align-items: center;
            -webkit-align-items: center;
            align-items: center;
            font-size: 14px;
            line-height: 0.2rem;
            -moz-justify-content: center;
            -webkit-justify-content: center;
            justify-content: center;
            box-shadow: 0px 0px 0.1rem 0.05rem #f0f0f0;
            .iconfont {
              color: $defaultColor;
              font-size: 22px;
            }
          }
        }
        .menus {
          .menus_list {
            .list_item {
              height: 0.5rem;
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -moz-align-items: center;
              -webkit-align-items: center;
              align-items: center;
              -moz-justify-content: space-between;
              -webkit-justify-content: space-between;
              justify-content: space-between;
              border-bottom: 1px solid #ededed;
              .item_left {
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -moz-align-items: center;
                -webkit-align-items: center;
                align-items: center;
                .iconfont {
                  color: $defaultColor;
                  font-size: 20px;
                  margin-right: 0.09rem;
                }
              }
            }
          }
        }
        .btn_box {
          margin-top: 0.2rem;
          .logout_btn {
            width: 100%;
            height: 0.44rem;
            background: $defaultColor;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -moz-justify-content: center;
            -webkit-justify-content: center;
            justify-content: center;
            -moz-align-items: center;
            -webkit-align-items: center;
            align-items: center;
            color: #fff;
            box-shadow: $boxShow;
            border-radius: 0.1rem;
          }
        }
      }
    }
  }
}
</style>
