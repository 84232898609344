import request from "@/utils/request";

//获取信息
export function memorialInfo_manage(data) {
  return request({
    url: "/app/memorialInfo/manage",
    method: "post",
    data,
  });
}
//亲友团管理(跳转)

export function memorialInfo_RelativesFriends(data) {
  return request({
    url: "/app/memorialInfo/RelativesFriends",
    method: "post",
    data,
  });
}
//退出纪念馆
export function memorialInfo_relevancyState(data) {
  return request({
    url: "/app/memorialInfo/relevancyState",
    method: "post",
    data,
  });
}
