<template>
  <div class="appointmentHistory wh100">
    <navBar title="预约历史" :leftArrowFlag="true" @clickNavBarRight="goAppointment">
      <i class="iconfont icon-yuyuezhongxin"></i>
    </navBar>
    <div class="content">
      <van-tabs v-model="active" animated swipeable :before-change="beforeChange" @click="tabChange" @change="tabChange">
      <van-tab title="未使用" name="0">
        <div class="list_content">
         <vantList  :list='list' :loading='loading' :finished='finished' :refreshing='refreshing' @getList='getList' @refresh='refresh'>
           <div class="item" v-for="(item,index) in list" :key="index">
             <historyItem :item='item'></historyItem>
           </div>
         </vantList>
        </div>
      </van-tab>
      <van-tab title="已使用" name="1">
          <div class="list_content">
         <vantList  :list='list' :loading='loading' :finished='finished' :refreshing='refreshing' @getList='getList' @refresh='refresh'>
           <div class="item" v-for="(item,index) in list" :key="index">
             <historyItem :item='item'></historyItem>
           </div>
         </vantList>
        </div>
      </van-tab>
      <van-tab title="已超时" name="2">
        <div class="list_content">
         <vantList  :list='list' :loading='loading' :finished='finished' :refreshing='refreshing' @getList='getList' @refresh='refresh'>
           <div class="item" v-for="(item,index) in list" :key="index">
             <historyItem :item='item'></historyItem>
           </div>
         </vantList>
        </div>
      </van-tab>
    </van-tabs>
    </div>
    
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import vantList from '@/components/list';
import historyItem from './components/historyItem'
import { getAppointmentList } from '@/api/appointment/appointmentQuery'
import {} from '@/api/user'
export default {
  name: "news",
  data() {
    return {
      active:0,//是否使用0:未使用; 1:已使用; 2:已过期
      list: [
       
      ],
      loading:false,
      finished:false,
      refreshing:false,
      pageSize: 10,
      currentPage: 1,
    };
  },
  components: {
    navBar,
    vantList,
    historyItem
  },
 created(){
   let historyType= sessionStorage.getItem('historyType')
    if(historyType){
      this.active=historyType
    }else{
      sessionStorage.setItem('historyType',0)
    }
    this.getList()
  },
  methods: {
    //tab切换之前
    beforeChange(){
      if(this.loading){
        return false
      }
    },
    //tab切换
    tabChange(name,title){
      sessionStorage.setItem('historyType',name)
      this.list=[];
      this.active=name;
      this.currentPage=1;
      this.finished = false;
      this.loading = false;
      this.refreshing=false;
      this.getList()
    },
    //请求数据
    async getList(){
       this.loading=null
     const { currentPage, pageSize } = this;
      let params = {
        currentPage,
        pageSize,
        type:'appointmentSacrifice',
        asUse:this.active
      };
      console.log(params,'2222')
      let res = await getAppointmentList(params);
      console.log(res,'3333')
    
      if(res.code!='2000'){
        this.$toast.fail(res.msg || "数据请求失败")
        return 
      }
       let list =  res.data|| [];
        this.list=[...this.list,...list];
        //根据总条数处理
        if(pageSize>list.length){
          
          this.finished = true;
        }else{
          this.currentPage++
          this.finished = false;
        }
        this.loading = false;
      this.refreshing=false;

    },
    //下拉刷新
    refresh(){
      this.refreshing=true;
      // 清空列表数据
      this.currentPage=1;
      this.list=[];
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
    //返回预约中心
    goAppointment(){
      this.$router.push('/appointment')
    }
  },
};
</script>

<style lang="scss" scoped>
.appointmentHistory {
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
    .iconfont{
      font-size: 24px;
    }
    .content{
      flex:1;
      // overflow: hidden;
      display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
      -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
      overflow-y: scroll;
    }
    /deep/ .van-tabs{
        flex:1;
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
        overflow: hidden;
        .van-tabs__content {
            flex:1;
            overflow-y: scroll;
            .van-tab__pane{
                height: 100%;
            }
        }
    }
  /deep/ .van-tabs--line .van-tabs__wrap{
    padding: 0.1rem 0;
    height: auto;
    .van-tabs__nav{
      justify-content: space-around;
      padding-bottom: 0;
      .van-tabs__line{
        display: none;
      }
      .van-tab{
        width: 0.8rem;
        height:0.3rem;
        background: #EFEFEF;
        color: $lightBlack;
        flex:none;
        border-radius: 0.05rem;
      }
      .van-tab--active{
        background: $defaultColor;
        color: #fff;
      }
    }
    
  }
  .list_content{
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: #F9F9F9;
    // height: calc(100vh - 1.4rem);
    padding: 0 0.125rem;
    height: 100%;
    width: 100%;
   /deep/ .van-list{
    width:100%;
    .list_item{
      width: 100%;
      height: 0.8rem;
    }
  } 

  }
  
  
}
</style>