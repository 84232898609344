import request from "@/utils/request";

//获取信息
export function getMyFollow(data) {
  return request({
    url: "/app/memorialInfo/follow",
    method: "post",
    data,
  });
}
