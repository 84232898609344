// 用户预约扫墓 时间配置
import request from "@/utils/request";

//保存预约信息 返回预约码
export function appointmentSacrifice_sacrificeDetail(data) {
  return request({
    url: "/sys/appointmentSacrifice/sacrificeDetail",
    method: "post",
    data,
  });
}
