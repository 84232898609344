import request from "@/utils/request";

//预约查询页面接口
//列表
export function getAppointmentList(data) {
  return request({
    url: "/sys/appointmentSacrifice/userAppointmentTypeList",
    method: "post",
    data,
  });
}
//预约详情
export function getAppointmentDetail(data) {
  return request({
    url: "/sys/appointmentSacrifice/userAppointmentTypeDetail",
    method: "post",
    data,
  });
}
//代为祭扫(订单)详情
export function getOrderDetail(data) {
  return request({
    url: "/sys/appointmentSacrifice/orderSacrificeDetail",
    method: "post",
    data,
  });
}
//取消订单(修改状态)
export function orderSacrificeManage_operation(data) {
  return request({
    url: "app/orderSacrificeManage/operation",
    method: "post",
    data,
  });
}
