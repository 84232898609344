<template>
  <div class="memorialHallSetting wh100">
    <navBar title="纪念馆设置" :leftArrowFlag="true" />
    <div class="content">
      <div class="content_item" @click="goSelectMusic(id)">
        <div class="item_left">
          <i class="iconfont icon-yinle"></i>
          <span>选择音乐</span>
        </div>
        <div class="item_right">
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="content_item" @click="goSelectHomeBg(id)">
        <div class="item_left">
          <i class="iconfont icon-icon_pic"></i>
          <span>纪念馆首页背景</span>
        </div>
        <div class="item_right">
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="content_item" @click="goSelectWorshipBg(id)">
        <div class="item_left">
          <i class="iconfont icon-tupian"></i>
          <span>纪念馆祭拜背景</span>
        </div>
        <div class="item_right">
          <van-icon name="arrow" />
        </div>
      </div>
      <div
        v-if="setting.memorialType === 1"
        class="content_item"
        @click="goUpgradeDoubleRoom(id)"
      >
        <div class="item_left">
          <i class="iconfont icon-fangjian"></i>
          <span>升级双人馆</span>
        </div>
        <div class="item_right">
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="content_item">
        <div class="item_left">
          <span>是否公开</span>
        </div>
        <div class="item_right">
          <van-switch
            active-color="#04BE02"
            :active-value="1"
            :inactive-value="0"
            inactive-color="#A4A4A4"
            v-model="setting.asPublic"
          />
        </div>
      </div>
      <div class="content_item">
        <div class="item_left">
          <span>是否允许亲友团加入</span>
        </div>
        <div class="item_right">
          <van-switch
            active-color="#04BE02"
            :active-value="1"
            :inactive-value="0"
            inactive-color="#A4A4A4"
            v-model="setting.asJoin"
          />
        </div>
      </div>
      <div class="content_item">
        <div class="item_left">
          <span>是否允许亲友团编辑纪念馆</span>
        </div>
        <div class="item_right">
          <van-switch
            active-color="#04BE02"
            :active-value="1"
            :inactive-value="0"
            inactive-color="#A4A4A4"
            v-model="setting.asEdit"
          />
        </div>
      </div>
      <div
        v-if="setting.isDelete === 'yes' && isDelete"
        class="large_btn"
        @click="deleteHall"
      >
        <span>删除纪念馆</span>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import {
  memorialInfoDetail,
  setMemorialInfo,
} from "@/api/memorialHall/memorialHallSetting";
export default {
  data() {
    return {
      id: "",
      setting: {
        memorialType: 2,
        asPublic: 1,
        asJoin: 0,
        asEdit: 0,
        isDelete: "no",
      },
      isDelete: true,
    };
  },
  components: {
    navBar,
  },
  created() {
    this.id = this.$route.params.id;
    this.isDelete = this.$route.query.isDelete == "no" ? false : true;

    this.load();
    this.setWatch("asPublic");
    this.setWatch("asJoin");
    this.setWatch("asEdit");
  },
  methods: {
    async load() {
      try {
        const { data } = await memorialInfoDetail({ id: this.id });
        console.log(data, "馆设置");
        let { memorialType, asEdit, asPublic, asJoin, isDelete } = data;
        this.setting = { memorialType, asEdit, asPublic, asJoin, isDelete };
      } catch (e) {
        console.log(e);
      }
    },
    //监听
    setWatch(key) {
      this.$watch("setting." + key, (n) => {
        let params = {};
        params[key] = n;
        this.setInfo(params);
      });
    },
    //设置信息接口
    setInfo(params) {
      setMemorialInfo({ id: this.id, ...params }).then(() => {
        this.$toast.success("设置成功");
      });
    },
    //选择音乐
    goSelectMusic(id) {
      this.$router.push(`/memorialHall/selectMusic/${id}`);
    },
    //选择首页背景
    goSelectHomeBg(id) {
      this.$router.push(`/memorialHall/selectHomeBg/${id}`);
    },
    //选择祭拜背景
    goSelectWorshipBg(id) {
      this.$router.push(`/memorialHall/selectWorshipBg/${id}`);
    },
    //升级双人馆
    goUpgradeDoubleRoom(id) {
      this.$dialog
        .confirm({
          title: "升级双人馆",
          message: "您确定升级双人馆吗？",
        })
        .then(() => {
          this.$router.push(`/memorialHall/upgradeDoubleRoom/${id}`);
        })
        .catch(() => {
          // on cancel
          this.$toast.fail("取消升级");
        });
    },
    deleteHall() {
      this.$dialog
        .confirm({
          title: "删除操作",
          message: "您确定删除该纪念馆吗？",
        })
        .then(() => {
          // on confirm
          setMemorialInfo({ id: this.id, deleted: 1 }).then(() => {
            this.$toast.success("删除成功");
            // this.load()
            this.$router.go(-2);
          });
        })
        .catch(() => {
          // on cancel
          this.$toast.fail("取消删除");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.memorialHallSetting {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  .content {
    flex: 1;
    padding: 0.1rem;
    .content_item {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -moz-align-items: center;
      -webkit-align-items: center;
      align-items: center;
      padding: 0.15rem 0;
      border-bottom: 1px solid #ededed;
      .item_left {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        .iconfont {
          color: $defaultColor;
          margin-right: 0.1rem;
        }
      }
      .item_right {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        /deep/ .van-icon {
          color: $lightBlack;
        }
        /deep/ .van-switch {
          width: 0.5rem;
          height: 0.25rem;
          border-radius: 0.25rem;
          .van-switch__node {
            width: 0.24rem;
            height: 0.24rem;
          }
        }
        /deep/ .van-switch--on .van-switch__node {
          transform: translateX(0.25rem);
        }
      }
    }
    .large_btn {
      margin-top: 1.5rem;
    }
  }
}
</style>
