<template>
  <div class="rechargeList wh100">
    <navBar
      title="充值历史"
      :leftArrowFlag="true"
    />
    <div class="list_content">
      <vantList
        :list="list"
        :loading="loading"
        :finished="finished"
        :refreshing="refreshing"
        @getList="getList"
        @refresh="refresh"
      >
        <div class="list" v-for="(item, index) in list" :key="index">
          <rechargeItem :item="item"></rechargeItem>
        </div>
      </vantList>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import vantList from "@/components/list";
import rechargeItem from "./components/rechargeItem";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      pageSize: 10,
      currentPage: 1,
      list: [
        {
          id: 1,
          title: "套餐一:10祭祀币",
          date:'2021-01-25 16:24:43',
          price:'-5.00',
          status:0
        },
        {
          id: 2,
          title: "套餐一:10祭祀币",
          date:'2021-01-25 16:24:43',
          price:'-5.00',
          status:0
        },
        {
          id: 3,
          title: "套餐一:10祭祀币",
          date:'2021-01-25 16:24:43',
          price:'-5.00',
          status:1
        },
      ],
    };
  },
  components: {
    navBar,
    vantList,
    rechargeItem
  },
  methods: {
     //请求数据
    async getList() {
      this.loading = true;
      const { currentPage, pageSize } = this;
      let params = {
        currentPage,
        pageSize,
      };
      let res = await getDeadList(params);
      this.loading = false;
      this.refreshing = false;
      if (res.code === 1) {
        let list = res.data.list || [];
        //根据总条数处理
        if (this.list.length < res.data.pager.total) {
          this.list = [...this.list, ...res.data.list];
          this.currentPage++;
        } else {
          this.finished = true;
          this.loading = false;
        }
        //根据总页数处理
        // if (this.currentPage > 1) {
        //   this.list = [...this.list, ...list];
        // } else {
        //   this.list = list;
        // }
        // // 如果当前页数 = 总页数，则已经没有数据
        // if (res.data.pager.currentPage == 6) {
        //   this.finished = true;
        //   this.loading = false;
        // }
        // // 如果总页数大于当前页码，页码+1
        // if (6 > this.currentPage) {
        //   this.currentPage++;
        // }
      }
    },
    //下拉刷新
    refresh() {
      this.refreshing = true;
      // 清空列表数据
      this.currentPage = 1;
      this.list = [];
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.rechargeList {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  .list_content{
    flex:1;
    padding: 0 0.125rem;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    /deep/ .van-list{
        width:100%;
        
    }
  }
}
</style>