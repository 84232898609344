import request from '@/utils/request'

//首页供奉商品分类
export function oblationList(data) {
  return request({
    url:'/app/oblation/list',
    method: 'post',
    data
  })
}

//获取纪念馆所有信息
export function getMemorialAllInfo(data) {
  return request({
    url:'/app/memorialInfo/getMemorialAllInfo',
    method: 'post',
    data
  })
}

//单独获取纪念馆关注状态
export function getFocusState(data) {
  return request({
    url:'/memorial/getFocusState',
    method: 'post',
    data
  })
}

//改变纪念馆关注状态
export function memorialFocus(data) {
  return request({
    url:'/memorial/focus',
    method: 'post',
    data
  })
}

//供奉
export function oblationSave(data) {
  return request({
    url:'/oblation/save',
    method: 'post',
    data
  })
}

//获取纪念馆分享
export function getShareData(data) {
  return request({
    url:'/app/memorialInfo/memorialInfoShare',
    method: 'post',
    data
  })
}

//留言
export function noteOperation(data) {
  return request({
    url:'/app/note/operation',
    method: 'post',
    data
  })
}
//加入亲友团弹框是否展示
export function showJoinPop(data) {
  return request({
    url:'/app/memorialInfo/ispopub',
    method: 'post',
    data
  })
}

//获取灯数据
export function worshipGoodsLight(data) {
  return request({
    url:'/app/worshipGoods/light',
    method: 'post',
    data
  })
}
//加入亲友团
export function joinFamily(data) {
  return request({
    url:'/memorial/saveMemorialRelativesFriends',
    method: 'post',
    data
  })
}

//点灯
export function worshipGoodsSave(data) {
  return request({
    url:'/app/worshipGoods/save',
    method: 'post',
    data
  })
}
//检测加入亲友团审核状态
export function checkJoinFamilyStatus(data) {
  return request({
    url:'/app/memorialInfo/changeMemorialUser',
    method: 'post',
    data
  })
}
//获取供奉的商品列表
export function fetchOfferingList(data) {
  return request({
    url:'/app/memorialInfo/worshipSpu',
    method: 'post',
    data
  })
}
//获取纪念馆操作相册权限
export function fetchMediaAuth(data) {
  return request({
    url:'/app/memorialInfo/checkEditPermission',
    method: 'post',
    data
  })
}
//获取纪念馆祭拜背景图
export function fetchWorshipBg(data) {
  return request({
    url:'/app/memorialInfo/graveDetail',
    method: 'post',
    data
  })
}
//切换纪念馆祭拜背景图
export function changeWorshipBg(data) {
  return request({
    url:'/app/memorialInfo/graveUpdate',
    method: 'post',
    data
  })
}
//获取纪念馆默认祭拜背景
export function fetchDefaultWorshipBg(data) {
  return request({
    url:'/app/memorialInfo/userFileInfoDetail',
    method: 'post',
    data
  })
}