<template>
  <div class="album wh100">
    <van-nav-bar
      title="相册"
      left-text="返回"
      left-arrow
      :right-text="rightText"
      @click-left="onClickLeft"
      @click-right="navAction"
    />
    <div class="content">
      <div class="content_main">
        <div class="tabs">
          <div class="tab" @click="goInfo"><span>简介</span></div>
          <div class="tab active"><span>相册</span></div>
          <div class="tab" @click="goEulogy"><span>悼文</span></div>
          <div class="tab" @click="goMessage"><span>留言</span></div>
        </div>
        <div class="media" v-if="medias.length > 0">
          <van-checkbox-group
            class="list"
            ref="checkboxGroup"
            v-model="selectResult"
            @change="selectChange"
          >
            <div
              class="media_item"
              v-for="(item, index) in medias"
              :key="index"
            >
              <div class="checkbox" v-show="flag">
                <van-checkbox :name="item.id"></van-checkbox>
              </div>
              <van-image
                v-if="item.fileUrl"
                @click="imgPreview(index)"
                class="media_img"
                fit="fill"
                :src="item.fileUrl"
              />
              <van-image
                v-else
                class="media_img"
                fit="fill"
                :src="defaultImg"
              />
            </div>
          </van-checkbox-group>
        </div>
        <van-empty v-else description="暂无数据" />
      </div>
      <div class="footer">
        <div class="footer_top" v-if="selectResult.length > 0">
          <div class="footer_left" @click="checkAll"><span>全选</span></div>
          <div class="footer_right" @click="deleteItems"><span>删除</span></div>
        </div>
        <div class="large_btn" @click="addClick" v-if="actionAuth">
          <span>添加照片</span>
          <input id="filePic" type="file" @change="changePhoto" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import navBar from "@/components/navBar";
import { ImagePreview } from "vant";
import { getUserId } from "@/utils/auth";
import { fetchMediaAuth } from "@/api/memorialHall";
import {
  memorialInfo_photoAlbum,
  memorialInfo_photoAlbum_save,
  memorialInfo_photoAlbum_delete,
} from "@/api/memorialHall/album";
export default {
  data() {
    return {
      flag: false,
      actionAuth: false, //操作权限
      startPosition: 0, //图片预览下标
      selectResult: [],
      pageSize: 20, //分页条数
      currentPage: 1, //当前页
      loginUserId: "",
      fileBase64List: [],
      medias: [],
      imgs: [],
      defaultImg: require("@/assets/imgs/deadImg.png"),
    };
  },
  // components: {
  //   navBar,
  // },
  computed: {
    rightText() {
      if (this.flag && this.actionAuth) {
        return "完成";
      } else if (this.actionAuth) {
        return "编辑";
      } else {
        return "";
      }
    },
  },
  created() {
    this.loginUserId = getUserId();
    this.onload();
    //获取相册操作权限
    this.getMediaAuth(this.$route.params.id);
  },
  methods: {
    //重新加载
    reload(){
      location.reload()
    },
    async getMediaAuth(id) {
      let params = {
        id,
      };
      let res = await fetchMediaAuth(params);
      console.log(res, "操作全先");
      if (res.code != "2000") {
        this.$toast.fail(res.msg || "请求数据失败");
        return;
      } else {
        this.actionAuth = res.data;
      }
    },
    async onload() {
      let postData = {
        memorialInfoId: this.$route.params.id, //纪念馆id
        pageSize: 20, //分页条数
        currentPage: 1, //当前页
      };
      try {
        const res = await memorialInfo_photoAlbum(postData);
        console.log(res, "图片");
        if (res.code === "2000") {
          //this.medias = [...this.medias, ...res.data];
          this.medias = res.data;
          this.imgs = [];
          this.medias.forEach((item) => {
            this.imgs.push(item.fileUrl);
          });
          return;
        }
        this.$toast.fail(res.msg || "数据请求失败");
      } catch (err) {
        console.log(err, 404);
        this.$toast.fail(err.msg);
      }
    },
    addClick() {
      document.querySelector("#filePic").click();
    },
    changePhoto() {
      // 选择图片
      let file = document.querySelector("#filePic").files[0];
      var reader = new FileReader();
      var that = this;
      console.log("base64", file);
      if (file.type != "image/jpeg" && file.type != "image/png") {
        this.$toast("请选择静态图片文件进行上传!");
        return;
      }
      reader.onloadend = function() {
        //把转换后的数据给id为base64Img的src属性
        console.log(reader.result);
        that.fileBase64List.push(reader.result);
        //这里调用了向后台发请求的代码
        that.addPhoto();
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    async addPhoto() {
      let postData = {
        memorialInfoId: this.$route.params.id, //纪念馆id
        fileBase64List: this.fileBase64List, //图片64转码
        type: "image", //文件类型(image:图片;music:音乐;视频:video)
        sourceType: "photo", //图片来源photo相册/grave墓穴
        loginUserId: this.loginUserId,
      };
      console.log(postData, "1111111");
      try {
        const res = await memorialInfo_photoAlbum_save(postData);
        console.log(res, 200);
        if (res.code === "2000") {
          this.$toast.success("照片添加成功");
          this.medias = [];
          this.fileBase64List = [];
          this.onload();
          return;
        }
        this.$toast.fail(res.msg || "数据请求失败");
      } catch (err) {
        console.log(err, 404);
        this.$toast.fail(err.msg);
      }
    },
    //返回纪念馆
    onClickLeft() {
      this.$router.push(`/memorialHall/${this.$route.params.id}`);
    },
    //去简介
    goInfo() {
      this.$router.push(`/memorialHall/albumLife/${this.$route.params.id}`);
    },
    //去悼文
    goEulogy() {
      this.$router.push(
        `/memorialHall/eulogyManagement/${this.$route.params.id}`
      );
    },
    //去留言
    goMessage() {
      this.$router.push(
        `/memorialHall/messageManagement/${this.$route.params.id}`
      );
    },
    //图片预览
    imgPreview(index) {
      //   this.startPosition=index;
      ImagePreview({
        images: this.imgs,
        startPosition: index,
      });
    },
    //点击编辑或完成
    navAction() {
      this.flag = !this.flag;
      this.selectResult = [];
      if (this.flag) {
        //完成
      } else {
        //编辑
      }
    },
    //选中改变
    selectChange(e) {
      console.log(e, "选中");
    },
    //全选
    checkAll() {
      //console.log(this.$refs.checkboxGroup)
      this.$refs.checkboxGroup.toggleAll(true);
    },
    //删除
    deleteItems() {
      let that = this;
      this.$dialog
        .confirm({
          title: "删除操作",
          message: "您确定删除吗？",
        })
        .then(async () => {
          // on confirm
          let postData = {
            ids: that.selectResult, //要删除的 主键
            loginUserId: that.loginUserId, //登陆id
          };

          try {
            const res = await memorialInfo_photoAlbum_delete(postData);
            console.log(res, 200);
            if (res.code === "2000") {
              that.$toast.success("删除成功");
              this.reload();
              return;
            }
            that.$toast.fail(res.msg || "数据请求失败");
          } catch (err) {
            console.log(err, 404);
            that.$toast.fail(err.msg);
          }
          that.reload();
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.album {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  /deep/ .van-nav-bar {
    background-color: $defaultColor;
    .van-icon,
    .van-nav-bar__text {
      color: #fff;
    }
  }
  .content {
    flex: 1;
    padding: 0.12rem;
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
    .content_main {
      flex: 1;
      .tabs {
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
        justify-content: space-around;
        margin-bottom: 0.2rem;
        .tab {
          width: 0.8rem;
          height: 0.3rem;
          border-radius: 0.05rem;
          background: #f5f5f5;
          color: #b7b7b7;
          display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
          -moz-justify-content: center;
-webkit-justify-content: center;
justify-content: center;
          -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
          span {
            font-size: 15px;
          }
        }
        .tab.active {
          background: $defaultColor;
          span {
            color: #fff;
          }
        }
      }
      .media {
        .list {
          display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
          flex-wrap: wrap;
          // height: calc(100% - 446px );
          overflow: auto;
          .media_item {
            position: relative;
            width: 1.1rem;
            height: 1.25rem;
            margin-right: 0.1rem;
            margin-bottom: 0.1rem;
            .media_img {
              width: 100%;
              height: 100%;
            }
            .checkbox {
              position: absolute;
              right: 0.06rem;
              top: 0.06rem;
              z-index: 25;
            }
          }
          .media_item:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
    }
    .footer {
      .footer_top {
        width: 100%;
        height: 0.5rem;
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        padding: 0 0.15rem;
        -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
        -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
        // position: absolute;
        // bottom: 0;
      }
      #filePic {
        display: none;
      }
    }
  }
}
</style>
