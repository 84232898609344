<template>
  <div class="login_register wh100">
    <navBar title="注册登录" :leftArrowFlag="true"></navBar>
    <div class="login_form_wrap">
      <van-form @submit="Submit" class="form">
        <div class="show_phone">
          <span class="tips">当前手机号:</span><span class="phone">{{ phone }}</span>
        </div>
        <div class="form_item" v-if="msgType == 1">
          <van-field
            class="form_code"
            v-model="code"
            center
            clearable
            label="验证码"
            :rules="[{ required: true, message: '请填写验证码！' }]"
            placeholder="请输入短信验证码"
          />
          <van-button
            class="code_btn"
            size="small"
            type="primary"
            @click="sendCode"
            :disabled="!disabledCodeBtn"
            >{{ btnText }}</van-button
          >
        </div>
        <div class="form_item">
          <van-field
            class="password"
            v-model="pwd"
            :type="passwordFlag ? 'password' : 'text'"
            name="password"
            clearable
            label="密码"
            placeholder="请输入您的密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          >
            <template slot="right-icon">
              <van-icon
                :name="passwordFlag ? 'closed-eye' : 'eye-o'"
                @click="passwordFlag = !passwordFlag"
                size="20px"
              />
            </template>
          </van-field>
        </div>
        <div class="form_btn">
          <van-button v-if="msgType == 1" round block type="primary" native-type="submit"
            >注册</van-button
          >
          <van-button v-if="msgType == 2" round block type="primary" native-type="submit"
            >登录</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import { register, login } from "@/api/user";
export default {
  name: "loginRegister",
  data() {
    return {
      msgType: 1, //1注册2登录
      isUser: false,
      passwordFlag: true, //密码框格式
      disabledCodeBtn: true, //验证码按钮文字
      phone: "",
      pwd: "",
      code: "",
      timer: null, //定时器标识
      btnText: "发送验证码", //按钮文字
      passErrMessage: "",
      subBtnText: "注册",
    };
  },
  beforeRouteEnter(to, from, next) {
    let token = localStorage.getItem("token");
    if (token) {
      next(false);
    } else {
      next();
    }
  },
  created() {
    let data = JSON.parse(sessionStorage.getItem("phonepwd"));
    if (data) {
      this.phone = data.phone;
      this.msgType = data.msgType;
      this.pwd = data.password;
    } else {
      this.phone = this.$route.query.phone;
      this.msgType = this.$route.query.msgType;
    }
  },
  mounted() {
    if (this.msgType == 1) {
      this.countDown(120);
    }
  },
  components: {
    navBar,
  },
  methods: {
    //手机验证码登录
    async Submit() {
      const { phone, msgType, code, pwd } = this;
      console.log(phone);
      let params = {};
      if (msgType == 1) {
        if (JSON.parse(localStorage.getItem("wxUser"))) {
          const {
            avatarUrl,
            nickname,
            sex,
            openId,
            unionid,
            country,
            province,
            city,
          } = JSON.parse(localStorage.getItem("wxUser"));
          console.log(
            avatarUrl,
            nickname,
            sex,
            openId,
            unionid,
            country,
            province,
            city,
            "微信参数"
          );
          params = {
            phone,
            codeType: msgType,
            code,
            type: 1,
            pwd,
            avatarUrl,
            nickname,
            gender: sex,
            openId,
            unionid,
            country,
            province,
            city,
          };
        } else {
          params = {
            phone,
            codeType: msgType,
            code,
            type: 1,
            pwd,
          };
        }
        //注册
        // const {avatarUrl,nickname,sex,openId,unionid}=JSON.parse(localStorage.getItem('wxUser'));
        // console.log(avatarUrl,nickname,sex,openId,unionid,'微信参数')

        let res = await register(params);
        if (res.code == "2000") {
          // this.$toast.success('注册成功')
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userId", res.data.userId);
          this.$router.replace("/");
        } else {
          this.$toast.fail(res.msg || "数据请求失败");
        }
      } else {
        if (JSON.parse(localStorage.getItem("wxUser"))) {
          //微信环境
          params = {
            phone,
            codeType: msgType,
            code,
            pwd,
            type: 1,
            openId: localStorage.getItem("openId"),
          };
        } else {
          params = {
            phone,
            codeType: msgType,
            code,
            pwd,
            type: 1,
          };
        }

        //登录
        let res = await login(params);
        console.log(res, "999");
        if (res.code == "2000") {
          //   this.$toast.success('登录成功')
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userId", res.data.userId);
          sessionStorage.removeItem("phonepwd");
          if (localStorage.getItem("firstLink")) {
            let firstLink = localStorage.getItem("firstLink");
            if (
              firstLink == "http://changchungzh.yunjisi.fushoubainian.com/" ||
              firstLink.indexOf("login") > -1
            ) {
              this.$router.replace("/");
            } else {
              window.location.href = localStorage.getItem("firstLink");
            }
          } else {
            this.$router.replace("/");
          }
        } else {
          this.$toast.fail(res.msg || "数据请求失败");
        }
      }
    },
    //发送验证码
    sendCode() {
      //let res = await api.pp.sms.getVerifyCode(this.phone)
      //  用手机号向后台换取验证码，发送成功则开始调用倒计时分方法
      // if(res){

      // }
      this.countDown(120);
    },
    // 倒计时方法
    countDown(time) {
      if (time === 0) {
        this.disabledCodeBtn = true;
        this.btnText = "发送验证码";
        return;
      } else {
        this.disabledCodeBtn = false;
        this.btnText = "重新发送(" + time + ")";
        time--;
      }
      setTimeout(() => {
        this.countDown(time);
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.login_register {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  /deep/ .van-button--primary {
    width: 100%;
    margin: 0;
    border-radius: 0.1rem;
    background-color: $defaultColor;
    border: 1px solid $defaultColor;
    box-shadow: 0px 0px 10px $defaultColor;
  }

  // /deep/ .van-field__error-message {
  //   display: none;
  // }
  .login_form_wrap {
    flex: 1;
    padding: 0.5rem 0.25rem 0;
    .form {
      .show_phone {
        margin: 0.25rem 0;
        font-size: 15px;
        line-height: 0.225rem;
        color: $textColor;
        .tips {
          color: $lightBlack;
        }
        .phone {
          font-weight: 600;
        }
      }
      .form_item {
        width: 100%;
        height: 0.44rem;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        margin-top: 0.25rem;
        /deep/ .van-cell {
          background: #f8f8f8;
          border-radius: 0.1rem;
        }
        .password {
          padding: 0.1rem !important;
        }
        .form_code {
          width: 1.9rem;
          height: 100%;
          background: #f8f8f8;

          /deep/ .van-field__label {
            width: auto;
            padding-left: 0.1rem;
          }
        }
        /deep/ .van-button--primary {
          width: 1.17rem !important;
          height: 0.44rem !important;
        }
      }
      .form_btn {
        margin-top: 1.11rem;
      }
    }
  }
}
</style>
