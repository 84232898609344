<template>
  <div class="manageCenter wh100">
    <navBar title="管理中心" :leftArrowFlag="true" />
    <div class="content">
      <div class="box">
        <div class="box_item" @click="goFamily">
          <i class="iconfont icon-qinyou"></i>
          <span>亲友管理</span>
        </div>
        <div class="box_item" @click="goBasicInfo">
          <i class="iconfont icon-xinxi12"></i>
          <span>基本信息</span>
        </div>
        <!-- <div class="box_item" @click="goRecallingSpace">
          <i class="iconfont icon-shikong-kongjianxuanzhong"></i>
          <span>追忆空间</span>
        </div> -->
        <div class="box_item" @click="goSetting">
          <i class="iconfont icon-shezhi1"></i>
          <span>纪念馆设置</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
export default {
  data() {
    return {
      id:''
    };
  },
  components: {
    navBar,
  },
  created(){
    console.log(this.$route.params.id,'id')
    this.id=this.$route.params.id
  },
  methods: {
    //亲友管理
    goFamily() {
        this.$router.push(`/memorialHall/familyList/${this.id}`)
    },
    //基本信息
    goBasicInfo() {
       this.$router.push(`/memorialHall/basicInfo/${this.id}`)
    },
    //追忆空间
    goRecallingSpace() {
      this.$toast('暂未开放')
       //this.$router.push(`/memorialHall/recallingSpace/${this.id}`)
    },
    //纪念馆设置
    goSetting() {
       this.$router.push(`/memorialHall/memorialHallSetting/${this.id}`)
    },
  },
};
</script>

<style lang="scss" scoped>
.manageCenter {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  .content {
    flex: 1;
    padding: 0.25rem 0.225rem;
    .box {
      display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .box_item {
        width: 1.1rem;
        height: 1.1rem;
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        margin: 0.15rem 0.275rem;
        -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
        -moz-justify-content: center;
-webkit-justify-content: center;
justify-content: center;
        -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
        border-radius: 0.05rem;
        box-shadow: 0px 0px 0.1rem 0.05rem #f0f0f0;
        .iconfont {
          font-size: 38px;
          color: $defaultColor;
        }
        span {
          font-size: 16px;
          line-height: 0.25rem;
        }
      }
    }
  }
}
</style>