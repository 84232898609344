<template>
  <div class="appointmentSuccess wh100">
    <navBar title="预约成功" :leftArrowFlag="true" />
    <div class="content">
      <h1>预约成功！</h1>
      <p>
        请截图保存此预约凭证，并与现场出示进行身份验证预约信息，请妥善保存。如有疑问请致电：{{phone}}
      </p>
      <div id="qrcode"></div>
      <!-- <div class="code">
        <span>预约码:{{ code }}</span>
      </div> -->
      <div class="large_btn" @click="goHistory"><span>完成</span></div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import navBar from "@/components/navBar";
export default {
  name: "appointmentSuccess",
  data() {
    return {
      code: "",
      phone:''
    };
  },
  components: {
    navBar,
  },
  created() {
    this.code = localStorage.getItem("ORDER_NUM");
    this.phone= JSON.parse(localStorage.getItem('adminconfig')).servicePhone
    localStorage.removeItem('editAppointmentInfo')
  },
  mounted() {
    this.$nextTick(function() {
      document.getElementById("qrcode").innerHTML = "";
      this.qrcode = new QRCode("qrcode", {
        width: 124,
        height: 124, // 高度
        // text: "987654", // 二维码内容
        text: window.btoa(this.code), // 二维码内容
        // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
        // background: '#f0f',   // 背景色
        // foreground: '#ff0'    // 前景色
      });
    });
  },
  methods: {
    goHistory() {
      sessionStorage.setItem('historyType',0)
      this.$router.push("/appointment/appointmentHistory");
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentSuccess {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  .content {
    flex: 1;
    padding: 0.2rem 0.15rem;
    text-align: center;
    h1 {
      font-size: 25px;
      color: $defaultColor;
      line-height: 0.35rem;
      margin-bottom: 0.15rem;
    }
    p {
      font-size: 14px;
      line-height: 0.2rem;
      text-align: left;
    }
    #qrcode {
      margin: 0.3rem 0 0.3rem;
      display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
      -moz-justify-content: center;
-webkit-justify-content: center;
justify-content: center;
    }
    .code {
      // width: 2rem;
      // width: 1.35rem;
      // height: 0.44rem;
      padding:0.12rem 0.2rem;
      border: 1px solid $defaultColor;
      display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
      -moz-justify-content: center;
-webkit-justify-content: center;
justify-content: center;
      -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
      color: $defaultColor;
      margin: 0 auto;
    }

    .large_btn {
      margin-top: 1.4rem;
    }
  }
}
</style>
