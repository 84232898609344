<template>
  <div class="eulogyItem" @click="changeCheck">
    <div class="item_left" v-show="flag">
      <van-checkbox :name="item.id" ref="checkboxes"></van-checkbox>
    </div>
    <div class="item_right">
      <div class="user_info">
        <div class="avatar_box">
          <img v-if='item.avatar' :src="item.avatar" alt="" />
          <img v-else :src="defaultAvatar" alt="" />
        </div>
        <div class="name">
          <span>{{ item.name || '匿名'}}</span>
        </div>
      </div>
      <h3>{{ item.title }}</h3>
      <div class="des">
        <p>{{ item.des }}</p>
      </div>
      <div class="item_bottom">
        <div class="item_left">
          <span class="date">{{ item.date | timeFormat1 }}</span>
        </div>
        <div class="item_right" v-if="item.status == 0">
          <span @click.stop="agree(item)">同意</span>
          <span @click.stop="refuse(item)">拒绝</span>
        </div>
      </div>
    </div>
    <van-dialog
      v-model="isShowRefuseDialog"
      title="是否拒绝?"
      show-cancel-button
      @confirm="confirm"
      @cancel="cancel"
    >
      <van-field
        v-model="reason"
        placeholder="请输入拒绝理由"
        style="padding: 0.1rem 0.2rem;"
      />
    </van-dialog>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { agree, refuse } from "@/api/my/eulogyManagement";
export default {
  data() {
    return { isShowRefuseDialog: false, reason: "", current: {},
    defaultAvatar:require('@/assets/imgs/avatar.png')
    };
  },
  props: {
    item: {
      type: Object,
      dafault: {},
    },
    flag: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  methods: {
    changeCheck(){
      if(this.flag){
        this.$refs.checkboxes.toggle();
      }
      
    },
    agree(item) {
      this.$dialog
        .confirm({
          title: "消息提示",
          message: "确定同意?",
        })
        .then(() => {
          agree({ id: item.id }).then(() => {
            this.$toast.success("已通过！");
            this.$emit("reload");
          });
        })
        .catch(() => {
          this.$toast("已取消");
        });
    },
    refuse(item) {
      this.isShowRefuseDialog = true;
      this.current = item;
    },
    confirm() {
      refuse({ id: this.current.id, refuseContent: this.reason }).then(() => {
        this.$toast.success("已拒绝！");
        this.reason = "";
        this.$emit("reload");
      });
    },
    cancel() {
      this.reason = "";
      this.$toast("已取消");
    },
  },
};
</script>

<style lang="scss" scoped>
.eulogyItem {
  padding: 0.05rem 0.1rem;
  margin-bottom: 0.1rem;
  background: #fff;
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  .item_left {
    padding: 0.1rem;
  }
  .item_right {
    flex: 1;
    .user_info {
      display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
      -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
      margin-bottom: 0.06rem;
      .avatar_box {
        width: 0.3rem;
        height: 0.3rem;
        margin-right: 0.06rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        font-size: 16px;
        line-height: 0.225rem;
        color: #bdbdbd;
      }
    }
    h3 {
      font-size: 15px;
      line-height: 0.21rem;
      font-weight: 600;
      margin-bottom: 0.04rem;
    }
    .des {
      padding-right: 0.4rem;
      margin-bottom: 0.15rem;
      p {
        font-size: 14px;
        line-height: 0.2rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }
    .item_bottom {
      display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
      -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
      -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
      .item_left {
        padding: 0;
        .date {
          font-size: 12px;
          line-height: 0.17rem;
          color: $lightBlack;
        }
      }
      .item_right {
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        justify-content: flex-end;
        -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
        span {
          margin: 0 0.1rem;
          color: $defaultColor;
        }
      }
    }
  }
}
</style>
