<template>
  <div class="bindSuccess wh100">
    <navBar title="绑定成功" :leftArrowFlag="true" />
    <div class="bindSuccess_form_wrap">
        <div class="result_box">
            <i class="iconfont icon-tijiaochenggong"></i>
            <h2>绑定成功！</h2>
            <h3>已成功绑定陵园</h3>
        </div>
        <div class="btn" @click="goMyHome">完成</div>
    </div>
  </div>
</template>
<script>
import navBar from "@/components/navBar";
export default {
  name: "bindSuccess",
  data() {
    return {
      form: {
        deadName: "",
        businessCode: "",
      },
    };
  },
  created() {},
  components: {
    navBar,
  },
  methods: {
    goMyHome(){
      this.$router.push('/my')
    }
  },
};
</script>

<style lang="scss" scoped>
.bindSuccess {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  // /deep/ .van-field__error-message {
  //   display: none;
  // }
  .bindSuccess_form_wrap {
    flex: 1;
    padding: 0.5rem 0.25rem 0;
    .result_box{
        text-align: center;
        .iconfont{
            font-size: 0.6rem;
            color: $defaultColor;
            margin-bottom: 0.1rem;
        }
        h2{
           font-size: 18px;
           line-height: 0.25rem;
           margin-bottom: 0.05rem;
        }
        h3{
            font-size: 14px;
            line-height: 0.2rem;
            color: $lightBlack;
        }
    }
    .btn{
        margin-top: 1.06rem;
        width: 100%;
        height: 0.44rem;
        background: $defaultColor;
        text-align: center;
        line-height: 0.44rem;
        box-shadow: $boxShow;
        color: #fff;
        border-radius: 0.1rem;
    }
  }
}
</style>