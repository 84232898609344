import { render, staticRenderFns } from "./selectMusic.vue?vue&type=template&id=3d525084&scoped=true&"
import script from "./selectMusic.vue?vue&type=script&lang=js&"
export * from "./selectMusic.vue?vue&type=script&lang=js&"
import style0 from "./selectMusic.vue?vue&type=style&index=0&id=3d525084&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d525084",
  null
  
)

export default component.exports