<template>
  <div class="addEulogy wh100">
    <navBar title="新增悼文" :leftArrowFlag="true" />
    <div class="content">
      <van-form @submit="onSubmit" class="form">
        <div class="form_item item_title">
          <van-field
            v-model="form.title"
            name="title"
            label="悼文标题"
            placeholder="悼文标题"
            :rules="[{ required: true, message: '请填写悼文标题' }]"
          />
        </div>
        <div class="form_item">
          <van-field
            v-model="form.content"
            name="des"
            rows="6"
            label="悼文内容"
            type="textarea"
            maxlength="200"
            placeholder="请输入悼文内容"
            show-word-limit
            :rules="[{ required: true, message: '悼文内容' }]"
          />
        </div>
        <div class="btn_box">
          <van-button :loading='btnLoading' :disabled='btnDisabled' round block type="primary" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import navBar from "@/components/navBar";
import { getUserInfo } from "@/api/user"; // 获取用户信息
import { note_operation } from "@/api/memorialHall/album";
export default {
  data() {
    return {
      btnLoading:false,
      btnDisabled:false,
      form: {
        memoriaId: "", //	纪念馆id
        content: "", //	留言内容
        contentType: "dw", //	ly留言 dw悼文
        state: 0, //	审核状态(0:未审核;1:同意;2:驳回)
        userId: "", //	留言人
        operatorVia: "", //	留言人头像
        operatorNick: "", //	留言人昵称
        title: "", //	留言标题
      },
      userInfo: {},
    };
  },
  components: {
    navBar,
  },
  created() {
    this.form.memoriaId = this.$route.params.id;
    this.getUserInfo();
  },
  methods: {
    // 获取用户信息
    async getUserInfo() {
      let postData = {};
      console.log(postData, "postData");
      try {
        const res = await getUserInfo(postData);
        console.log(res, 200);
        if (res.code === "2000") {
          this.userInfo = res.data;
          this.form.operatorVia = res.data.avatarUrl ? res.data.avatarUrl : "";
          this.form.userId = res.data.id;
          this.form.operatorNick = res.data.nickname ? res.data.nickname : "";
          return;
        }
        this.$toast.fail(res.msg || "数据请求失败");
      } catch (err) {
        console.log(err, 404);
        this.$toast.fail(err.msg);
      }
    },
    //提交
    async onSubmit() {
      let postData = this.form;
      console.log(postData, "postData");
      this.btnLoading=true
      this.btnDisabled=true
      try {
        const res = await note_operation(postData);
        console.log(res, 200);
        if (res.code === "2000") {
          this.$toast.success("发布成功");
          this.btnLoading=false
            this.btnDisabled=false
          this.$router.go(-1);
          return;
        }
        this.$toast.fail(res.msg || "数据请求失败");
        this.btnLoading=false
            this.btnDisabled=false
      } catch (err) {
        console.log(err, 404);
        this.btnLoading=false
            this.btnDisabled=false
        this.$toast.fail(err.msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.addEulogy {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  .content {
    flex: 1;
    padding: 0.12rem;
    .form {
      /deep/ .van-button--primary{
    width: 100%;
    margin:0;
    border-radius: 0.1rem;
    background-color: $defaultColor;
    border: 1px solid $defaultColor;
    box-shadow:0px 0px 10px $defaultColor;
}
      .form_item {
        margin-bottom: 0.15rem;
        /deep/ .van-cell {
          display: block;
          .van-field__label {
            margin-bottom: 0.1rem;
          }
          .van-field__value {
            border: 1px solid #d4d4d4;
            border-radius: 0.06rem;
            min-height: 0.44rem;
            .van-field__body {
              height: 100%;
              .van-field__control {
                height: 100%;
                padding-left: 0.1rem;
              }
            }
          }
        }
      }
      .item_title {
        /deep/ .van-field__value {
          height: 0.44rem;
          .van-field__body {
            height: 100%;
            .van-field__control {
              height: 100%;
              padding-left: 0.1rem;
            }
          }
        }
      }
      .btn_box {
        margin: 2rem 0.12rem 0.12rem;
      }
    }
  }
}
</style>
