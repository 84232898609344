<template>
  <div class="orderDetail wh100">
    <navBar title="订单详情" :leftArrowFlag="true" />
    <div class="content">
      <div class="item">
        <div class="item_left">订单号:</div>
        <div class="item_right">{{detail.orderNo}}</div>
      </div>
      <div class="item">
        <div class="item_left">当前订单状态:</div>
        <div class="item_right">{{detail.sweepOrderState | formatStatus(detail.orderType)}}</div>
      </div>
      <div class="item">
        <div class="item_left">逝者姓名:</div>
        <div class="item_right">{{detail.deadName}}</div>
      </div>
      <div class="item">
        <div class="item_left">逝者身份证:</div>
        <div class="item_right">{{detail.deadCardNo}}</div>
      </div>
      <div class="item">
        <div class="item_left">预约人姓名:</div>
        <div class="item_right">{{detail.appointmentName}}</div>
      </div>
      <div class="item">
        <div class="item_left">预约人电话:</div>
        <div class="item_right">{{detail.appointmentPhone}}</div>
      </div>
      <div class="item">
        <div class="item_left">墓穴信息:</div>
        <div class="item_right">{{detail.graveDetail}}</div>
      </div>
      <div class="item">
        <div class="item_left">预约日期:</div>
        <div class="item_right">{{detail.appointmentDate}}</div>
      </div>
      <div class="item">
        <div class="item_left">关联图片:</div>
        <div class="item_right">
          <span
            v-if="!detail.sacrificeFileInfoList || detail.sacrificeFileInfoList.length <= 0"
          >暂无关联图片</span>
          <div
            class="img_box"
            v-else
            v-for="(item, index) in detail.sacrificeFileInfoList"
            :key="index"
          >
            <van-image width="100" height="100" @click="showPreview(index)" :src="item.fileUrl" />
            <van-icon name="cross" color="red" @click="delImg(item.id)" />
          </div>
        </div>
      </div>
      <div class="item">
        <div class="item_left">基础服务费:</div>
        <div class="item_right">{{detail.osPrice | formatPrice}}</div>
      </div>
      <div class="item">
        <div class="item_left">祭扫商品:</div>
        <div class="item_right">
          <span v-if="!detail.orderSpus || detail.orderSpus.length <= 0">暂无祭扫商品</span>
          <div v-else class="goods" v-for="(item, index) in detail.orderSpus" :key="index">
            <div class="good_img">
              <van-image
                style="width: 100px; height: 100px"
                :src="item.img"
                fit="cover"
                @click="showImage(item.img)"
              ></van-image>
            </div>
            <div class="good_info">
              <span>商品名称:{{ item.spuName }}</span>
              <span>价格:{{ item.price }}</span>
              <span>数量：{{ item.num }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="steps_wrap">
        <van-steps :active="active" direction="vertical">
          <van-step v-for="(item, index) in detail.orderTurnLogs" :key="index">
            <div>
              订单状态 :
              <van-tag
                size="large"
                :type="
                      item.orderState == 0 || item.orderState == 1
                        ? 'danger'
                        : item.orderState == 2
                        ? 'warning'
                        : 'success'
                    "
              >{{ item.orderState | formatStatus(item.orderType) }}</van-tag>
            </div>
            <p>
              {{ item.userName }} 创建于
              {{ item.gmtCreate }}
            </p>
          </van-step>
        </van-steps>
      </div>
      <div class="btns">
        <!-- <van-button
              v-if="detail.sweepOrderState == 2"
              type="primary"
              @click='changeStatus(4)'
              round
              
              >审核</van-button
        >-->
        <!-- <van-button
              v-if="detail.sweepOrderState == 4"
              type="primary"
              
              round
              
              >上传图片</van-button
        >-->
        <van-button block round icon="plus" @click="imgClick" type="primary">上传图片</van-button>
        <input class="file_input" type="file" accept=".png, .jpg, .jpeg" @change="change" />
        <!-- <van-button
              v-if="detail.sweepOrderState == 2 || detail.sweepOrderState == 3"
              type="danger"
              
              round
              
              >取消</van-button
        >-->
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import { Dialog } from "vant";
import { ImagePreview, Toast } from "vant";
import { getDetail, uploadImg, delImg } from "@/api/my/examineList";
export default {
  data() {
    return {
      active: 0,
      detail: {},
      form: {
        id: "",
        orderNo: "",
        images: [],
        type: "image"
      }
    };
  },
  filters: {
    formatStatus(val, type) {
      if (val == 0) {
        return "用户取消";
      } else if (val == 1) {
        return "工作人员取消";
      } else if (val == 2) {
        return "待审核";
      } else if (val == 3) {
        return "审核成功,待支付";
      } else if (val == 4 && type == 0) {
        return "免费祭扫";
      } else if (val == 4 && type == 1) {
        return "支付成功";
      } else if (val == 5) {
        return "完成";
      }
    },
    formatPrice(value) {
      let newValue = value/100;
      if (!newValue) return "0.00";
      
      if (newValue > 100000000 || newValue < -100000000) {
        return Number(newValue / 100000000).toFixed(2) + "亿";
      } else if (newValue > 10000 || newValue < -10000) {
        return Number(newValue / 10000).toFixed(2) + "万";
      } else {
        return Number(newValue).toFixed(2) + "元";
      }
    }
  },
  components: {
    navBar
  },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      let params = {
        id: this.$route.params.id
      };
      let res = await getDetail(params);
      console.log(res, "88784");
      if (res.code !== "2000") return this.$toast.fail(res.msg);
      this.detail = res.data;
    },
    changeStatus() {},
    // 触发图片选择
    imgClick() {
      this.form.id = this.detail.id;
      this.form.orderNo = this.detail.orderNo;
      this.form.images = [];
      document.querySelector("input[type=file]").value = "";
      document.querySelector("input[type=file]").click();
    },
    // 选择图片
    change() {
      let file = document.querySelector("input[type=file]").files[0];
      var reader = new FileReader();
      console.log("base64", file);
      reader.onloadend = async () => {
        //把转换后的数据给id为base64Img的src属性
        console.log(reader.result);
        this.form.images.push(reader.result);
        //这里调用了向后台发请求的代码
        let params = this.form;
        let res = await uploadImg(params);
        console.log(res, "0123456");
        if (res.code != "2000") return this.$toast.fail(res.msg);
        this.getDetail();
        // this.form = {
        //   id: "",
        //   orderNo: "",
        //   images: [],
        //   type: "image",
        // };
      };
      if (file) {
        reader.readAsDataURL(file);
      }
      document.querySelector("input[type=file]").value = "";
    },
    // 返回布尔值
    beforeRead(file) {
      if (file.type !== "image/jpeg") {
        Toast("请上传 jpg 格式图片");
        return false;
      }
      return true;
    },
    showPreview(index) {
      ImagePreview({
        images: this.detail.fileUrls,
        startPosition: index
      });
    },
    showImage(src) {
      ImagePreview({
        images: [src]
      });
    },
    delImg(id) {
      Dialog.confirm({
        title: "删除图片",
        message: "您确定删除这张图片吗"
      })
        .then(async () => {
          // on confirm
          let res = await delImg({ id });
          if (res.code != "2000") return this.$toast.fail(res.msg);
          this.$toast.success(res.msg);
          this.getDetail();
        })
        .catch(() => {
          // on cancel
          this.$toast.fail("取消删除");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.orderDetail {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  .content {
    flex: 1;
    padding: 0.15rem;
    overflow-y: scroll;
    .item {
      display: flex;
      align-items: center;
      padding: 0.15rem 0;
      border-bottom: 1px solid #ededed;
      .item_left {
        width: 1rem;
      }
      .item_right {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .goods {
          display: flex;
          align-items: center;
          .good_img {
            margin-right: 10px;
          }
          .good_info {
            flex: 1;
            display: flex;
            flex-direction: column;
            span {
              line-height: 30px;
            }
          }
        }
        .img_box {
          width: 1rem;
          height: 1rem;
          position: relative;
          margin: 0.05rem;
          /deep/ .van-icon {
            position: absolute;
            right: 0.05rem;
            top: 0.05rem;
          }
        }
      }
    }
    .btns {
      display: flex;
      align-items: center;
      /deep/ .van-button {
        flex: 1;
        margin: 0 0.1rem;
      }
    }
    .file_input {
      color: #ffffff00;
      display: none;
      width: 100px;
      height: 100px;
    }
  }
}
</style>