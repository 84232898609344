<template>
    <div class="cemeteryBinding wh100">
        <navBar title='陵园绑定' :leftArrowFlag='true' />
        <div class="cemeteryBinding_form_wrap">
             <van-form @submit="Submit" class="form">
                    <div class="form_item">
                        <van-field
                            v-model="form.deadName"
                            name="deadName"
                            clearable
                            label="逝者姓名"
                            placeholder="请输入逝者姓名"
                        :rules="[{ required: true, message: '请填写逝者姓名' }]" />
                    </div>
                    <div class="form_item">
                         <van-field
                            v-model="form.businessCode"
                            name="businessCode"
                            clearable
                            label="逝者编号"
                            placeholder="请输入逝者编号"
                         :rules="[{ required: true, message: '请填写逝者编号' }]"
                    />
                    </div>
                    <div class="form_btn">
                        <van-button round block type="primary" native-type="submit">绑定</van-button>
                    </div>
                </van-form>
        </div>
    </div>
</template>
<script>
import navBar from '@/components/navBar'
    export default {
        name:'cemeteryBinding',
        data() {
            return {
              form:{
                 deadName:'',
                  businessCode:'',
              },
            }
        },
        created(){
        },
        components:{
            navBar,
        },
        methods:{
            Submit(vals){
                console.log(vals)
                this.$router.push('/my/bindSuccess')
            },
            
        }
    }
</script>

<style lang="scss" scoped>
.cemeteryBinding{
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
    // /deep/ .van-field__error-message{
    //     display: none;
    // }
    .cemeteryBinding_form_wrap{
        flex:1;
        padding:0.5rem 0.25rem 0;
        .form{
            margin: 0.25rem 0;
            .form_item{
                width: 100%;
                height: 0.44rem;
                display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
                -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
                margin-bottom: 0.25rem;
                 /deep/ .van-cell{
                        background: #F8F8F8;
                        padding: 0.1rem!important;
                    }
                    /deep/ .van-field__label{
                        width: auto;
                        padding-left: 0.1rem;
                    }
                /deep/ .van-button--primary{
                    width: 1.17rem!important;
                    height: 0.44rem!important;
                }
                
            }
        }
    }
}
</style>