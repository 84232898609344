<template>
  <div class="i_manage wh100">
    <navBar title="我管理的纪念馆" :leftArrowFlag="true" />
    <div class="list_content">
      <vantList
        :list="list"
        :loading="loading"
        :finished="finished"
        :refreshing="refreshing"
        @getList="getList"
        @refresh="refresh"
      >
        <div class="list" v-for="(item, index) in list" :key="index">
          <myManageItem :item="item" @refresh='getList' ></myManageItem>
        </div>
      </vantList>
    </div>
    <div class="create_btn" @click="goCreate">
      <span>+创建纪念馆</span>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import vantList from "@/components/list";
import myManageItem from "@/components/memorialHallItem/myManageItem";
import {
  memorialInfo_manage,
  memorialInfo_RelativesFriends,
  memorialInfo_relevancyState,
} from "@/api/my/iManageMemorialHalls";
export default {
  name: "iManageMemorialHalls",
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      pageSize: 10,
      currentPage: 1,
      list: [],
    };
  },
  components: {
    navBar,
    vantList,
    myManageItem,
  },
  mounted() {
    this.getList();
  },
  methods: {
    //创建纪念馆
    goCreate() {
      this.$router.push("/createMemorialHall");
    },
    //请求数据
    async getList() {
      this.loading=true
      const { currentPage, pageSize } = this;
      let params = {
        friendsTypes: ["qy", "glz"],
        currentPage,
        pageSize,
      };
      try {
        let res = await memorialInfo_manage(params);
        this.loading = false;
        this.refreshing = false;
        if (res.code === "2000") {
          let list = JSON.parse(JSON.stringify(res.data)) || [];
          list.forEach((item) => {
            item.life = item.birthday + "~" + item.deathDay;
          });
          //根据总条数处理
          if (this.list.length < res.total) {
            this.list = [...this.list, ...list];
            this.currentPage++;
          } else {
            this.finished = true;
            this.loading = false;
          }
        }
      } catch (err) {}
    },

    goCreate() {
      this.$router.push("/createMemorialHall");
    },
    //下拉刷新
    refresh() {
      this.refreshing = true;
      // 清空列表数据
      this.currentPage = 1;
      this.list = [];
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.i_manage {
  background: #f9f9f9;
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  .list_content {
    flex: 1;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 0.125rem;
    padding-bottom: 0;
    /deep/ .van-list {
      width: 100%;
    }
  }
  .create_btn {
    height: 0.44rem;
    background: $defaultColor;
    color: #fff;
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
    -moz-justify-content: center;
-webkit-justify-content: center;
justify-content: center;
    border-radius: 0.05rem;
    box-shadow: $boxShow;
    margin: 0.15rem;
  }
}
</style>
