<template>
  <div class="rechargeItem">
      <div class="item_left">
          <h4>{{item.title}}</h4>
          <h5>{{item.date}}</h5>
      </div>
      <div class="item_right">
          <h4>{{item.price}}</h4>
          <h5 :class="[item.status ==0?'success':'']">{{item.status | formatStatus}}</h5>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  filters:{
      formatStatus(val){
          if(val===0){
              return '充值成功'
          }else{
              return '充值失败'
          }
      }
  },
  methods: {
   
  },
  
};
</script>

<style lang="scss" scoped>
.rechargeItem {
    padding:0.2rem 0 0.04rem;
    border-bottom: 1px solid #EDEDED;
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
    .item_left{
        h4{
            font-size: 14px;
            line-height: 0.2rem;
            margin-bottom: 0.05rem;
        }
        h5{
            font-size: 12px;
            color: $lightBlack;
            line-height: 0.17rem;
        }
    }
    .item_right{
        h4{
            font-size: 14px;
            line-height: 0.2rem;
            margin-bottom: 0.05rem;
        }
        h5{
            font-size: 12px;
            color: $lightBlack;
            line-height: 0.17rem;
        }
        h5.success{
            color: $defaultColor;
        }
    }
}
</style>