<template>
  <div class="login_register wh100">
    <navBar title="修改密码" :leftArrowFlag="true"></navBar>
    <div class="login_form_wrap">
      <van-form @submit="Submit" class="form">
        <div class="form_item">
          <van-field
            v-model="form.password"
            :type="passwordFlag ? 'password' : 'text'"
            name="password"
            clearable
            label="密码"
            placeholder="请输入您的密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          >
            <template slot="right-icon">
              <van-icon
                :name="passwordFlag ? 'closed-eye' : 'eye-o'"
                @click="passwordFlag = !passwordFlag"
                size="20px"
              />
            </template>
          </van-field>
        </div>
        <div class="form_item">
          <van-field
            v-model="form.passPassword"
            name="passPassword"
            clearable
            type="password"
            label="确认密码"
            placeholder="请输入您的确认密码"
            :rules="[{ required: true, message: '请填写确认密码' }]"
            :error-message="passErrMessage"
          />
        </div>

        <div class="form_btn">
          <van-button round block type="primary" native-type="submit">确认</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import { changePassword } from "@/api/user";
export default {
  name: "alterPassword",
  data() {
    return {
      passwordFlag: true, //密码框格式
      form: {
        password: "",
        passPassword: "",
      },

      passErrMessage: "",
    };
  },
  created() {},
  components: {
    navBar,
  },
  methods: {
    async Submit(vals) {
      const { password, passPassword } = this.form;
      if (password === passPassword) {
        let params = {
          pwsOne: password,
          pwsTwo: passPassword,
        };
        let res = await changePassword(params);
        if (res.code != "2000") {
          this.$toast.fail(res.msg || "数据请求失败");
          return;
        } else {
          this.$toast.success("密码修改成功,请重新登录");
          localStorage.removeItem("token");
          //localStorage.clear()
          sessionStorage.setItem(
            "phonepwd",
            JSON.stringify({
              phone: this.$route.params.phone,
              msgType: 2,
              password: this.form.password,
            })
          );
          this.$router.replace({
            path: "/passwordRegister",
            // query: {
            //   phone: this.$route.params.phone,
            //   msgType: 2,
            //   password: this.form.password,
            // },
          });
          // this.$router.replace({
          //   path: "/login",
          // });
        }
      } else {
        this.$toast.fail("两次密码不一致");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login_register {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  /deep/ .van-button--primary {
    width: 100%;
    margin: 0;
    border-radius: 0.1rem;
    background-color: $defaultColor;
    border: 1px solid $defaultColor;
    box-shadow: 0px 0px 10px $defaultColor;
  }
  .login_form_wrap {
    flex: 1;
    padding: 0.5rem 0.25rem 0;
    .form {
      margin: 0.25rem 0;
      .form_item {
        width: 100%;
        height: 0.44rem;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        margin-bottom: 0.25rem;
        /deep/ .van-cell {
          background: #f8f8f8;
          padding: 0.1rem !important;
        }
        /deep/ .van-field__label {
          width: auto;
          padding-left: 0.1rem;
        }
        /deep/ .van-button--primary {
          width: 1.17rem !important;
          height: 0.44rem !important;
        }
      }
    }
  }
}
</style>
