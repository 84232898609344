<template>
  <van-pull-refresh v-model="isRefreshing" @refresh="onRefresh">
    <van-list
      ref="vantlist"
      v-if="list && list.length > 0"
      v-model="isLoading"
      :finished="finished"
      :immediate-check="false"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <slot></slot>
    </van-list>
    <van-empty v-else description="暂无数据" />
  </van-pull-refresh>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      isRefreshing: false,
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: [],
    },
    finished: {
      type: Boolean,
      default: false,
    },
    refreshing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    //    listLoading(){
    //        return this.loading
    //    }
  },
  created() {
    //this.$emit('getList',this.isLoading)
  },
  watch: {
    loading(val) {
      this.isLoading = val;
    },
    refreshing(val) {
      this.isRefreshing = val;
    },
  },
  methods: {
    onLoad() {
      this.$emit("getList", this.isLoading);
    },
    onRefresh() {
      this.$emit("refresh", this.isRefreshing);
    },
  },
};
</script>

<style lang="scss" scoped>
.van-pull-refresh {
  //padding: 0 0.125rem;
  // overflow-y: scroll;
}
</style>
