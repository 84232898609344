import request from "@/utils/request";

// 获取全局配置信息
export function configuration_loadList(data) {
  return request({
    url: "/configuration/loadList",
    method: "post",
    data,
  });
}
