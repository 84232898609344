<template>
  <div class="login_register wh100">
    <navBar title="登录" :leftArrowFlag="true"></navBar>
    <div class="login_form_wrap">
      <van-form @submit="Submit" class="form">
        <div class="show_phone">
          <span class="tips">当前手机号:</span
          ><span class="phone">{{ phone }}</span>
        </div>
        <div class="form_item">
          <van-field
            class="form_code"
            v-model="code"
            center
            clearable
            label="验证码"
            :rules="[{ required: true, message: '请填写验证码！' }]"
            placeholder="请输入短信验证码"
          />
          <van-button
            class="code_btn"
            size="small"
            type="primary"
            @click="sendCode"
            :disabled="!disabledCodeBtn"
            >{{ btnText }}</van-button
          >
        </div>

        <div class="form_btn">
          <van-button
            v-if="msgType == 2"
            round
            block
            type="primary"
            native-type="submit"
            >登录</van-button
          >
          <van-button
            v-if="msgType == 1"
            round
            block
            type="primary"
            native-type="submit"
            >注册</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import { register, login, getVerificationCode } from "@/api/user";
export default {
  name: "loginRegister",
  data() {
    return {
      msgType: 1, //1注册2登录
      disabledCodeBtn: true, //按钮状态
      phone: "", //手机号
      code: "", //验证码
      timer: null, //定时器标识
      btnText: "发送验证码", //按钮文字
      passErrMessage: "",
    };
  },
  created() {
    this.phone = this.$route.query.phone;
    this.msgType = this.$route.query.msgType;
  },
  components: {
    navBar,
  },
   beforeRouteEnter (to, from, next) {
      let token=localStorage.getItem('token')
      if(token){
          next(false)
      }else{
          next()
      }
      },
  mounted() {
    this.countDown(120);
  },
  methods: {
    //手机验证码登录
    async Submit() {
      const { phone, msgType, code } = this;
      console.log(phone);
      let params = {};
      if (msgType == 1) {
        if (JSON.parse(localStorage.getItem("wxUser"))) {
          const { avatarUrl, nickname, sex, openId, unionid,country,province,city } = JSON.parse(
            localStorage.getItem("wxUser")
          );
          console.log(avatarUrl, nickname, sex, openId, unionid,country,province,city, "微信参数");
          params = {
            phone,
            codeType: msgType,
            code,
            type: 2,
            avatarUrl,
            nickname,
            gender: sex,
            openId,
            unionid,
            country,
            province,
            city,
          };
        } else {
          params = {
            phone,
            codeType: msgType,
            code,
            type: 2,
          };
        }

        console.log(params, "注册请求参数");
        //注册
        let res = await register(params);
        if (res.code == "2000") {
          // this.$toast.success(res.msg)
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userId", res.data.userId);
          this.$router.replace("/");
        } else {
          this.$toast.fail(res.msg || "数据请求失败");
        }
      } else {
         if (JSON.parse(localStorage.getItem("wxUser"))) {
           //微信环境
            params = {
              phone,
              codeType: msgType,
              code,
              type: 2,
              openId:localStorage.getItem('openId')
            };
         }else{
           //浏览器环境
            params = {
              phone,
              codeType: msgType,
              code,
              type: 2,
            };
         }
        //登录
        let res = await login(params);
        if (res.code == "2000") {
          //   this.$toast.success('登录成功')
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("userId", res.data.userId);
          if (localStorage.getItem("firstLink")) {
            let firstLink=localStorage.getItem("firstLink");
            if(firstLink=='http://changchungzh.yunjisi.fushoubainian.com/' || firstLink.indexOf('login')>-1){
               this.$router.replace("/");
            }else{
              window.location.href = localStorage.getItem("firstLink");
            }
          } else {
            this.$router.replace("/");
          }
        } else {
          this.$toast.fail(res.msg || "数据请求失败");
        }
      }
    },
    //发送验证码
    async sendCode() {
      let params = {
        mobile: this.phone,
        msgType: this.msgType,
      };
      let result = await getVerificationCode(params);

      if (result.code == "2000") {
        this.$toast.success(result.data);
        this.countDown(10);
      } else {
        this.$toast.fail(res.result);
      }
    },
    // 倒计时方法
    countDown(time) {
      if (time === 0) {
        this.disabledCodeBtn = true;
        this.btnText = "发送验证码";
        return;
      } else {
        this.disabledCodeBtn = false;
        this.btnText = "重新发送(" + time + ")";
        time--;
      }
      setTimeout(() => {
        this.countDown(time);
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.login_register {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
 /deep/ .van-button--primary{
    width: 100%;
    margin:0;
    border-radius: 0.1rem;
    background-color: $defaultColor;
    border: 1px solid $defaultColor;
    box-shadow:0px 0px 10px $defaultColor;
}

// /deep/ .van-field__error-message{
//   display: none;
// }
/deep/ .van-cell {
          background: #f8f8f8;
          border-radius: 0.06rem;
        }
  .login_form_wrap {
    flex: 1;
    padding: 0.5rem 0.25rem 0;
    .form {
      .show_phone {
        margin: 0.25rem 0;
        font-size: 15px;
        line-height: 0.225rem;
        color: $textColor;
        .tips {
          color: $lightBlack;
        }
        .phone {
          font-weight: 600;
        }
      }
      .form_item {
        width: 100%;
        height: 0.44rem;
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
        margin-bottom: 1.8rem;
        .form_code {
          max-width: 1.9rem;
          min-width: 180px;
          height: 100%;
          background: #f8f8f8;
          /deep/ .van-field__label {
            width: auto;
            padding-left: 0.1rem;
          }
        }
        .code_btn{
          min-width: 80px;
        }
        /deep/ .van-button--primary {
          width: 1.17rem !important;
          height: 0.44rem !important;
        }
      }
    }
  }
}
</style>