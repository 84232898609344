<template>
  <div class="search wh100">
    <div class="search_box">
      <van-icon name="arrow-left" @click="goHome" />
      <van-search
        v-model="searchName"
        clearable
        shape="round"
        placeholder="请输入纪念馆名"
         @search="onSearch"
         show-action
         @clear='clearSearch'
          @cancel="onCancel"
      />
    </div>
    <div class="search_content">
      <div class="list_content" v-if="list && list.length > 0">
        <vantList
          :list="list"
          :loading="loading"
          :finished="finished"
          :refreshing="refreshing"
          @getList="getList"
          @refresh="refresh"
        >
          <div class="list" v-for="(item, index) in list" :key="index">
            <searchItem :item="item"></searchItem>
          </div>
        </vantList>
      </div>
      <div v-else class="no_search">
        <div class="no_search_title">
          <div class="title_left">
            <span>搜索历史</span>
          </div>
          <div class="title_right" @click="deleteSearchHistory">
            <i class="iconfont icon-46shanchu"></i>
          </div>
        </div>
        <div class="search_results_wrap">
          <div class="search_results">
            <div
              @click="onSearch(item)"
              class="results_item"
              v-for="(item, index) in searchHistorys"
              :key="index"
            >
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vantList from "@/components/list";
import searchItem from "@/components/memorialHallItem/searchItem";
import {
  memorialInfo_search,
} from "@/api/home/index";
export default {
  data() {
    return {
      searchName: "",
      searchHistorys: [],
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageSize: 10,
      currentPage: 1,
    };
  },
  components: {
    vantList,
    searchItem,
  },
  created(){
    let searchHistorys= JSON.parse(localStorage.getItem('searchHistorys'));
    if(searchHistorys){
      this.searchHistorys=searchHistorys
    }
  },
  methods: {
    goHome() {
      this.$router.go(-1);
    },
    //
    onSearch(val){
      if(val){
        this.getList(val)
      }else{
        this.$toast.fail('请输入纪念馆名')
      }
      
    },
    onCancel() {
      this.list=[]
    },
    clearSearch(){
      this.list=[]
    },
    //请求数据
    async getList(val) {
       this.loading=true
      const { currentPage, pageSize, searchName} = this;
      let params = {
        currentPage,
        pageSize,
        memorialName:val
      };
      let res = await memorialInfo_search(params);
      if (res.code === "2000") {
        if(this.searchHistorys.includes(val)){
        }else{
          this.searchHistorys.push(val);
          localStorage.setItem('searchHistorys',JSON.stringify(this.searchHistorys))
        }
        
        
        let list = JSON.parse(JSON.stringify(res.data)) || [];
        list.forEach((item) => {
            item.memorialInfoId = item.id;
            // 在世时间处理
            item.life = item.segDates ? item.segDates[0] : "";
            // 图片
            item.avatar = item.deadPortraitUrls ? item.deadPortraitUrls[0] : "";

            // 离世时长;
            item.leave = item.deathYears ? item.deathYears[0] : "";
            // 纪念馆名称
            item.name = item.deadNames ? item.deadNames[0] : "";
          });
        //根据总条数处理
        if (this.list.length < res.data.total) {
          this.list = [...this.list, ...list];
          this.currentPage++;
        } else {
         this.list=list
          this.finished = true;
          this.loading = false;
        }
      }
      this.loading = false;
      this.refreshing = false;
    },
    //下拉刷新
    refresh() {
      this.refreshing = true;
      // 清空列表数据
      this.currentPage = 1;
      this.list = [];
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
    //删除搜索历史
    deleteSearchHistory(){
      this.$dialog.confirm({
          title: '删除操作',
          message: '您确定删除搜索历史吗？',
      })
          .then(() => {
          // on confirm
              this.searchHistorys=[];
              localStorage.removeItem('searchHistorys')
          })
          .catch(() => {
          // on cancel
              this.$toast.fail('取消删除');
          });
    }
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  // 搜索框
  .search_box {
    padding: 0.07rem 0.15rem;
    background: $defaultColor;
    color: #fff;
    width: 100%;
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
    /deep/ .van-search {
      padding: 0;
      flex: 1;
      margin-left: 0.1rem;
      background: transparent;
      .van-search__content {
        padding-right: 0.1rem;
        .van-cell {
          padding: 0.05rem 0 !important;
        }
      }
      .van-search__action{
        color: #fff;
      }
    }
  }
  .search_content {
    flex: 1;
    .list_content {
     padding: 0 0.125rem;
      background: #f9f9f9;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      height: calc(100vh - 0.48rem);
      width: 100%;
      /deep/ .van-list {
        width: 100%;
        .list_item {
          width: 100%;
          height: 0.8rem;
        }
      }
    }
    .no_search {
      padding: 0.12rem;
      .no_search_title {
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
        text-indent: 0.08rem;
        margin-bottom: 0.23rem;
        .title_left {
          border-left: 3px solid $defaultColor;
        }
      }
      .search_results_wrap{
          .search_results{
              display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
              flex-wrap: wrap;
              .results_item{
                 padding: 0.05rem 0.12rem;
                 margin:0.05rem;
                 text-align: center;
                 background: #F8F8F8;
                 color: #4C4C4C;
                 border-radius: 0.03rem;
                 span{
                     font-size: 14px;
                     line-height: 0.2rem;
                 }
              }
          }
      }
    }
  }
}
</style>