<template>
  <div class="lighting wh100">
    <navBar title="点亮祭拜灯" :leftArrowFlag="true" />
    <div class="content">
      <div class="lights">
        <div
          :class="[active == item.id ? 'active' : '', 'light_item']"
          v-for="(item, index) in lights"
          :key="index"
          @click="selectChange(item)"
        >
          <div class="light_img">
            <img :src="item.url" alt="" />
          </div>
          <h3>{{ item.title }}</h3>
          <h5>{{ item.des }}</h5>
          <div :class="[item.price && item.price > 0 ? 'price' : '', 'tips']">
            <span v-if="item.price && item.price > 0">收费</span>
            <span v-else>免费</span>
          </div>
        </div>
      </div>

      <div class="large_btn" @click="lighting"><span>点亮长明灯</span></div>
    </div>
    <van-popup class="popup" v-model="show" position="bottom">
      <h2>{{ selectItem.title }}</h2>
      <div class="prices">
        <div class="price">
          <div class="price_left">
            <span>点亮{{ selectItem.validTime }}天</span>
            <span class="price_num">{{ selectItem.price }}祭祀币</span>
          </div>
          <div class="price_right">
            <van-checkbox v-model="checked" />
          </div>
        </div>
      </div>
      <!-- <van-cell-group>
            <van-cell :title="cellTitle" clickable @click="setSelectPrice">
              <template #right-icon>
                <van-radio :name="selectItem.price" />
              </template>
            </van-cell>
          </van-cell-group> -->
      <div
        class="large_btn"
        @click="checked ? buy(selectItem) : $toast('请选择套餐')"
      >
        <span>点击购买</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import { addClass, removeClass } from "@/utils";
import { worshipGoodsLight, worshipGoodsSave } from "@/api/memorialHall";
export default {
  data() {
    return {
      checked: false,
      show: false,
      active: 50, //
      selectItem: {}, //选中的灯
      selectPrice: 0, //选择价格
      lights: [],
    };
  },
  components: {
    navBar,
  },
  created() {
    this.id = this.$route.params.id;
    //根据选择的灯的类型改变选中值
    if (this.$route.query.active) {
      this.active = this.$route.query.active;
    }
  },
  computed: {
    cellTitle() {
      return `点亮${this.selectItem.validTime}天${this.selectItem.price}祭祀币`;
    },
  },
  async mounted() {
    //获取灯数据
    let { data } = await worshipGoodsLight({});
    this.lights = data.commoditiesResList.map((el) => {
      if (el.id == this.active) {
        this.selectItem = {
          id: el.id,
          url: el.imgUrl1,
          title: el.title,
          des: el.description,
          price: el.price,
          validTime: el.validTime,
        };
      }
      return {
        id: el.id,
        url: el.imgUrl1,
        title: el.title,
        des: el.description,
        price: el.price,
        validTime: el.validTime,
      };
    });
  },
  watch: {
    show(newVal) {
      if (!newVal) {
        this.checked = false;
      }
    },
  },
  methods: {
    //购买
    buy({ id }) {
      let params = {
        commoditiesId: id, //商品id
        memorialId: this.id,
      };
      console.log(id, "等id");
      worshipGoodsSave(params).then((res) => {
        let { code, msg } = res;
        if (code === "2000") {
          this.$toast.success("点亮成功!");
          this.show = false;
          this.$router.go(-1);
        } else {
          this.$toast(msg);
        }
      });
    },
    //单击每一项
    selectChange(item) {
      this.active = item.id;
      this.selectItem = item;
      console.log("222", item, this.selectItem);
    },
    //点亮长明灯
    lighting() {
      // console.log("111", this.selectItem);
      if (this.selectItem.price == 0) {
        //免费直接点亮
        //this.$toast.success("点亮成功");
        this.show = true;
        //this.$router.go(-1);
      } else {
        //收费
        this.show = true;
      }
    },
    setSelectPrice() {
      this.selectPrice = this.selectItem.price;
    },
  },
};
</script>

<style lang="scss" scoped>
.lighting {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  /deep/ .van-checkbox__icon--checked .van-icon{
    background-color: $defaultColor;
    border-color: $defaultColor;
}
  .content {
    padding: 0.1rem 0.17rem;
    flex: 1;
    overflow-y: scroll;
    .lights {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-wrap: wrap;
      -moz-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      min-height: 4.4rem;
      .light_item {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        width: 1.61rem;
        overflow: hidden;
        height: 2rem;
        position: relative;
        margin-bottom: 0.2rem;
        -moz-flex-direction: column;
        -webkit-flex-direction: column;
        flex-direction: column;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        box-shadow: 0px 0px 0.1rem 0.05rem #f0f0f0;
        padding: 0.21rem 0.325rem 0.11rem;
        // -moz-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center;
        .light_img {
          width: 0.7rem;
          height: 1.08rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        h3 {
          font-size: 14px;
          line-height: 0.22rem;
          margin-bottom: 0.05rem;
        }
        h5 {
          font-size: 12px;
          line-height: 0.17rem;
          color: $lightBlack;
          text-align: center;
        }
        .tips {
          position: absolute;
          background: $defaultColor;
          padding: 0.02rem 0.15rem;
          color: #fff;
          left: -10px;
          top: 5%;
          // z-index: 44;
          transform: rotate(-45deg);
        }
        .tips.price {
          background: linear-gradient(90deg, #00c391, #3c9bff);
        }
      }
      .light_item.active {
        border: 1px solid $defaultColor;
      }
      .light_item.active::after {
        content: "";
        position: absolute;
        width: 0.25rem;
        height: 0.25rem;
        right: 0;
        bottom: 0;
        z-index: 22;
        background: url("../../assets/imgs/selected.png") no-repeat;
        background-size: 100% 100%;
      }
    }

    .large_btn {
      margin-top: 0.3rem;
    }
  }
  .popup {
    padding: 0.17rem;
    h2 {
      font-size: 16px;
      line-height: 0.23rem;
      font-weight: 600;
    }
    .prices {
      .price {
        padding: 0.1rem 0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        -moz-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        .price_left {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
        }
        .price_right {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
        }
      }
    }
    .large_btn {
      margin: 0.25rem 0 0.15rem;
    }
  }
}
</style>
