<template>
  <!-- 纪念馆 -->
  <div class="memorialHall wh100">
    <navBar
      :title="detail.name + '的纪念馆'"
      :leftArrowFlag="true"
      @clickNavBarRight="goHome"
    >
      <i class="iconfont icon-shouyex"></i>
    </navBar>
    <div class="main" :style="{ backgroundImage: 'url(' + detail.bg + ')' }">
      <!-- 新增关注已关注 -->
      <div class="follow_box" @click="changeFollow" v-if="isMe == false">
        <div v-if="detail.hasFollow" class="follow_item has">
          <span>+关注</span>
        </div>
        <div v-else class="follow_item no"><span>已关注</span></div>
      </div>
      <!-- 头部 -->
      <div class="content_top">
        <div class="top_left">
          <div class="avatar_box">
            <img
              class="avatar"
              v-if="detail.avatar"
              :src="detail.avatar"
              alt=""
            />
            <img class="avatar" v-else :src="defaultAvatar" alt="" />
          </div>
          <div class="hall_num">
            <div class="num_item">
              <i class="iconfont icon-qinyou"></i>
              <span>{{ detail.familyNum }}</span>
            </div>
            <div class="num_item">
              <i class="iconfont icon-jibaitai"></i>
              <span>{{ detail.dotNum }}</span>
            </div>
          </div>
        </div>
        <div class="top_center">
          <div
            class="family_item"
            v-for="(item, index) in detail.familyList"
            :key="index"
          >
            <div class="avatar_box">
              <img
                class="avatar"
                v-if="item.avatar"
                :src="item.avatar"
                alt=""
              />
              <img class="avatar" v-else :src="defaultAvatar" alt="" />
            </div>
          </div>
        </div>
        <div class="top_right">
          <!-- 我管理的纪念馆展示邀请亲友 -->
          <!-- 他人的纪念馆展示加入亲友团 -->
          <span v-if="isMy == false" @click="goShare">邀请亲友</span>
          <span v-else @click="joinRelativesFriends">加入亲友团</span>
        </div>
      </div>
      <!-- tab -->
      <div class="tabs_wrap">
        <div class="tabs_item active"><span>首页</span></div>
        <div class="tabs_item" @click="goWorship"><span>祭拜</span></div>
      </div>
      <!-- 主要内容区 -->
      <div class="content_main">
        <!-- 左侧 -->
        <div class="main_left">
          <div
            :class="[autoPlay ? 'run' : '', 'left_item', 'music_box']"
            @click="closeMusic"
          >
            <i class="iconfont icon-yinle"></i>
          </div>
          <!-- <div class="left_item" @click="goTask">
            <i class="iconfont icon-xiachenducharenwupaiqi"></i>
            <span>任务</span>
          </div> -->
          <div class="left_item" @click="createHall">
            <i class="iconfont icon-fangjian"></i>
            <span>建馆</span>
          </div>
        </div>
        <!-- 中部 -->
        <div class="main_center">
          <div class="deads">
            <div
              class="dead_item"
              v-for="(item, index) in detail.deads"
              :key="index"
            >
              <div class="avatar_box">
                <img
                  class="avatar"
                  v-if="item.avatar"
                  :src="item.avatar"
                  alt=""
                />
                <img class="avatar" v-else :src="deadImg" alt="" />
              </div>
              <h3>{{ item.name }}</h3>
              <h5>{{ item.date }}</h5>
            </div>
          </div>
          <div class="intr">
            <p>{{ detail.des }}</p>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="main_right">
          <!-- <div class="right_item" @click="goRecallingSpace">
            <i class="iconfont icon-shikong-kongjianxuanzhong"></i>
            <span>追忆空间</span>
          </div> -->
          <div class="right_item" @click="showWorshipPopup = true">
            <i class="iconfont icon-jibaitai"></i>
            <span>供奉</span>
          </div>
          <div class="right_item" @click="goAlbum">
            <i class="iconfont icon-tupian"></i>
            <span>追忆空间</span>
          </div>
        </div>
      </div>
      <!-- 祭拜记录 -->
      <div class="worship_list">
        <vue-seamless-scroll
          :data="detail.worshipList"
          class="seamless-warp"
          :class-option="classOption"
        >
          <div
            class="list_item"
            v-for="(item, index) in detail.worshipList"
            :key="index"
          >
            <div class="avatar_box">
              <img
                class="avatar"
                v-if="item.avatar"
                :src="item.avatar"
                alt=""
              />
              <img class="avatar" v-else :src="deadImg" alt="" />
            </div>
            <div class="info">
              <span>{{ item.name }}</span
              >赠送了<span>{{ item.good }}</span>
            </div>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
    <!-- 底部留言 -->
    <div class="footer">
      <van-field
        v-model="message"
        center
        clearable
        placeholder="留言寄哀思..."
      />
      <div class="sub_btn" @click="sendMessage">留言</div>
    </div>
    <!-- 祭拜弹框 -->
    <van-popup
      :lock-scroll="false"
      class="popup_box"
      v-model="showWorshipPopup"
      position="bottom"
      :style="{ height: '55%' }"
    >
      <div class="popup_title"><span>请选择祭拜品</span></div>
      <div class="popup_content">
        <div class="left" ref="left">
          <ul class="content">
            <li
              v-for="(item, index) in left"
              :key="item"
              :class="{ current: currentIndex == index }"
              @click="selectItem(index, $event)"
            >
              <span class="left-item">{{ item }}</span>
            </li>
          </ul>
        </div>
        <div class="right" ref="right">
          <ul class="content">
            <li
              class="right-item right-item-hook"
              v-for="(item, index) in right"
              :key="index"
            >
              <h2>
                <span>{{ item.name }}</span>
              </h2>
              <ul>
                <li v-for="child in item.content" :key="child.id">
                  <div class="img_box">
                    <img :src="child.img" alt="" />
                  </div>
                  <div class="child_info">
                    <div class="info_left">
                      <h3>{{ child.name }}</h3>
                      <h4>
                        价格：<span>{{
                          child.price ? child.price / 100 + "元" : "免费"
                        }}</span>
                      </h4>
                    </div>
                    <div class="info_right" @click="gongfeng(child)">
                      <span>立即供奉</span>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="popup_bottom">
        <div class="bottom_left">
          <span>匿名赠送</span>
          <van-switch
            active-color="$defaultColor"
            inactive-color="#A4A4A4"
            v-model="isAnonymous"
          />
        </div>
        <div class="bottom_right" @click="goRecharge"><span>去充值</span></div>
      </div> -->
    </van-popup>
    <audio
      ref="music"
      autoplay
      preload="auto"
      loop
      v-if="detail.music"
      id="music"
      :src="detail.music"
    ></audio>
    <!-- 欢迎弹框 -->
    <van-overlay
      class="welcomeOverlay"
      :show="welcomeOverlay"
      @click="welcomeOverlay = false"
    >
      <div class="wrapper" @click.stop>
        <div class="box">
          <h2>欢迎来到纪念馆</h2>
          <h3>请选择跟逝者的关系</h3>
          <div class="large_btn btn" @click="iAmFamilyFriend">
            <span>我是亲友</span>
          </div>
          <div class="tourist_btn btn" @click="welcomeOverlay = false">
            <span>我是游客</span>
          </div>
        </div>
        <van-icon
          @click="welcomeOverlay = false"
          class="close_btn"
          name="close"
        />
      </div>
    </van-overlay>
    <!-- 加入亲友团弹框 -->
    <van-overlay
      class="joinOverlay"
      :show="joinOverlay"
      @click="joinOverlay = false"
    >
      <div class="wrapper" @click.stop>
        <div class="box">
          <div class="box_top"></div>
          <h3>恭喜成功加入亲友团</h3>
          <van-form @submit="onSubmit" class="form" ref="form">
            <van-field
              readonly
              clickable
              name="relationshipName"
              :value="joinForm.relationshipName"
              label="您是逝者的"
              placeholder="点击选择关系"
              @click="showPicker = true"
              :rules="[{ required: true, message: '请选择关系' }]"
            />
            <van-popup
              class="relationship_pop"
              v-model="showPicker"
              style="height: 50%"
              position="bottom"
            >
              <div class="popup_title">
                <div class="title_left" @click="showPicker = false">
                  <span>取消</span>
                </div>
                <div class="title_center"><span>请选择</span></div>
                <div class="title_right" @click="getRelationship">
                  <span>确定</span>
                </div>
              </div>
              <van-tabs v-model="active">
                <van-tab
                  :title="item.name"
                  v-for="(item, index) in relationship"
                  :key="index"
                >
                  <div class="relationship_list">
                    <div
                      :class="[
                        selectRelationshipItem.id == child.id ? 'active' : '',
                        'relationship_item',
                      ]"
                      @click="clickRelationshipItem(child)"
                      v-for="(child, index) in item.deadRelationRespList"
                      :key="index"
                    >
                      <span>{{ child.name }}</span>
                    </div>
                  </div>
                </van-tab>
              </van-tabs>
            </van-popup>
            <van-field
              v-model="joinForm.realName"
              name="realName"
              label="真实姓名"
              clearable
              placeholder="真实姓名"
              :rules="[{ required: true, message: '请填写真实姓名' }]"
            />
            <div style="margin: 16px">
              <van-button round block type="primary" native-type="submit"
                >确认</van-button
              >
            </div>
          </van-form>
        </div>
        <van-icon @click="joinOverlay = false" class="close_btn" name="close" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import vueSeamlessScroll from "vue-seamless-scroll";
import BScroll from "better-scroll";
import {
  oblationList,
  getMemorialAllInfo,
  checkJoinFamilyStatus,
  joinFamily,
  getFocusState,
  memorialFocus,
  oblationSave,
  noteOperation,
  showJoinPop,
} from "@/api/memorialHall";
import { fetchRelationshipList } from "@/api/relationship";
export default {
  data() {
    return {
      id: "", //纪念馆id
      deadImg: require("@/assets/imgs/deadImg.png"),
      defaultAvatar: require("@/assets/imgs/avatar.png"),
      isAnonymous: false, //是否匿名
      showWorshipPopup: false, //祭拜底部弹框
      welcomeOverlay: false, //欢迎弹框
      joinOverlay: false, //加入亲友团弹框
      message: "",
      autoPlay: true, //
      isMe: true, //是否我创建
      isMy: "", //true加入亲友团或false邀请亲友
      detail: {
        hasFollow: true, //是否已关注
        name: "",
        avatar: "",
        bg: require("@/assets/imgs/appointment/bg.jpg"),
        music: "",
        familyNum: 0,
        dotNum: 0,
      },
      classOption: {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
      showPicker: false, //选择器
      joinForm: {
        realName: "", //真实姓名
        relationshipName: "",
        deadRelationId: "", //关系
      },
      left: [],
      right: [],
      listHeight: [],
      scrollY: 0, //实时获取当前y轴的高度
      clickEvent: false,
      lefts: null,
      rights: null,
      active: 0,
      relationship: [], //亲友关系
      selectRelationshipItem: {}, //选中亲友关系
    };
  },
  components: {
    navBar,
    vueSeamlessScroll,
  },
  created() {
    this.id = this.$route.params.id;
    this.init();
    //获取是否展示加入亲友团弹框
    this.showJoinPop();
    //获取亲友关系
    this.getRelationshipList();
    //检测是否加入亲友团
    this.checkJoinFamilyStatus();
  },
  mounted() {
    // 播放音乐
    document.addEventListener("DOMContentLoaded", function () {
      this.audioAutoPlay();
    });
  },
  watch: {
    joinOverlay(newValue, oldValue) {
      if (!newValue) {
        this.joinForm = {
          realName: "", //真实姓名
          relationship: "", //关系
        };
        this.selectRelationshipItem = {};
        this.active = 0;
        this.$refs.form.resetValidation();
      }
    },
    showWorshipPopup(newValue, oldValue) {
      if (newValue) {
        this.$nextTick(() => {
          this._initScroll();
          this._getHeight();
        });
      }
    },
  },
  computed: {
    //
    currentIndex() {
      const index = this.listHeight.findIndex((item, index) => {
        return (
          this.scrollY >= this.listHeight[index] &&
          this.scrollY < this.listHeight[index + 1]
        );
      });
      return index > 0 ? index : 0;
    },
  },
  beforeDestroy() {
    document.removeEventListener("DOMContentLoaded", () => {});
  },
  methods: {
    //检测展示加入亲友团和邀请好友
    async checkJoinFamilyStatus() {
      let params = {
        memorialId: this.id,
        userId: localStorage.getItem("userId"),
      };
      let res = await checkJoinFamilyStatus(params);
      console.log(res, "shifouzai");
      if (res.code != "2000") {
        this.$toast.fail(res.msg || "数据请求失败");
      } else {
        this.isMy = res.data;
      }
    },
    //亲友关系
    async getRelationshipList() {
      let res = await fetchRelationshipList({});
      if (res.code != "2000") {
        this.$toast.fail(res.msg || "数据请求失败");
      } else {
        this.relationship = res.data;
      }
    },
    //否展示加入亲友团弹框
    async showJoinPop() {
      let params = {
        memorialId: this.id,
        userId: localStorage.getItem("userId"),
      };
      let res = await showJoinPop(params);
      if (res.code != "2000") {
        this.$toast.fail(res.msg || "数据请求失败");
      } else {
        this.welcomeOverlay = res.data;
      }
    },
    //获取数据转化为页内所需格式
    init() {
      let userId = localStorage.getItem("userId") || "";
      //获取纪念馆所有信息
      getMemorialAllInfo({ id: this.id }).then((res) => {
        console.log(res, "01212");
        let { data } = res;
        let {
          deaderInfos,
          memorialInfo,
          flag,
          memorialSpuImgs,
          memorialWorshipeds,
          relativesFriendsCount,
          relativesFriendsavatarUrl,
          memorialWorshipedsNums,
        } = data;
        //将值转换为当前页面值
        this.detail.name = memorialInfo.memorialName;
        this.detail.des = memorialInfo.epitaph;
        this.detail.dotNum = memorialWorshipedsNums;
        this.detail.familyList = relativesFriendsavatarUrl
          .slice(0, 5)
          .map((el) => {
            return {
              id: "",
              avatar: el,
            };
          });
        this.detail.worshipList = memorialWorshipeds.map((el) => {
          return {
            id: el.id,
            avatar: el.operatorVia,
            name: el.operatorNick,
            good: el.sacrificeName,
          };
        });
        this.detail.deads = deaderInfos.map((el) => {
          return {
            id: el.idCardNo,
            name: el.deadName,
            avatar: el.deadPortraitUrl,
            date: el.birthday.slice(0, 4) + "-" + el.deathday.slice(0, 4),
          };
        });
        this.detail.familyNum = relativesFriendsCount;
        this.detail.avatar = deaderInfos[0].deadPortraitUrl;
        this.isMe = flag; //是否我创建
        for (let i = 0; i < memorialSpuImgs.length; i++) {
          let el = memorialSpuImgs[i];
          if (el.specificType === "backgroundImg") {
            //背景图
            this.detail.bg = JSON.stringify(el.fileUrl);
          } else if (el.specificType === "sacrificeImg") {
            //祭祀图
          } else if (el.specificType === "backgroundMp3") {
            //背景音乐
            this.detail.music = el.fileUrl;
          }
        }
        console.log(data);
      });
      //获取供奉相关信息
      oblationList({}).then((res) => {
        let { data } = res;
        this.left = [];
        this.right = data.map((el) => {
          let name = el.title;
          this.left.push(name);
          let content = el.commoditiesList.map((item) => {
            let { title, img, id, price } = item;
            return {
              id,
              name: title,
              price,
              img,
            };
          });
          return {
            name,
            content,
          };
        });

        console.log(data);
      });

      //获取纪念馆关注状态
      getFocusState({ memoriaId: this.id, userId }).then(({ data }) => {
        console.log(data, "是否关注");
        if (data == "y") {
          this.detail.hasFollow = false;
        } else {
          this.detail.hasFollow = true;
        }
      });
    },
    //关注/取消关注
    changeFollow() {
      this.detail.hasFollow = !this.detail.hasFollow;
      let focus=''
      if(this.detail.hasFollow){
        focus='n'
      }else{
        focus='y'
      }
      memorialFocus({
        memoriaId: this.id,
        focus,
        userId: localStorage.getItem("userId") || "",
      }).then((res) => {
        if (this.detail.hasFollow) {
          this.$toast.success("取关成功!");
        } else {
          this.$toast.success("关注成功!");
        }
      });
    },
    //去分享
    goShare() {
      this.$router.push(`/memorialHall/share/${this.$route.params.id}`);
    },
    //加入亲友团
    joinRelativesFriends() {
      this.joinOverlay = true;
    },
    //选择与逝者的关系
    onConfirm(value) {
      this.joinForm.relationship = value;
      this.showPicker = false;
    },
    //提交表单加入亲友团
    async onSubmit() {
      const { deadRelationId, realName } = this.joinForm;
      let params = {
        friendsType: "qy",
        deadRelationId,
        memorialId: this.id,
        realName,
      };
      let res = await joinFamily(params);
      if (res.code != "2000") {
        this.$toast.fail(res.msg || "数据请求失败");
      } else {
        this.$toast.success(res.msg);
        this.joinOverlay = false;
      }
    },
    //回首页
    goHome() {
      this.$router.push("/");
    },
    //去相册
    goAlbum() {
      this.$router.push(`/memorialHall/album/${this.$route.params.id}`);
    },
    //tab去祭拜页面
    goWorship() {
      this.$router.push(`/memorialHall/worship/${this.$route.params.id}`);
    },
    //去任务
    goTask() {
      this.$toast("暂未开放");
    },
    //建馆
    createHall() {
      this.$router.push("/createMemorialHall");
    },
    //追忆空间
    goRecallingSpace() {
      this.$toast("暂未开放");
      // this.$router.push(
      //   `/memorialHall/recallingSpace/${this.$route.params.id}`
      // );
    },
    //音乐播放
    audioAutoPlay() {
      this.$nextTick(() => {
        let audio = this.$refs.music;
        audio.play();
        document.addEventListener(
          "WeixinJSBridgeReady",
          function () {
            audio.play();
          },
          false
        );
      });
    },
    //音乐暂停
    audioAutoPause() {
      let audio = this.$refs.music;
      audio.pause();
      document.addEventListener(
        "WeixinJSBridgeReady",
        function () {
          audio.pause();
        },
        false
      );
    },
    //音乐暂停
    closeMusic() {
      this.autoPlay = !this.autoPlay;
      if (this.autoPlay) {
        //播放
        this.audioAutoPlay();
      } else {
        //暂停
        this.audioAutoPause();
      }
    },
    //留言
    sendMessage() {
      if (!this.message) {
        return;
      }
      let params = {
        memoriaId: this.id,
        contentType: "ly",
        content: this.message,
        userId: localStorage.getItem("userId") || "",
      };
      noteOperation(params).then(() => {
        this.message = "";
        this.$toast.success("已留言");
      });
    },
    //去充值
    goRecharge() {
      this.$router.push("/my/recharge");
    },
    //初始化betterscroll
    _initScroll() {
      //better-scroll的实现原理是监听了touchStart,touchend事件，所以阻止了默认的事件（preventDefault）
      //所以在这里做点击的话，需要在初始化的时候传递属性click,派发一个点击事件
      //在pc网页浏览模式下，点击事件是不会阻止的，所以可能会出现2次事件，所以为了避免2次，可以在绑定事件的时候把$event传递过去
      if (!this.lefts) {
        this.lefts = new BScroll(this.$refs.left, {
          click: true,
        });
      } else {
        this.lefts.refresh();
      }
      if (!this.rights) {
        this.rights = new BScroll(this.$refs.right, {
          click: true,
          probeType: 3, //探针的效果，实时获取滚动高度
        });
      } else {
        this.rights.refresh();
      }
      //rights这个对象监听事件，实时获取位置pos.y
      this.rights.on("scroll", (pos) => {
        this.scrollY = Math.abs(Math.round(pos.y));
      });
    },
    //获取滚动区域高度数组
    _getHeight() {
      let rightItems = this.$refs.right.getElementsByClassName(
        "right-item-hook"
      );
      let height = 0;
      this.listHeight.push(height);
      for (let i = 0; i < rightItems.length; i++) {
        let item = rightItems[i];
        height += item.clientHeight;
        this.listHeight.push(height);
      }
      console.log(this.listHeight, "98989");
    },
    //点击左侧分类
    selectItem(index, event) {
      this.clickEvent = true;
      //在better-scroll的派发事件的event和普通浏览器的点击事件event有个属性区别_constructed
      //浏览器原生点击事件没有_constructed所以当时浏览器监听到该属性的时候return掉
      if (!event._constructed) {
        return;
      } else {
        let rightItems = this.$refs.right.getElementsByClassName(
          "right-item-hook"
        );
        let el = rightItems[index];
        this.rights.scrollToElement(el, 300);
      }
    },
    //供奉
    gongfeng(good) {
      let params = {
        memoriaId: this.id,
        sacrificeName: good.name,
        sacrificeNum: 1,
        sacrificeId: good.id,
        sacrificePrice: good.price,
        userId: localStorage.getItem("userId") || "",
      };
      oblationSave(params).then((res) => {
        this.showWorshipPopup = false;
        this.$toast.success("供奉成功");
        this.init();
      });
    },
    //关系每项点击
    clickRelationshipItem(item) {
      // this.deadRelationId=item.id
      this.selectRelationshipItem = item;
    },
    //确定获取选择的关系
    getRelationship() {
      this.joinForm.deadRelationId = this.selectRelationshipItem.id;
      this.joinForm.relationshipName = this.selectRelationshipItem.name;
      this.showPicker = false;
    },
    //我是亲友
    iAmFamilyFriend() {
      this.welcomeOverlay = false;
      this.joinOverlay = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.memorialHall {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  .main {
    flex: 1;
    padding: 0.15rem 0.12rem 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    .follow_box {
      position: absolute;
      top: 0.75rem;
      right: 0.12rem;
      .follow_item {
        width: 0.65rem;
        height: 0.3rem;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center;
        font-size: 14px;
        color: #fff;
        border-radius: 0.05rem;
      }
      .follow_item.has {
        background: #eda425;
      }
      .follow_item.no {
        background: #888888;
      }
    }
    // 头部
    .content_top {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-align-items: center;
      -webkit-align-items: center;
      align-items: center;
      -moz-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      margin-bottom: 0.15rem;
      .top_left {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        width: 1rem;
        height: 0.45rem;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 0.45rem;
        padding: 0.05rem;
        .avatar_box {
          width: 0.35rem;
          height: 0.35rem;
          border-radius: 50%;
          margin-right: 0.05rem;
          
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .hall_num {
          color: #fff;
        }
      }
      .top_center {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        .family_item {
          .avatar_box {
            width: 0.35rem;
            height: 0.35rem;
            border-radius: 50%;
            margin-right: -0.1rem;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
        }
      }
      .top_right {
        width: 0.85rem;
        height: 0.25rem;
        border-radius: 0.25rem;
        background: $defaultColor;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        font-size: 14px;
        color: #fff;
        span {
          line-height: 0.25rem;
        }
      }
    }
    //tab
    .tabs_wrap {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      width: 100%;
      -moz-align-items: center;
      -webkit-align-items: center;
      align-items: center;
      padding: 0 1.05rem;
      -moz-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      margin-bottom: 0.25rem;
      .tabs_item {
        width: 0.65rem;
        height: 0.3rem;
        background: #efefef;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        border-radius: 0.3rem;
        span {
          line-height: 0.3rem;
          font-size: 16px;
          color: $lightBlack;
        }
      }
      .tabs_item.active {
        background: $defaultColor;
        span {
          color: #fff;
        }
      }
    }
    //主要内容区
    .content_main {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      margin-bottom: 0.15rem;
      // 左侧
      .main_left {
        .left_item {
          margin-bottom: 0.25rem;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -moz-flex-direction: column;
          -webkit-flex-direction: column;
          flex-direction: column;
          -moz-justify-content: center;
          -webkit-justify-content: center;
          justify-content: center;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
          color: #fff;
          background: $defaultColor;
          width: 0.55rem;
          height: 0.55rem;
          border-radius: 0.08rem;
          i {
            font-size: 23px;
            margin-bottom: 0.04rem;
          }
          span {
            font-size: 12px;
            line-height: 0.17rem;
          }
        }
        .music_box {
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.4);
        }
        .left_item.run {
          animation: turn 10s linear infinite;
        }
      }
      .main_center {
        min-width: 2rem;
        padding-top: 0.2rem;
        .deads {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
          -moz-justify-content: center;
          -webkit-justify-content: center;
          justify-content: center;
          margin-bottom: 0.15rem;
          .dead_item {
            margin: 0 0.05rem;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -moz-flex-direction: column;
            -webkit-flex-direction: column;
            flex-direction: column;
            -moz-align-items: center;
            -webkit-align-items: center;
            align-items: center;
            .avatar_box {
              width: 1rem;
              height: 1.11rem;
              margin-bottom: 0.1rem;
              padding:0.05rem;
            background: url('../../assets/imgs/border.png') no-repeat top center;
            background-size: 100% 100%;
              img {
                width: 100%;
                height: 100%;
                border-radius: 0.05rem;
              }
            }
            h3 {
              font-size: 12px;
              line-height: 0.25rem;
            }
            h5 {
              font-size: 12px;
              line-height: 0.21rem;
              color: $lightBlack;
            }
          }
        }
        .intr {
          width: 2rem;
          p {
            font-size: 12px;
            line-height: 0.2rem;
            color: $lightBlack;
          }
        }
      }
      .main_right {
        .right_item {
          margin-bottom: 0.25rem;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -moz-flex-direction: column;
          -webkit-flex-direction: column;
          flex-direction: column;
          -moz-justify-content: center;
          -webkit-justify-content: center;
          justify-content: center;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
          color: #fff;
          background: $defaultColor;
          width: 0.55rem;
          height: 0.55rem;
          border-radius: 0.08rem;
          i {
            font-size: 23px;
            margin-bottom: 0.04rem;
          }
          span {
            font-size: 12px;
            line-height: 0.17rem;
          }
        }
      }
    }
    .worship_list {
      .seamless-warp {
        height: 1.22rem;
        overflow: hidden;
        .list_item {
          padding: 0.05rem 0.1rem 0.05rem 0.05rem;
          // height: 0.3rem;
          width: max-content;
          margin-bottom: 0.07rem;
          border-radius: 0.3rem;
          color: #fff;
          // background: linear-gradient(
          //   90deg,
          //   rgba(60, 155, 255, 0.8),
          //   rgba(0, 195, 145, 0.8)
          // );
          //background: rgba(83, 177, 117, 0.5);
          background: rgba(138, 118, 81, 0.5);
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
          .avatar_box {
            width: 0.25rem;
            height: 0.25rem;
            margin-right: 0.05rem;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .info {
            font-size: 12px;
          }
        }
      }
    }
  }
  // 底部留言
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 0.45rem;
    background: #d0d0d0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    padding: 0.04rem 0.12rem;
    /deep/ .van-cell {
      padding: 0.1rem !important;
      border-radius: 0.05rem;
    }
    .sub_btn {
      width: 0.6rem;
      height: 0.37rem;
      margin-left: 0.14rem;
      background: $defaultColor;
      color: #fff;
      border-radius: 0.05rem;
      text-align: center;
      line-height: 0.37rem;
    }
  }
  // 欢迎弹框
  .welcomeOverlay {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
    text-align: center;
    .box {
      width: 3rem;
      height: 3.6rem;
      background: url("../../assets/imgs/welcome.png") no-repeat;
      background-size: contain;
      background-color: #fff;
      padding: 1.3rem 0.25rem 0.25rem;
      border-top-left-radius: 0.05rem;
      border-top-right-radius: 0.05rem;
      border-bottom-left-radius: 0.06rem;
      border-bottom-right-radius: 0.06rem;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-flex-direction: column;
      -webkit-flex-direction: column;
      flex-direction: column;
      -moz-align-items: center;
      -webkit-align-items: center;
      align-items: center;
      h2 {
        font-size: 16px;
        line-height: 0.23rem;
        margin-bottom: 0.07rem;
      }
      h3 {
        font-size: 14px;
        line-height: 0.2rem;
        color: $lightBlack;
      }
      .btn {
        margin: 0.3rem 0 0;
      }
      .tourist_btn {
        background: #d4d4d4;
        color: #fff;
        width: 100%;
        height: 0.44rem;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center;
        border-radius: 0.05rem;
        box-shadow: 0px 0px 0.1rem 0.05rem #f0f0f0;
      }
    }
    .close_btn {
      color: #fff;
      font-size: 40px;
      margin: 0.15rem auto 0;
    }
  }
  // 加入亲友团
  .joinOverlay {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
    text-align: center;
    .box {
      width: 3rem;
      background: #fff;
      border-radius: 0.05rem;
      padding-bottom: 0.1rem;
      .box_top {
        width: 100%;
        height: 1.47rem;
        background: url("../../assets/imgs/join.png") no-repeat;
        background-size: contain;
      }
      h3 {
        font-size: 16px;
      }
      .form {
        /deep/ .van-cell {
          padding: 0.1rem 0.16rem !important;
        }
        .relationship_pop {
          .popup_title {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -moz-justify-content: space-between;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            width: 100%;
            height: 0.44rem;
            -moz-align-items: center;
            -webkit-align-items: center;
            align-items: center;
            padding: 0 0.12rem;
            .title_left {
              color: $lightBlack;
            }
            .title_center {
            }
            .title_right {
              color: rgb(68, 83, 152);
            }
          }
          /deep/ .van-tab--active {
            background: $defaultColor;
            color: #fff;
          }
          /deep/ .van-tabs__line {
            display: none;
          }
          .relationship_list {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-wrap: wrap;
            .relationship_item {
              border: #ddd solid 0.5px;
              border-radius: 0.05rem;
              margin: 0.05rem;
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -moz-align-items: center;
              -webkit-align-items: center;
              align-items: center;
              -moz-justify-content: center;
              -webkit-justify-content: center;
              justify-content: center;
              height: 0.3rem;
              color: #333;
              background-color: #fff;
              padding: 0 0.15rem;
            }
            .relationship_item.active {
              background: $defaultColor;
              color: #fff;
            }
          }
        }
      }
    }
    .close_btn {
      color: #fff;
      font-size: 40px;
      margin: 0.15rem auto 0;
    }
  }
  //祭拜弹框
  .popup_box {
    background: #f1f1f1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    .popup_title {
      width: 100%;
      height: 0.44rem;
      background: $defaultColor;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-align-items: center;
      -webkit-align-items: center;
      align-items: center;
      color: #fff;
      padding-left: 0.15rem;
      font-size: 16px;
    }
    .popup_content {
      flex: 1;
      // margin-bottom: 0.12rem;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      overflow: hidden;
      .left {
        flex: 0 0 1rem;
        background-color: #fff;
        margin-right: 0.1rem;
        li {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
          -moz-justify-content: center;
          -webkit-justify-content: center;
          justify-content: center;
          width: 100%;
          height: 0.5rem;
          position: relative;
        }
        .current {
          background: #f1f1f1;
          color: $defaultColor;
        }
        .current::after {
          content: "";
          width: 4px;
          height: 15px;
          border-radius: 5px 0 0 5px;
          position: absolute;
          background-color: $defaultColor;
          top: 0;
          right: 0px;
          bottom: 0;
          margin: auto;
        }
      }

      .right {
        flex: 1;

        .right-item {
          margin-top: 0.1rem;
          background-color: #fff;
          h2 {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -moz-align-items: center;
            -webkit-align-items: center;
            align-items: center;
            padding-left: 0.15rem;
            height: 0.5rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            span {
              margin-left: 0.05rem;
            }
          }
          h2::before {
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: $defaultColor;
          }
          li {
            width: 100%;
            padding: 0.15rem 0.1rem 0.15rem 0.15rem;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -moz-align-items: center;
            -webkit-align-items: center;
            align-items: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            .img_box {
              width: 0.6rem;
              height: 0.6rem;
              background: #efefef;
              border-radius: 0.03rem;
              padding: 0.1rem;
              margin-right: 0.08rem;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .child_info {
              flex: 1;
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -moz-align-items: center;
              -webkit-align-items: center;
              align-items: center;
              -moz-justify-content: space-between;
              -webkit-justify-content: space-between;
              justify-content: space-between;
              .info_left {
                h3 {
                  font-size: 14px;
                  line-height: 0.2rem;
                  margin-bottom: 0.05rem;
                }
                h4 {
                  display: -webkit-box;
                  display: -moz-box;
                  display: -ms-flexbox;
                  display: -webkit-flex;
                  display: flex;
                  -moz-align-items: center;
                  -webkit-align-items: center;
                  align-items: center;
                  font-size: 12px;
                  color: $lightBlack;
                  span {
                    color: $defaultColor;
                  }
                }
              }
              .info_right {
                width: 0.72rem;
                height: 0.3rem;
                color: #fff;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -moz-align-items: center;
                -webkit-align-items: center;
                align-items: center;
                -moz-justify-content: center;
                -webkit-justify-content: center;
                justify-content: center;
                background: $defaultColor;
                border-radius: 0.06rem;
              }
            }
          }
        }
      }
    }
    .popup_bottom {
      width: 100%;
      background: #ffff;
      height: 0.44rem;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      padding: 0 0.12rem;
      -moz-align-items: center;
      -webkit-align-items: center;
      align-items: center;
      -moz-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      .bottom_left {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        span {
          margin-right: 0.08rem;
        }
        /deep/ .van-switch {
          width: 0.5rem;
          height: 0.25rem;
          border-radius: 0.25rem;
          .van-switch__node {
            width: 0.24rem;
            height: 0.24rem;
          }
        }
        /deep/ .van-switch--on .van-switch__node {
          transform: translateX(0.25rem);
        }
      }
    }
  }
}
@keyframes turn {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(72deg);
  }

  40% {
    transform: rotate(144deg);
  }

  60% {
    transform: rotate(216deg);
  }

  80% {
    transform: rotate(288deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
