import request from '@/utils/request'

//纪念馆设置展示
export function memorialInfoDetail(data) {
  return request({
    url:'/app/memorialInfo/memorialInfoDetail',
    method: 'post',
    data
  })
}
//获取背景图片及音乐
export function memorialInfoDetailImgOrMp3(data) {
  return request({
    url:'/app/memorialInfo/memorialInfoDetailImgOrMp3',
    method: 'post',
    data
  })
}
//设置纪念馆信息,包括删除
export function setMemorialInfo(data) {
  return request({
    url:'/app/memorialInfo/set',
    method: 'post',
    data
  })
}

//升级双人棺
export function memorialInfo_Detail(data) {
  return request({
    url:'/app/memorialInfo/detail',
    method: 'post',
    data
  })
}

//双人馆数据保存
export function saveDeadInfo(data) {
  return request({
    url:'/app/memorialInfo/saveDeadInfo',
    method: 'post',
    data
  })
}

