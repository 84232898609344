<template>
  <div class="familyList wh100">
    <navBar title="亲友管理" :leftArrowFlag="true" />
    <div class="list_content">
      <vantList
        :list="list"
        :loading="loading"
        :finished="finished"
        :refreshing="refreshing"
        @getList="getList"
        @refresh="refresh"
      >
        <div class="list" v-for="(item, index) in list" :key="index">
          <familyListItem :item="item" @setItem="setItem"></familyListItem>
        </div>
      </vantList>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import vantList from "@/components/list";
import familyListItem from "@/components/memorialHallItem/familyListItem";
import { getUserId } from "@/utils/auth";
import {
  memorialInfo_RelativesFriends,
  memorialInfo_relevancyState,
} from "@/api/memorialHall/familyList";

export default {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      pageSize: 10,
      currentPage: 1,
      loginUserId: "",
      id: "",
      list: [],
    };
  },
  components: {
    navBar,
    vantList,
    familyListItem,
  },
  mounted() {
    this.loginUserId = getUserId();
    this.id = this.$route.params.id;
    this.getList();
  },
  methods: {
    //请求数据
    async getList() {
      this.loading = true;
      const { currentPage, pageSize } = this;
      let params = {
        memorialInfoId: this.id, //纪念馆主键
        loginUserId: this.loginUserId, //登陆id
        currentPage,
        pageSize,
      };
      let res = await memorialInfo_RelativesFriends(params);
      this.loading = false;
      this.refreshing = false;
      if (res.code === "2000") {
        let list = res.data || [];
        //根据总条数处理
        if (this.list.length < res.total) {
        this.list = [...this.list, ...list];
          this.currentPage++;
        } else {
          this.finished = true;
          this.loading = false;
        }
        // // //根据总条数处理
        // if (this.list.length < res.total) {
        //   this.list = [...this.list, ...res.data];
        //   //   this.currentPage++;
        // } else {
        //   this.finished = true;
        //   this.loading = false;
        // }
        // // 如果当前页数 = 总页数，则已经没有数据
        // if (res.currentPage == this.currentPage) {
        //   this.finished = true;
        //   this.loading = false;
        // }
        // // 如果总页数大于当前页码，页码+1
        // else if (res.currentPage > this.currentPage) {
        //   this.currentPage++;
        // }
      }
    },

    async setItem(e) {
      let postData = {
        memorialRelativesFriendsId: e.id,
        mrfState: e.state,
        loginUserId: this.loginUserId,
      };
      console.log(postData, "postData");
      try {
        const res = await memorialInfo_relevancyState(postData);
        console.log(res, 200);
        if (res.code === "2000") {
          this.$toast.success("操作成功");
          this.refresh();
          return;
        }
        this.$toast.fail(res.msg || "操作失败");
      } catch (err) {
        console.log(err, 404);
        this.$toast.fail(err.msg);
      }
    },
    //下拉刷新
    refresh() {
      this.refreshing = true;
      // 清空列表数据
      this.currentPage = 1;
      this.list = [];
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.familyList {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  .list_content {
    flex: 1;
    background: #f9f9f9;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 0 0.125rem;
    /deep/ .van-list {
      width: 100%;
    }
  }
}
</style>
