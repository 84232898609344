import request from '@/utils/request'

//纪念馆设置展示
export function memorialInfoDetail(data) {
  return request({
    url:'/app/memorialInfo/detail',
    method: 'post',
    data
  })
}

export function memorialInfoOperation(data) {
  return request({
    url:'/app/memorialInfo/operation',
    method: 'post',
    data
  })
}