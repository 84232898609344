import request from '@/utils/request'

export function getList(data) {
  return request({
    url: '/app/note/list',
    method: 'post',
    data
  })
}

export function remove(data) {
  return request({
    url: '/app/note/delete',
    method: 'post',
    data
  })
}

export function agree(data) {
  return request({
    url: '/app/note/agree',
    method: 'post',
    data
  })
}

export function refuse(data) {
  return request({
    url: '/app/note/refuse',
    method: 'post',
    data
  })
}
//悼文详情
export function fetchEulogyDetail(data) {
  return request({
    url: '/app/note/detail',
    method: 'post',
    data
  })
}