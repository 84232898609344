<template>
  <div class="news_detail wh100">
    <navBar :title="title" :leftArrowFlag="true"></navBar>
    <div class="content">
      <h3>{{ detail.title }}</h3>
      <p class="time">发布于{{ detail.gmtCreate | timeFormat1 }}</p>
      <p v-html="detail.content"></p>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import { getNewDetail } from '@/api/news'
export default {
  name: "newsDetail",
  data() {
    return {
      title: "新闻详情",
      detail: {
        
      },
    };
  },
  components: {
    navBar,
  },
  filters: {
    
  },
  created() {
    this.getNewsDetail(this.$route.params.id)
  },
  methods:{
    async getNewsDetail(id){
      let params={
        id
      }
      let res= await getNewDetail(params)
      if(res.code!='2000') return this.$toast.fail(res.msg || "数据请求失败")
      this.detail=res.data
    }
  }
};
</script>

<style lang="scss" scoped>
.news_detail {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  
  .content {
    flex: 1;
    padding: 0.15rem;
    overflow-y: scroll;
    h3 {
      text-align: center;
      font-size: 0.18rem;
    }
    .time {
      text-align: center;
      font-size: 0.12rem;
      color: #a1a1a1;
      margin: 0.1rem 0 0.15rem;
    }
    p{
      line-height: 1.5em;
      text-indent: 2em;
      img{
        width: 100%;
      }
    }
    
    
  }
}
</style>
