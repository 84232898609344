<template>
  <div class="personal_data wh100">
    <navBar title="个人资料" :leftArrowFlag="true" />
    <div class="form_wrap">
      <van-form @submit="onSubmit" class="form">
        <!-- <van-field name="uploader" label="头像" class="avatar_box">
          <template #input>
            <van-uploader :after-read='afterUpload'  :before-read="beforeUpload" v-model="form.uploader" >
              <img class="avatar" src="../../../assets/imgs/avatar.png" alt="">
            </van-uploader>
          </template>
        </van-field> -->
        <!-- <van-field name="uploader" label="头像" class="avatar_box">
          <template #input>
            <van-uploader
              :after-read="afterUpload"
              :max-count="1"
              :before-read="beforeUpload"
              v-model="form.uploader"
            >
              <img class="avatar" :src="avatar" alt="" />
            </van-uploader>
          </template>
        </van-field> -->
        <van-field name="uploader" label="头像" class="avatar_box">
          <template #input>
            <van-uploader
              :after-read="afterUpload"
              :max-count="1"
              :max-size="3 * 1024 * 1024"
              :before-read="beforeUpload"
              @oversize="onOversize"
              v-model="form.uploader"
            >
              <img class="avatar" :src="avatar" alt="" />
            </van-uploader>
          </template>
        </van-field>
        <van-field
          v-model="form.nickname"
          name="nickname"
          label="昵称"
          placeholder="请输入您的昵称"
        />
        <van-field
          v-model="form.name"
          name="name"
          label="姓名"
          placeholder="请输入您的姓名"
        />

        <van-field
          readonly
          clickable
          name="birthday"
          :value="form.birthday"
          label="生日"
          right-icon="arrow-down"
          placeholder="点击选择生日"
          @click="showCalendar = true"
        />
        <van-popup v-model="showCalendar" position="bottom">
          <van-datetime-picker
            type="date"
            title="选择年月日"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="onConfirm1"
            @cancel="showCalendar = false"
          />
        </van-popup>

        <!-- <van-field
          v-model="form.sex"
          name="sex"
          label="性别"
          placeholder="请输入您的性别"
        /> -->
        <van-field name="gender" label="选择性别">
          <template #input>
            <van-radio-group v-model="form.gender" direction="horizontal">
              <van-radio :name="1">男</van-radio>
              <van-radio :name="-1">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          readonly
          clickable
          name="userArea"
          :value="form.userArea"
          label="地区选择"
          placeholder="点击选择省市区"
          @click="showArea = true"
        />
        <van-field
          name="introduce"
          v-model="form.introduce"
          rows="4"
          autosize
          label="个人简介"
          type="textarea"
          maxlength="100"
          placeholder="请输入个人简介"
          show-word-limit
        />
        <van-popup v-model="showArea" position="bottom">
          <van-area
            :area-list="areaList"
            @confirm="onConfirm"
            @cancel="showArea = false"
          />
        </van-popup>
        <div class="btn_box">
          <van-button
            :loading="btnLoading"
            :disabled="btnDisabled"
            round
            block
            type="primary"
            native-type="submit"
            >确认修改</van-button
          >
        </div>
      </van-form>
    </div>
    <!-- 剪裁图片组件 -->
    <!-- <van-popup
      class="bg-tran"
      v-model="showCropper"
      closeable
      position="top"
      :style="{ height: '100%' }"
    >
      <div class="flex-column-center height100">
        <vueCropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.outputSize"
          :outputType="option.outputType"
          :info="option.info"
          :full="option.full"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :autoCrop="option.autoCrop"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :centerBox="option.centerBox"
          :infoTrue="option.infoTrue"
          :fixedBox="option.fixedBox"
          :high="option.high"
          :mode="option.mode"
        ></vueCropper>
        <div class="popup_bottom">
          <div class="bottom_item">
            <span @click="cancelCropper">取消</span>
          </div>
          <div class="bottom_item">
            <span @click="rotateImage" class="font18"
              ><van-icon name="replay"
            /></span>
          </div>
          <div class="bottom_item"><span @click="getCropBlob">确定</span></div>
        </div>
      </div>
    </van-popup> -->
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import areaList from "@/utils/areaList";
import * as imageConversion from "image-conversion";
import { operation, getData } from "@/api/my/personalData";

import { VueCropper } from "vue-cropper";
export default {
  name: "personalData",
  data() {
    return {
      // showCropper: false, // 截图弹窗遮罩默认隐藏
      // imageAccept: "/jpg,/png,/jpeg",
      // imageFileName: "",
      // option: {
      //   img: "",
      //   outputSize: 0.8,
      //   info: false, // 裁剪框的大小信息
      //   outputType: "jpeg", // 裁剪生成图片的格式
      //   canScale: false, // 图片是否允许滚轮缩放
      //   autoCrop: true, // 是否默认生成截图框
      //   autoCropWidth: window.innerWidth - 100 + "px", // 默认生成截图框宽度
      //   autoCropHeight: window.innerWidth - 100 + "px", // 默认生成截图框高度
      //   high: true, // 是否按照设备的dpr 输出等比例图片
      //   fixedBox: true, // 固定截图框大小 不允许改变
      //   fixed: true, // 是否开启截图框宽高固定比例
      //   fixedNumber: [1, 1], // 截图框的宽高比例
      //   full: true, // 是否输出原图比例的截图
      //   canMoveBox: false, // 截图框能否拖动
      //   original: false, // 上传图片按照原始比例渲染
      //   centerBox: false, // 截图框是否被限制在图片里面
      //   infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      //   mode: "100% auto", // 图片默认渲染方式
      // },
      btnLoading: false,
      btnDisabled: false,
      showCalendar: false,
      avatar: require("../../../assets/imgs/avatar.png"),
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      form: {
        id: "",
        uploader: [],
        nickname: "",
        name: "",
        birthday: "",
        gender: "",
        userArea: "",
        introduce: "",
        avatarUrl: "",
      },
      showArea: false,
      areaList: areaList, // 数据格式见 Area 组件文档
    };
  },
  components: {
    navBar,
   // VueCropper,
  },
  created() {
    this.init();
    
  },
  methods: {
    // 上传文件过大
    onOversize() {
      this.$toast("图片不能大于3M");
    },

    // 获取文件后缀
    getFileSuffix(fileName) {
      return fileName.match(/\/\w+$/)[0].toLowerCase();
    },
    init() {
      getData({})
        .then(({ data }) => {
          let {
            id,
            avatarUrl,
            nickname,
            name,
            birthday,
            gender,
            userArea,
            introduce,
          } = data;
          this.form = {
            avatarUrl:avatarUrl?avatarUrl:this.avatar,
            id,
            nickname,
            name,
            birthday,
            gender,
            userArea,
            introduce,
            uploader: [{ url: avatarUrl, isImage: true }],
          };
          // if(avatarUrl){
          //   this.form.uploader=[
          //     {
          //       url:avatarUrl
          //     }
          //   ]
          // }else{
          //   this.form.uploader=[]
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    beforeUpload(file) {
      // if (file.type !== 'image/jpeg') {
      //   this.$toast('请上传 jpg 格式图片');
      //   return false;
      // }
      //return true;
      return new Promise((resolve, reject) => {
        console.log("压缩前", file); // 压缩到400KB,大于400KB的图片都会进行压缩，小于则不会
        imageConversion.compressAccurately(file, 100).then((res) => {
          // console.log(res)
          res = new File([res], file.name, {
            type: res.type,
            lastModified: Date.now(),
          });
          console.log("压缩后", res);
           this.showCropper = true;
          resolve(res);
        });
      });
    },
    afterUpload(file) {
      console.log(file, "111");
    },
    onSubmit(data) {
      let params = { ...this.form };
      if (data.uploader.length > 0) {
        params.avatarUrl = data.uploader[0].content;
      } else {
        params.avatarUrl = "";
      }
      // params.avatarUrl==data.uploader?data.uploader[0].content:''
      console.log(params, "canshu");
      this.btnLoading = true;
      this.btnDisabled = true;
      operation(params)
        .then(() => {
          this.$toast.success({
            type: "success",
            message: "修改成功",
            onClose: () => {
              this.btnLoading = false;
              this.btnDisabled = false;
              this.$router.go(-1);
            },
          });
        })
        .catch((err) => {
          this.btnLoading = false;
          this.btnDisabled = false;
        });
    },
    onConfirm(values) {
      this.form.userArea = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;
    },
    onConfirm1(values) {
      this.form.birthday = this.$dayjs(values).format("YYYY-MM-DD");
      this.showCalendar = false;
    },
     // 确认剪裁并上传图片
	    async getCropBlob() {
	      this.$toast('上传中', 0);
	      let formData = new FormData();
	      this.$refs.cropper.getCropBlob((data) => {
          console.log('裁剪',data);
	        // formData.append('avatar', data, this.imageFileName);
	        // // formData私有类对象，访问不到，可以通过get判断值是否传进去
	        // console.log(formData,formData.get('avatar'));
	        // 上传图片至服务器
	        // changeAvatar(formData)
	        // .then(res => {
	        //   if(res.code === 200){
	        //     this.$toast('更改头像成功');
	        //   } else {
	        //     this.$toast('上传失败');
	        //   }
	      //   })
	      //   .catch(err => console.error(err));
	       })
	    },
	
	    // 旋转图片
	    rotateImage() {
	      this.$refs.cropper.rotateRight();
	    },
	
	    // 取消截图上传头像
	    cancelCropper() {
	      this.showCropper = false; // 隐藏切图遮罩
	      this.showPopup = true;
	    },
  },
};
</script>

<style lang="scss" scoped>
.personal_data {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  /deep/ .van-radio__icon--checked .van-icon {
    background-color: $defaultColor !important;
    border-color: $defaultColor !important;
  }
  .form_wrap {
    width: 100%;
    flex: 1;
    padding: 0.05rem 0.15rem;
    .form {
      color: $lightBlack;
      /deep/ .van-field__label {
        color: $lightBlack;
        font-size: 14px;
      }
      /deep/ .van-uploader {
        // .van-uploader__file{
        //      width: 0.6rem;
        //   height: 0.6rem;
        //    margin:0;
        //   position: absolute;
        //   background: transparent;
        //   .van-uploader__upload-icon{
        //     display: none;
        //   }
        // }
        // .van-uploader__upload{
        //   width: 0.6rem;
        //   height: 0.6rem;
        //   margin:0;
        //   position: absolute;
        //   background: transparent;
        //   .van-uploader__upload-icon{
        //     display: none;
        //   }
        // }
        .van-uploader__preview-image {
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
        }
      }
      /deep/ .van-uploader__preview {
        margin: 0;
      }
      /deep/ .van-button--primary {
        width: 100%;
        margin: 0;
        border-radius: 0.1rem;
        background-color: $defaultColor;
        border: 1px solid $defaultColor;
        box-shadow: 0px 0px 10px $defaultColor;
      }
      .btn_box {
        margin-top: 0.4rem;
      }
      .avatar {
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
      }
      .avatar_box {
        padding: 0.05rem 0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        /deep/ .van-field__control--custom {
          justify-content: flex-end;
          .van-image {
            width: 0.6rem;
            height: 0.6rem;
            img {
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
//裁剪
.height100 {
	height: 100vh;
}
.flex-column-center{ 
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
}
.vue-cropper {
  	background: #000;
  	.cropper-view-box {
	  	outline: 1px solid #fff !important;
	  	outline-color: #fff !important;
 	}
}
.popup_bottom{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  .bottom_item{
    flex:1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

</style>
