<template>
    <div class="messageItem">
        <div class="item_top">
            <div class="top_left">
                <img class="avatar" v-if="item.avatar" :src="item.avatar" alt="">
                <img class="avatar" v-else :src="defaultAvatar" alt="">
                <span class="name">{{item.name}}</span>
            </div>
            <div class="top_right"><span>{{item.date}}</span></div>
        </div>
        <div class="item_bottom">
            <p>{{item.des}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                defaultAvatar:require('@/assets/imgs/avatar.png'),
            }
        },
        props:{
            item:{
                type:Object,
                default: {},
            }
        },
        methods:{
            
        }
    }
</script>

<style lang="scss" scoped>
.messageItem{
    padding:0.1rem 0;
    border-bottom: 1px solid #EDEDED;
    .item_top{
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
        -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
        margin-bottom: 0.06rem;
        .top_left{
            display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
            -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
            .avatar{
                width: 0.3rem;
                height:0.3rem;
                margin-right: 0.06rem;
            }
            .name{
                font-size: 15px;
                color: $lightBlack;
            }
        }
        .top_right{
            font-size: 12px;
            color: $lightBlack;
        }
    }
    .item_bottom{
        padding-right: 0.68rem;
        p{
            font-size: 14px;
            line-height: 0.2rem;
        }
    }
}
.messageItem:last-child{
    border-bottom: none;
}
</style>