<template>
  <div class="eulogyDetail wh100">
    <navBar :title="title" :leftArrowFlag='true'></navBar>
    <div class="content">
        <h3>{{detail.title}}</h3>
      <p v-html="detail.content"></p>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import {fetchEulogyDetail} from  '@/api/my/eulogyManagement'
export default {
    name:'eulogyDetail',
  data() {
    return {
      title: "悼文详情",
      detail: {
        title: "",
        content: ``,
      },
    };
  },
  components: {
    navBar,
  },
  created() {
   
    this.getEulogyDetail(this.$route.params.id)
  },
  methods: {
    async getEulogyDetail(id) {
        let params={
          id
        }
        let res= await fetchEulogyDetail(params)
        if(res.code!='2000'){
          this.$toast.fail(res.msg || "数据请求失败")
          return 
        }else{
          this.detail=res.data
           this.title = this.detail.title;
        }
    }
  },
};
</script>

<style lang="scss" scoped>
.eulogyDetail {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  .content {
    flex: 1;
    padding: 0.15rem;
    overflow-y: scroll;
    h3{
        font-size: 16px;
        line-height: 0.23rem;
        margin-bottom: 0.15rem;
        text-align: center;
    }
    p{
        text-indent: 32px;
        font-size: 14px;
        line-height: 0.2rem;
    }
  }
}
</style>