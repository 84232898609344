<template>
  <div class="orderDetail wh100">
    <navBar title="订单详情" :leftArrowFlag="true" />
    <div class="content">
      <div class="order_steps">
        <div class="steps_title">
          <span>预约信息状态查询</span>
        </div>
        <div class="steps_wrap">
          <van-steps :active="active" direction="vertical">
            <van-step v-for="(item, index) in detail.orderAbouts" :key="index">
              <h3>{{ item.type| formatType(detail.orderType)}}</h3>
              <p>{{ item.orderDate }}</p>
            </van-step>
          </van-steps>
        </div>
      </div>
      <div class="services">
        <div class="services_item">
          <div class="item_label">
            <span>逝者姓名</span>
          </div>
          <div class="item_info">
            <span>{{ detail.deadName }}</span>
          </div>
        </div>
        <div class="services_item">
          <div class="item_label">
            <span>预约日期</span>
          </div>
          <div class="item_info">
            <span>{{ detail.appointmentDate }}</span>
          </div>
        </div>
        <div class="services_item">
          <div class="item_label">
            <span>预约内容</span>
          </div>
          <div class="item_info">
            <div class="charge_item" v-for="(item, index) in detail.serviceDetail" :key="index">
              <span>{{ item.serviceName }}</span>￥
              <span>{{ item.servicePrice }}</span>
            </div>
          </div>
        </div>
        <div class="services_item total_item">
          <div class="item_label">
            <span>合计：</span>
          </div>
          <div class="item_info total">
            <span>￥{{ detail.price }}</span>
          </div>
        </div>
      </div>
      <div class="imgs" v-if="detail.url && detail.url.length > 0">
        <div class="imgs_title">
          <span>祭扫详情</span>
        </div>
        <div class="imgs_wrap">
          <van-image
            class="img"
            fit="cover"
            :src="item"
            @click="showPreview(index)"
            v-for="(item, index) in detail.url"
            :key="index"
          />
        </div>
      </div>
      <div
        v-if="
          detail.orderAbouts &&
            detail.orderAbouts.length > 0 &&
            detail.orderAbouts[0].type == 3
        "
        class="large_btn"
      >
        <span>去支付</span>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import { ImagePreview } from "vant";
import { getOrderDetail } from "@/api/appointment/appointmentQuery";
export default {
  data() {
    return {
      active: 0,
      detail: {}
    };
  },
  filters: {
    formatType(val, type) {
      if (val == 0) {
        return "用户取消";
      } else if (val == 1) {
        return "工作人员取消";
      } else if (val == 2) {
        return "待审核";
      } else if (val == 3) {
        return "审核通过";
      } else if (val == 4 && type == 0) {
        return "免费祭扫";
      } else if (val == 4 && type == 1) {
        return "支付成功";
      } else if (val == 5) {
        return "完成";
      }else if (val == 6) {
        return "待支付";
      }
    }
  },
  components: {
    navBar
  },
  created() {
    this.getDetail(this.$route.params.id);
  },
  methods: {
    async getDetail(id) {
      let params = {
        orderNo: id
      };
      let res = await getOrderDetail(params);
      console.log(res, "2333");
      if (res.code != "2000") {
        this.$toast.fail(res.msg || "数据请求失败");
        return;
      }
      let detail = JSON.parse(JSON.stringify(res.data));
      this.detail = detail;
    },
    showPreview(index) {
      ImagePreview({
        images: this.detail.url,
        startPosition: index
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.orderDetail {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  .content {
    flex: 1;
    padding: 0.15rem;
    overflow-y: scroll;
    .order_steps {
      border-bottom: 1px solid #ededed;
      .steps_title {
        margin-bottom: 0.15rem;
      }
      .steps_wrap {
        margin-bottom: 0.15rem;
        /deep/ .van-step__circle {
          border: 0.045rem solid #b5b5b5;
          width: 0.2rem;
          height: 0.2rem;
          background-color: #d1d1d1;
        }
        /deep/ .van-step--vertical .van-step__circle-container {
          font-size: 20px;
        }
      }
    }
    .services {
      padding: 0.2rem 0;
      .services_item {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        padding: 0.05rem 0;
        font-size: 14px;
        line-height: 0.2rem;
        .item_label {
          width: 0.6rem;
          margin-right: 0.6rem;
        }
        .item_info {
          flex: 1;
          color: $lightBlack;
        }
        .total {
          color: #e7643c;
        }
      }
      .total_item {
        border-top: 1px solid #ededed;
      }
    }
    .imgs {
      padding: 0.2rem 0;
      .imgs_title {
        font-size: 14px;
        line-height: 0.2rem;
        margin-bottom: 0.1rem;
      }
      .imgs_wrap {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        .img {
          width: 1.6rem;
          height: 1.1rem;
          margin: 0.05rem;
        }
      }
    }
    .large_btn {
      margin: 0.1rem 0;
    }
  }
}
</style>
