<template>
    <div class="my wh100">
        <navBar title="预约核销"></navBar>
        <div class="my_content">
            <van-button class="btn" type="primary" @click="wxScanCode">扫描二维码</van-button>
        </div>
    </div>
</template>
<script>
import wx from "weixin-jsapi";
import { wxSignatureVerification } from "@/api/wx";
import { writeOff } from "@/api/my";
import navBar from "@/components/navBar";
export default {
    components: {
        navBar
    },
    mounted() {
        // this.wxScanCode()
    },
    methods: {
        // 微信扫一扫
        wxScanCode() {
            let params = {
                url: window.location.href.split("#")[0],
            };
            //调后端接口返回所需要的参数
            wxSignatureVerification({ ...params }).then((res) => {
                console.log("rescode", res);
                // 后端返回的参数
                if (res.code != "2000") return this.$toast.fail("微信验签失败");
                let result = res.data.wxConfig;
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: res.data.appId, // 必填，公众号的唯一标识
                    timestamp: result.timestamp, // 必填，生成签名的时间戳
                    nonceStr: result.nonceStr, // 必填，生成签名的随机串
                    signature: result.signature, // 必填，签名
                    jsApiList: [
                        "checkJsApi",
                        "onMenuShareTimeline",
                        "onMenuShareAppMessage",
                        "onMenuShareQQ",
                        "onMenuShareWeibo",
                        "hideMenuItems",
                        "showMenuItems",
                        "hideAllNonBaseMenuItem",
                        "showAllNonBaseMenuItem",
                        "translateVoice",
                        "startRecord",
                        "stopRecord",
                        "onRecordEnd",
                        "playVoice",
                        "pauseVoice",
                        "stopVoice",
                        "uploadVoice",
                        "downloadVoice",
                        "chooseImage",
                        "previewImage",
                        "uploadImage",
                        "downloadImage",
                        "getNetworkType",
                        "openLocation",
                        "getLocation",
                        "hideOptionMenu",
                        "showOptionMenu",
                        "closeWindow",
                        "scanQRCode",
                        "chooseWXPay",
                        "openProductSpecificView",
                        "addCard",
                        "chooseCard",
                        "openCard",
                    ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                });
                wx.ready(() => {
                    wx.scanQRCode({
                        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                        success: async (res) => {
                            var result = window.atob(res.resultStr);
                            let params = {
                                appointmentCode: result,
                            };
                            let res1 = await writeOff(params);
                            if (res1.code != "2000") {
                                this.$toast.fail(res1.msg);
                                return;
                            }
                            this.$router.push({
                                path: `/appointment/appointmentInfo/${res1.data.userAppointmentInfoId}`,
                            });
                        },
                        error: (res) => {
                            //console.log(res);
                        },
                    });
                });
            });
        },
    }
}
</script>
<style lang="scss" scoped> 
    .my_content{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btn{
        background: #8a7651;
        border: 0;
        margin-top: 50px;
    }
</style>