<template>
  <div class="myFamily wh100">
    <navBar title="我的家人" :leftArrowFlag="true" />
    <div class="content">
        <div class="tree_box" v-drag>
          <TreeChart  :json="treeData" @click-node='clickNode' />
        </div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import TreeChart from "vue-tree-chart";
export default {
  data() {
    return {
       treeData: {
            name: "父亲",
                image_url: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
                mate: {
                name: "母亲",
                image_url: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
                },
                children: [
                {
                    name: "儿子",
                    image_url: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
                    mate: {
                        name: "儿媳",
                        image_url: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
                        },
                        children:[
                            {
                                name: "孙子",
                                image_url: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
                            },
                            {
                                name: "孙子",
                                image_url: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
                            },
                        ]
                },
                {
                    name: "兄弟",
                    image_url: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
                },
                {
                    name: "兄弟",
                    image_url: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
                },
            ],
        },
    };
  },
  components: {
    navBar,
     TreeChart,
  },
  created(){
   
  },
  methods: {
     clickNode(node) {
        console.log(node,'2222')
      },
   
  },
};
</script>

<style lang="scss" scoped>
.myFamily {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  .content {
    flex: 1;
    padding: 0.25rem 0.225rem;
    .tree_box{
       position: fixed;
  z-index: 0;
  left: 0;
  top:0.46rem;
    /deep/ .extend_handle{
        display: none;
    }
    /deep/ .node .person .avat{
        border: none;
    }
    /deep/ .extend:after{
        height: 35px;
    }
    /deep/ .node .person{
        cursor: pointer;
    }
    }
  
  }
}
</style>