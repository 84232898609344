import request from "@/utils/request";

//代祭扫信息录入

export function replaceSacrifice_add(data) {
  return request({
    url: "/sys/replaceSacrifice/add",
    method: "post",
    data,
  });
}

// 支付成功修改状态
export function wxpayUpdatePayInfo(data) {
  return request({
    url: "/app/wxpay/updatePayInfo",
    method: "post",
    data,
  });
}
