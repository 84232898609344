<template>
  <div class="personal_data wh100">
    <navBar title="基本资料" :leftArrowFlag="true" />
    <div class="form_wrap">
      <van-form class="form" @submit="onSubmit">
        <!-- <div class="avatar_box">
          <div class="box_left"><span>头像</span></div>
          <div class="box_right">
            <van-uploader>
              <img class="avatar" src="http://qp0bwqldc.hn-bkt.clouddn.com/avatar.jpg" alt="">
            </van-uploader>
          </div>
        </div> -->
        <div v-for="(item,index) in form.deadInfoDetails" :key="index">
           <van-field name="uploader" label="逝者照片" class="avatar_box">
            <template #input>
              <van-uploader max-count="1" :after-read='afterUpload' :deletable='false' :preview-full-image='false' :before-read="beforeUpload" v-model="form.deadInfoDetails[index].uploader" >
                <img v-if="item.deadPortraitUrl" class="avatar" :src="item.deadPortraitUrl" alt="">
                <img class="avatar" v-else :src="defaultAvatar" alt="">
              </van-uploader>
            </template>
          </van-field>
          <van-field
            v-model="form.deadInfoDetails[index].deadName"
            name="deadName"
            label="逝者姓名"
            placeholder="请输入逝者姓名"
            :rules="[{ required: true, message: '请输入逝者姓名' }]"
          />
        <van-field name="radio" label="选择性别" >
          <template #input>
            <van-radio-group v-model="form.deadInfoDetails[index].genders" direction="horizontal">
              <van-radio :name="0">男</van-radio>
              <van-radio :name="1">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field name="radio" label="时间类型">
          <template #input>
            <van-radio-group v-model="form.deadInfoDetails[index].calendar" direction="horizontal">
              <van-radio :name="1">阴历</van-radio>
              <van-radio :name="0">阳历</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
            readonly
            clickable
            :name="'birthday'+index"
            :value="form.deadInfoDetails[index].birthday"
            label="出生时间"
            right-icon="arrow-down"
            placeholder="点击选择日期"
            :rules="[{ required: true, message: '请选择出生日期' }]"
            @click="dateSelect('birthday',index)"
        />
        <van-field
            readonly
            clickable
            :name="'deathday'+index"
            :value="form.deadInfoDetails[index].deathday"
            label="逝世时间"
            right-icon="arrow-down"
            placeholder="点击选择日期"
            :rules="[{ required: true, message: '请选择逝世日期' }]"
           @click="dateSelect('deathday',index)"
        />
        
        </div>
        <van-field
          v-model="form.epitaph"
          rows="4"
          autosize
          label="墓志铭"
          type="textarea"
          maxlength="100"
          placeholder="请输入墓志铭"
          show-word-limit
         
        />
        <van-field
          v-model="form.biography"
          rows="4"
          autosize
          label="生平简介"
          type="textarea"
          maxlength="100"
          placeholder="请输入生平简介"
          show-word-limit
         
        />
        <div class="btn_box">
          <van-button :loading='btnLoading' :disabled='btnDisabled' round block type="primary" native-type="submit" 
            >确认</van-button
          >
        </div>
        <!-- <van-calendar   v-model="showCalendar1" @confirm="onConfirm1" />
        <van-calendar  v-model="showCalendar2" @confirm="onConfirm2" /> -->
      </van-form>
    </div>
    <van-popup v-model="showCalendar" position="bottom">
            <van-datetime-picker
              type="date"
              title="选择年月日"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="onConfirm"
              @cancel="showCalendar = false"
            />
          </van-popup>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import areaList from '@/utils/areaList'
import { memorialInfoDetail,memorialInfoOperation } from '@/api/memorialHall/manageCenter'
import { Toast } from 'vant';

export default {
  name: "personalData",
  data() {
    return {
       btnLoading:false,
      btnDisabled:false,
      id:'',
      current:'',
      defaultAvatar: require("../../../assets/imgs/avatar.png"),
      type:'',
      showCalendar:false,
      showCalendar1:false,
      showCalendar2:false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      form:{
        id:'',
        biography:'',
        epitaph:'',
        deadInfoDetails:[
          {
            uploader: [{}],
            deadName:'',
            calendar:1,
            birthday:'',
            deathday:'',
            genders:1,
            epitaph:'',
          }
        ]
      },
      showArea:false,
      areaList:areaList // 数据格式见 Area 组件文档
    };
  },
  components: {
    navBar,
  },
  created(){
    this.id=this.$route.params.id
    this.init()
  },
  methods: {
    dateSelect(type,current){
      this.showCalendar=true;
      this.type=type;
      this.current=current
    },
    init(){
      memorialInfoDetail({id:this.id}).then(res=>{
        console.log(res,'返回详情')
        this.form=res.data
      })
    },
    beforeUpload(file){
      // if (file.type !== 'image/jpeg') {
      //   this.$toast('请上传 jpg 格式图片');
      //   return false;
      // }
       return true;
    },
    afterUpload(file){
      console.log(file,'111')
      console.log(this.form)
    },
    onSubmit(){
      let { id,biography,epitaph,deadInfoDetails }=this.form
      let params={
        id,biography,epitaph
      }
      params.deadList=deadInfoDetails.map(el=>{
        return {
          ...el,
          deadPortraitUrl:el.uploader?el.uploader[0].content:el.deadPortraitUrl
        }
      })
      this.btnLoading=true
      this.btnDisabled=true
      memorialInfoOperation(params).then(()=>{
        // Toast.success('提交成功',()=>{
        //   this.init()
        // });
        Toast.success({
          type: 'success',
          message: '提交成功',
          onClose: () => {
            this.btnLoading=false
            this.btnDisabled=false
            this.init()
            //this.$router.go(-1)
          },
        })
        
      }).catch(err=>{
        this.btnLoading=false
            this.btnDisabled=false
      })
    },
    onConfirm(values) {
      
      const {type,current}=this
      
      this.form.deadInfoDetails[current][type] = this.$dayjs(values).format('YYYY-MM-DD');
      let birthday=this.$dayjs(this.form.deadInfoDetails[current].birthday).unix();
      let deathday=this.$dayjs(this.form.deadInfoDetails[current].deathday).unix();
      if(deathday<birthday){
        this.$toast.fail('出生日期应小于死亡日期');
        this.form.deadInfoDetails[current].deathday=''
        return 
      }
       this.showCalendar = false;
    },
    // onConfirm2(values) {
    //   this.form.deadInfoDetails[this.current].deathday = this.$dayjs(values).format('YYYY-MM-DD');
    //     this.showCalendar2 = false;
    // },
  },
};
</script>

<style lang="scss" scoped>
.personal_data {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  .form_wrap {
    width: 100%;
    flex: 1;
    padding: 0.05rem 0.15rem;
    overflow-y: scroll;
    .form {
      color: $lightBlack;
      /deep/ .van-radio__icon--checked .van-icon{
    background-color: $defaultColor;
  border-color: $defaultColor;
}
    /deep/ .van-button--primary{
    width: 100%;
    margin:0;
    border-radius: 0.1rem;
    background-color: $defaultColor;
    border: 1px solid $defaultColor;
    box-shadow:0px 0px 10px $defaultColor;
}
      // /deep/.van-field__error-message{
      //     display: none;
      // }
      /deep/ .van-calendar__selected-day{
          background-color: $defaultColor;
      }
      /deep/ .van-button--danger{
          background-color: $defaultColor;
              border: 1px solid $defaultColor;
      }
     
      /deep/ .van-field__label {
        color: $lightBlack;
        font-size: 14px;
      }
      /deep/ .van-uploader{
        .van-uploader__file{
             width: 0.6rem;
          height: 0.6rem;
           margin:0;
          position: absolute;
          background: transparent;
          .van-uploader__upload-icon{
            display: none;
          }
        }
        .van-uploader__upload{
          width: 0.6rem;
          height: 0.6rem;
          margin:0;
          position: absolute;
          background: transparent;
          .van-uploader__upload-icon{
            display: none;
          }
        }
      }
      /deep/ .van-uploader__preview{
        margin:0;
      }
      
      .btn_box{
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
      }
      .avatar {
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
      }
      .avatar_box {
        padding: 0.05rem 0;
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
        -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
        /deep/ .van-field__control--custom {
          justify-content: flex-end;
          .van-image {
            width: 0.6rem;
            height: 0.6rem;
            img {
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
</style>