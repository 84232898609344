// 用户预约扫墓
import request from "@/utils/request";

//保存预约信息 返回预约码
export function appointmentSacrifice_operation(data) {
  return request({
    url: "/sys/appointmentSacrifice/operation",
    method: "post",
    data,
  });
}
