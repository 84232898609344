<template>
  <div class="blessingTree wh100">
    <navBar
      title="祈福树"
      :leftArrowFlag="true"
      rightText="介绍"
      @clickNavBarRight="onClickRight"
    />
    <div class="content">
      <div class="content_main">
        <div class="bless_list">
          <div
            class="bless_item"
            v-for="(item, index) in blesss"
            :key="index"
            @click="showItemDetail(item)"
          >
            <img class="item_little_img" :src="item.little" alt="" />
          </div>
        </div>
      </div>
      <div class="content_bottom">
        <div class="bottom_btn my_bless" @click="goMyBless">
          <span>我的祈福</span>
        </div>
        <div class="bottom_btn go_bless" @click="goBless">
          <span>去祈福</span>
        </div>
      </div>
    </div>
    <van-overlay class="overlay" :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="box">
          <div class="box_title"><span>介绍</span></div>
          <div class="box_content">
            <h4>1.点击许愿牌可以看到大家的愿望</h4>
            <h4>2.点击左下角我的祈福可以查看自己的 祈福内容</h4>
            <h4>
              3.点击右下角去祈福，可以发布祈福内容
              发布内容不可修改，要认真对待每一次 祈福
            </h4>
          </div>
        </div>
        <van-icon @click="show = false" class="close_btn" name="close" />
      </div>
    </van-overlay>
    <van-overlay class="cover" :show="showOver" @click="showOver = false">
      <div class="wrapper" @click.stop>
        <div class="anmiate_box" v-show="showOver">
          <!-- <transition
            enter-active-class="animate__animated animate__zoomIn"
            leave-active-class="animate__animated animate__zoomOut"
            :duration="{ enter: 300, leave: 800 }"
          >
            <div class="big_img" v-show="showOver">
              <img class="bigImg" :src="selectItem.big" alt="" />
            </div>
          </transition> -->
          <div class="big_img" v-show="bigImg">
            <img class="bigImg" :src="selectItem.big" alt="" />
          </div>
          <div class="cover_box" v-show="coverBox" @click="closeCoverBox">
            <div
              class="cover_img_box"
              :style="{ 'background-image': 'url(' + selectItem.cover + ')' }"
            >
              <div class="cover_info">
                <p>{{ selectItem.des }}</p>
              </div>
              <div class="name_box">
                <div class="dot"></div>
                <span>{{ selectItem.name }}</span>
                <div class="dot"></div>
              </div>
            </div>
            <div class="collect_box" @click.stop="changeCollected">
              <van-icon v-if="selectItem.isCollected" name="like-o" />
              <van-icon v-else name="like" />
              <span>{{ selectItem.collectNums }}人祝福</span>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- <div class="overlaybox" v-show="showOver" @click="showOver = false"></div> -->
  </div>
</template>

<script>
import animateCSS from "@/utils/animateCSS";
import navBar from "@/components/navBar";
export default {
  data() {
    return {
      show: false,
      showOver: false,
      bigImg: true,
      coverBox: false,
      selectItem: {},
      blesss: [
        {
          id: 1,
          little: require("../../../../assets/imgs/bless/aiqing.png"),
          cover: require("../../../../assets/imgs/bless/aiqingcover.png"),
          big: require("../../../../assets/imgs/bless/aiqing_.png"),
          des: "爱情啊，我爱了",
          collectNums: 7,
          isCollected: false,
          name: "罗杰",
          title: "爱情",
        },
        {
          id: 2,
          little: require("../../../../assets/imgs/bless/caiyun.png"),
          cover: require("../../../../assets/imgs/bless/caiyuncover.png"),
          big: require("../../../../assets/imgs/bless/caiyun_.png"),
          des: "希望工作一切顺利",
          collectNums: 5,
          isCollected: false,
          name: "李博儿",
          title: "财运",
        },
        {
          id: 3,
          little: require("../../../../assets/imgs/bless/jiankang.png"),
          cover: require("../../../../assets/imgs/bless/jiankangcover.png"),
          big: require("../../../../assets/imgs/bless/jiankang_.png"),
          des: "健康永久，安详快乐",
          collectNums: 3,
          isCollected: false,
          name: "我",
          title: "健康",
        },
        {
          id: 4,
          little: require("../../../../assets/imgs/bless/pingan.png"),
          cover: require("../../../../assets/imgs/bless/pingancover.png"),
          big: require("../../../../assets/imgs/bless/pingan_.png"),
          des:
            "㊗️愿天下的父母身体安康㊗️朋友们一切顺利㊗️孩子学业有成㊗️有情人终成眷属🙏🙏🙏",
          collectNums: 7,
          isCollected: false,
          name: "苏娜",
          title: "平安",
        },
        {
          id: 5,
          little: require("../../../../assets/imgs/bless/shiye.png"),
          cover: require("../../../../assets/imgs/bless/shiyecover.png"),
          big: require("../../../../assets/imgs/bless/shiye_.png"),
          des: "祝一家人平安快乐！",
          collectNums: 3,
          isCollected: false,
          name: "卡斯",
          title: "事业",
        },
        {
          id: 6,
          little: require("../../../../assets/imgs/bless/xueye.png"),
          cover: require("../../../../assets/imgs/bless/xueyecover.png"),
          big: require("../../../../assets/imgs/bless/xueye_.png"),
          des: "学业有成",
          collectNums: 7,
          isCollected: false,
          name: "路飞",
          title: "学业",
        },
      ],
    };
  },
  components: {
    navBar,
  },
  created() {},
  methods: {
    closeCoverBox() {
      this.coverBox = false;
      this.showOver = false;
      this.bigImg = true;
    },
    changeCollected() {
      this.selectItem.isCollected = !this.selectItem.isCollected;
    },
    showItemDetail(item) {
      this.showOver = true;
      this.selectItem = item;
      animateCSS(".big_img", "zoomIn").then((message) => {
        // Do something after the animation
        //console.log('动画结束')
        this.bigImg = false; //
        this.coverBox = true;
        animateCSS(".cover_box", "flipInY").then((message) => {});
      });
    },
    onClickRight() {
      this.show = true;
    },
    goMyBless() {
      this.$router.push("/my/myBlessing");
    },
    goBless() {
      this.$router.push("/my/blessingCard");
    },
  },
};
</script>

<style lang="scss" scoped>
.blessingTree {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  background-color: #eff2f7;
  // background: url("../../../../assets/imgs/tree.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  .overlaybox {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    z-index: 2;
  }
  .anmiate_box {
    position: absolute;
    width: 100%;
    // left: 0;
    // top: 0;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
    z-index: 3;
    .big_img {
      width: 2.7rem;
      height: 3.7rem;
      position: absolute;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .cover_box {
      width: 100%;
      height: 100%;
      padding-top: 1rem;
      .cover_img_box {
        width: 100%;
        height: 3.7rem;
        position: relative;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: 80%;
        .cover_info {
          position: absolute;
          width: 1.85rem;
          height: 2.97rem;
          top: 0.72rem;
          left: 0.82rem;
          text-align: left;
          padding: 0.1rem;
          text-align: justify;
          font-size: 14px;
          line-height: 0.2rem;
        }
        .name_box {
          position: absolute;
          bottom: 0.1rem;
          right: 1.2rem;
          font-size: 12px;
          padding: 0.05rem 0.1rem;
          color: #fff;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
          border-radius: 12px;
          background-color: #48c479;
          span {
            margin: 0 0.03rem;
          }
          .dot {
            width: 0.04rem;
            height: 0.04rem;
            background: #fff;
            border-radius: 50%;
          }
        }
      }
      .collect_box {
        display: flex;
        font-size: 14px;
        box-sizing: border-box;
        background-color: #fff;
        padding: 0.1rem 0.2rem;
        position: absolute;
        bottom: 1.5rem;
        left: 50%;
        -webkit-transform: translateX(-60%);
        transform: translateX(-60%);
        border-radius: 155px;
        -webkit-box-align: center;
        -webkit--moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        /deep/ .van-icon {
          color: crimson;
          font-size: 20px;
          margin-right: 0.07rem;
        }
      }
    }
  }

  .content {
    flex: 1;
    padding: 0.25rem 0.15rem;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    .content_main {
      flex: 1;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-flex-direction: column;
      -webkit-flex-direction: column;
      flex-direction: column;
      -moz-justify-content: center;
      -webkit-justify-content: center;
      justify-content: center;
      .bless_list {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .bless_item {
          width: 0.65rem;
          height: 1.07rem;
          margin: 0.3rem 0.15rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .bless_item:nth-child(2n) {
          margin-top: 0.15rem;
        }
      }
    }
    .content_bottom {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-align-items: center;
      -webkit-align-items: center;
      align-items: center;
      -moz-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      .bottom_btn {
        margin: 0 0.1rem;
        flex: 1;
        height: 0.44rem;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        font-size: 14px;
        color: #fff;
        border-radius: 0.08rem;
      }
      .my_bless {
        background-color: #efb835;
      }
      .go_bless {
        background-color: #07bf67;
      }
    }
  }
  .overlay {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
    text-align: center;
    .box {
      width: 3rem;
      .box_title {
        width: 100%;
        height: 0.5rem;
        background: $defaultColor;
        text-align: center;
        line-height: 0.5rem;
        color: #fff;
        font-size: 16px;
      }
      .box_content {
        width: 100%;
        height: 2rem;
        text-align: left;
        background: #fff;
        padding: 0.15rem 0.23rem 0;
        h4 {
          font-size: 14px;
          line-height: 0.25rem;
        }
      }
    }
    .close_btn {
      color: #fff;
      font-size: 40px;
      margin: 0.15rem auto 0;
    }
  }
  .cover {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
    text-align: center;
    .wrapper {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
