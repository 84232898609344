<template>
  <div class="appointmentDetail wh100">
    <navBar title="预约详情" :leftArrowFlag="true" />
    <div class="content">
      <h2 :class="[detail.asUse == 0 ? 'success' : '']">
        {{ detail.asUse | formatStatus }}
      </h2>
      <p>
        请截图保存此预约凭证，并与现场出示进行身份验证预约信息已同步发送到您的手机，请妥善保存。如有疑问请致电：{{
          phone
        }}
      </p>
      <div id="qrcode"></div>
      <!-- <div class="code" :class="[detail.asUse != 0 ? 'disabled' : '']">
        <span>预约码:{{ detail.appointmentCode }}</span>
      </div> -->
      <div class="large_btn" @click="goDetail"><span>查看预约信息</span></div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import navBar from "@/components/navBar";
import { getAppointmentDetail } from "@/api/appointment/appointmentQuery";
export default {
  data() {
    return {
      phone: "",
      detail: {},
    };
  },
  components: {
    navBar,
  },
  filters: {
    formatStatus(val) {
      switch (val) {
        case 0:
          return "未使用";
          break;
        case 1:
          return "已使用";
          break;
        case 2:
          return "已过期";
          break;
        default:
          return "";
      }
    },
  },
  created() {
    this.getDetail(this.$route.params.id);
    this.phone = JSON.parse(localStorage.getItem("adminconfig")).servicePhone;
  },
  mounted() {},
  methods: {
    async getDetail(id) {
      let params = {
        id,
      };
      let res = await getAppointmentDetail(params);
      console.log(res, "详情");
      if (res.code != "2000") {
        this.$toast.fail(res.msg || "数据请求失败");
        return;
      }
      this.detail = res.data;
      this.$nextTick(function() {
        document.getElementById("qrcode").innerHTML = "";
        this.qrcode = new QRCode("qrcode", {
          width: 124,
          height: 124, // 高度
          text: window.btoa(res.data.appointmentCode), // 二维码内容
          // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
          // background: '#f0f',   // 背景色
          // foreground: '#ff0'    // 前景色
        });
      });
    },
    goDetail(){
      this.$router.push(`/appointment/appointmentInfo/${this.$route.params.id}`)
    }
  },
};
</script>

<style lang="scss" scoped>
.appointmentDetail {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  .content {
    padding: 0.2rem 0.35rem;
    h2 {
      text-align: center;
      font-size: 25px;
      line-height: 0.35rem;
      color: $lightBlack;
      font-weight: 600;
      margin-bottom: 0.15rem;
    }
    h2.success {
      color: $defaultColor;
    }
    p {
      font-size: 14px;
      line-height: 0.24rem;
      margin-bottom: 0.5rem;
    }
    #qrcode {
      // margin: 0.3rem 0 0.3rem;
      margin: 0.3rem auto;
      width: 1.24rem;
      height: 1.24rem;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-justify-content: center;
      -webkit-justify-content: center;
      justify-content: center;
      -moz-align-items: center;
      -webkit-align-items: center;
      align-items: center;
    }
    .code {
      // width: 1.35rem;
      // height: 0.44rem;
      width: 2.6rem;
      padding: 0.12rem 0.2rem;
      border: 1px solid $defaultColor;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      border-radius: 0.05rem;
      -moz-justify-content: center;
      -webkit-justify-content: center;
      justify-content: center;
      -moz-align-items: center;
      -webkit-align-items: center;
      align-items: center;
      color: $defaultColor;
      margin: 0 auto;
    }
    .disabled {
      border: 1px solid #ebebeb;
      background: #ebebeb;
      color: $lightBlack;
    }
    .large_btn{
      margin-top: 0.5rem;
    }
  }
}
</style>
