import request from "@/utils/request";

//纪念馆设置展示
export function memorialInfo_RelativesFriends(data) {
  return request({
    url: "/app/memorialInfo/RelativesFriends",
    method: "post",
    data,
  });
}
export function memorialInfo_relevancyState(data) {
  return request({
    url: "/app/memorialInfo/relevancyState",
    method: "post",
    data,
  });
}

