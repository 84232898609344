<template>
  <div class="examineList wh100">
    <navBar
      title="预约列表"
      :leftArrowFlag="true"
    />
    <div class="list_content">
      <vantList
        :list="list"
        :loading="loading"
        :finished="finished"
        :refreshing="refreshing"
        @getList="getList"
        @refresh="refresh"
      >
        <div class="list" v-for="(item, index) in list" :key="index">
        <list-item :item="item" :flag="flag" @load="getList"></list-item>
        </div>
      </vantList>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import vantList from "@/components/list";
import ListItem from  './components/listItem.vue'
import { getList } from '@/api/my/examineList'
export default {
  data() {
    return {
      flag: false,
      loading: false,
      finished: false,
      refreshing: false,
      pageSize: 4,
      currentPage: 1,
      selectResult: [],
      list: []
    };
  },
  components: {
    navBar,
    vantList,
    ListItem
  },
  created(){
    this.getList()
  },
  filters: {
    formatStatus(val, type) {
      if (val == 0) {
        return "用户取消";
      } else if (val == 1) {
        return "工作人员取消";
      } else if (val == 2) {
        return "待审核";
      } else if (val == 3) {
        return "审核成功,待支付";
      } else if (val == 4 && type == 0) {
        return "免费祭扫";
      } else if (val == 4 && type == 1) {
        return "支付成功";
      } else if (val == 5) {
        return "完成";
      }
    }
  },
  methods: {
      //请求数据
    async getList() {
      this.loading = true;
      const { currentPage, pageSize } = this;
      let params = {
        currentPage,
        pageSize,
        contentType:'dw'
      };
      let res = await getList(params);
      console.log(res,'8888')
      this.loading = false;
      this.refreshing = false;
      if (res.code === "2000") {
        let list = res.data || [];
        //根据总条数处理
       
        this.list = [...this.list, ...list];
        this.currentPage++;
        console.log(this.list.length, res.total, this.list.length >= res.total);
        if (this.list.length >= res.total) {
          this.finished = true;
          this.loading = false;
        }
      }

    },
    //下拉刷新
    refresh() {
      this.refreshing = true;
      // 清空列表数据
      this.currentPage = 1;
      this.list = [];
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
  }
};
</script>

<style lang="scss" scoped>
.examineList{
  display: flex;
  flex-direction: column;
  .list_content{
    height: calc(100% - 46px);
    .van-pull-refresh{
      height: 100%;
      overflow-y:scroll
    }
  }
}
</style>
