<template>
  <div class="appointmentInfo wh100">
    <navBar title="预约信息" :leftArrowFlag="true" />
    <div class="content">
      <van-form class="form" disabled>
        <van-field
          v-model.trim="form.appointmentName"
          name="appointmentName"
          label="预约人姓名"
          placeholder="预约人姓名"
        />
        <van-field
          v-model.trim="form.appointmentPhone"
          name="appointmentPhone"
          label="预约人手机号"
          placeholder="预约人手机号"
        />
        <van-field
          v-model.trim="form.appointmentCardNo"
          name="appointmentCardNo"
          label="预约人身份证号"
          placeholder="预约人身份证号"
        />
        <van-field
          v-model.trim="form.appointmentDate"
          name="appointmentDate"
          label="预约日期"
          disabled
          placeholder="预约日期"
        />
        <van-field
          v-model.trim="form.appointmentTime"
          name="appointmentTime"
          label="预约时间"
          disabled
          placeholder="预约时间"
        />
        <van-field
          v-model.trim="form.accompanyingNumber"
          label="随行人数"
          disabled
          placeholder="随行人数"
        />
        <div class="accompanyingList">
          <div
            class="accompanying_item"
            v-for="(item, index) in form.graveFriendResList"
            :key="index"
          >
            <div class="item_title">
              第<span>{{ index + 1 }}</span
              >位随行人信息
            </div>
            <van-field
              v-model.trim="form.graveFriendResList[index].friendName"
              label="随行人姓名"
              placeholder="随行人姓名"
            />
            <van-field
              v-model.trim="form.graveFriendResList[index].friendPhone"
              label="随行人手机号"
              placeholder="随行人手机号"
            />
            <van-field
              v-model.trim="form.graveFriendResList[index].friendCard"
              label="随行人身份证号"
              placeholder="随行人身份证号"
            />
          </div>
        </div>
      </van-form>
      <div class="large_btn" @click="goMy">
        <span>完成</span>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import { fetchAppointmentInfo } from "@/api/appointment/appointmentSacrificeSweep";
export default {
  data() {
    return {
      form: {
        appointmentName: "",
        appointmentPhone: "",
        appointmentCardNo: "",
        appointmentDate: "",
        appointmentTime: "",
        accompanyingNumber: 1,
        graveFriendResList: [],
      },
    };
  },
  components: {
    navBar,
  },
  created() {
    this.getDetail(this.$route.params.id);
  },
  mounted() {},
  methods: {
    async getDetail(id) {
      let params = {
        userAppointmentInfoId: id,
      };
      let res = await fetchAppointmentInfo(params);
      if (res.code != "2000") return this.$toast.fail("请求失败");
      this.form.appointmentTime = `${res.data.appointmentInfo.appointmentAm}-${res.data.appointmentInfo.appointmentPm}`;
      this.form.appointmentName = res.data.appointmentName;
      this.form.appointmentPhone = res.data.appointmentPhone;
      this.form.appointmentCardNo = res.data.appointmentCardNo;
      this.form.appointmentDate = res.data.appointmentDate;
      this.form.accompanyingNumber = res.data.graveFriendResList.length;
      this.form.graveFriendResList = res.data.graveFriendResList;
    },
    goMy() {
      //  this.$router.replace('/my')
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentInfo {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  .content {
    flex: 1;
    overflow-y: scroll;
    padding: 0.125rem;
    .large_btn {
      margin-top: 0.4rem;
    }
    .form {
      background: #f9f9f9;
      // /deep/ .van-field__error-message {
      //   display: none;
      // }

      /deep/ .van-field__label {
        width: 1rem;
      }
      /deep/ .van-cell {
        padding: 0.1rem 0.12rem !important;
      }
      .accompanyingList {
        .accompanying_item {
          background: #fff;
          margin-top: 0.1rem;
          padding: 0.2rem 0;
          .item_title {
            color: $defaultColor;
            font-size: 12px;
            padding: 0 0.12rem;
            line-height: 0.17rem;
          }
        }
      }
    }
  }
}
</style>
