<template>
  <div class="recallingSpace wh100">
    <navBar title="追忆空间" :leftArrowFlag="true" @clickNavBarRight="share">
      <i class="iconfont icon-fenxiang1"></i>
    </navBar>
    <div class="content">
      <div class="content_top">
        <div class="avatar_box">
          <img class="avatar" :src="detail.avatar" alt="" />
        </div>
        <h3>{{ detail.name }}</h3>
        <h4>馆号:{{ detail.hallId }}</h4>
      </div>
      <div class="content_main">
        <div class="numbers">
          <div class="numbers_item">亲友数：{{ detail.familyNum }}</div>
          <div class="numbers_item">关注数：{{ detail.followNum }}</div>
        </div>
        <!-- 亲友团 -->
        <div class="family">
          <div class="title">
            <div class="title_left"><span>王二牛纪念馆亲友团</span></div>
            <div class="title_right">
              <span>查看更多</span>
              <van-icon name="arrow" />
            </div>
          </div>
          <div class="item_content">
            <div class="family_content">
              <div class="avatar_box">
                <img :src="detail.avatar" alt="" />
              </div>
              <div class="family_right">
                <h3>当前已有28人加入逝者亲友团</h3>
                <div class="add_btn"><span>我也要加入</span></div>
              </div>
            </div>
          </div>
        </div>
        <!-- 个人简介 -->
        <div class="person">
          <div class="title">
            <div class="title_left"><span>人物简介</span></div>
            <div class="title_right">
              <span>查看更多</span>
              <van-icon name="arrow" />
            </div>
          </div>
          <div class="item_content">
            <div class="person_content">
              <div class="person_item">
                <div class="person_item_lable">姓名：</div>
                <div class="person_item_des">{{ detail.name }}</div>
              </div>
              <div class="person_item">
                <div class="person_item_lable">性别：</div>
                <div class="person_item_des">{{ detail.sex }}</div>
              </div>
              <div class="person_item">
                <div class="person_item_lable">出生日期：</div>
                <div class="person_item_des">{{ detail.birthday }}</div>
              </div>
              <div class="person_item">
                <div class="person_item_lable">死亡日期：</div>
                <div class="person_item_des">{{ detail.deadDate }}</div>
              </div>
              <div class="person_item">
                <div class="person_item_lable">个人简介：</div>
                <div class="person_item_des">{{ detail.personalProfile }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 相册 -->
        <div class="photo">
          <div class="title">
            <div class="title_left"><span>纪念相册</span></div>
            <div class="title_right">
              <span>查看更多</span>
              <van-icon name="arrow" />
            </div>
          </div>
          <div class="item_content">
            <div class="photo_content">
                <van-uploader v-model="detail.photos" multiple />
            </div>
          </div>
        </div>
        <!-- 留言 -->
        <div class="messages">
          <div class="title">
            <div class="title_left"><span>哀思留言</span></div>
            <div class="title_right">
              <span>查看更多</span>
              <van-icon name="arrow" />
            </div>
          </div>
          <div class="item_content">
            <div class="messages_content">
               <messageItem v-for="(item,index)  in detail.messages" :key='index' :item='item'></messageItem>
            </div>
          </div>
        </div>
        <!-- 悼文 -->
        <div class="eulogys">
          <div class="title">
            <div class="title_left"><span>追忆悼文</span></div>
            <div class="title_right">
              <span>查看更多</span>
              <van-icon name="arrow" />
            </div>
          </div>
          <div class="item_content">
            <div class="eulogys_content">
               <eulogysItem v-for="(item,index)  in detail.eulogys" :key='index' :item='item'></eulogysItem>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <van-field
        v-model="message"
        center
        clearable
        placeholder="留言寄哀思..."
      />
      <div class="sub_btn">留言</div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import messageItem from "../components/messageItem";
import eulogysItem from "../components/eulogysItem";
export default {
  data() {
    return {
      message: "",
      detail: {
        id: 1,
        avatar: "http://qp0bwqldc.hn-bkt.clouddn.com/avatar.jpg",
        name: "开心就好",
        hallId: 195528,
        familyNum: 28,
        followNum: 20,
        sex: "男",
        birthday: "1960-01-01",
        deadDate: "2020-01-01",
        personalProfile:"开心就好生于一九六零年一月一日，逝世日期二零二一年一月一日，山东济南人",
        photos:[
            {
               url:'http://qnhcpekfn.hb-bkt.clouddn.com/1.jpg'
            },
             {
               url:'http://qnhcpekfn.hb-bkt.clouddn.com/2.jpg'
            }
        ],
        messages:[
            {id:1,avatar:'http://qp0bwqldc.hn-bkt.clouddn.com/avatar.jpg',name:'开心就好',date:'刚刚',des:'您降生在非同寻常,您降生在非同寻常,您降生在非同寻常,您降生在非同寻常,您降生...'},
            {id:2,avatar:'http://qp0bwqldc.hn-bkt.clouddn.com/avatar.jpg',name:'开心就好',date:'2021-02-02',des:'您降生在非同寻常,您降生在非同寻常,您降生在非同寻常,您降生在非同寻常,您降生...'}
        ],
        eulogys:[
             {id:1,avatar:'http://qp0bwqldc.hn-bkt.clouddn.com/avatar.jpg',name:'开心就好',date:'刚刚',des:'您降生在非同寻常,您降生在非同寻常,您降生在非同寻常,您降生在非同寻常,您降生...'},
        ]
      },
    };
  },
  components: {
    navBar,
    messageItem,
    eulogysItem
  },
  created() {
    console.log(this.$route.params.id);
    this.getDetail(this.$route.params.id);
  },
  methods: {
    getDetail(id) {},
    share() {
      console.log("分享");
    },
  },
};
</script>

<style lang="scss" scoped>
.recallingSpace {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  .content {
    flex: 1;
    overflow-y: scroll;
    background: #fbfbfb;
    .content_top {
      width: 100%;
      height: 1.4rem;
      background: url("../../../assets/imgs/recallingSpacebg.png") no-repeat;
      background-size: 100% 100%;
      display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
      -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
      -moz-justify-content: center;
-webkit-justify-content: center;
justify-content: center;
      -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
      .avatar_box {
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        // background: rgba(255,255,255,0.4);
        background: #e7ebe5;
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
        -moz-justify-content: center;
-webkit-justify-content: center;
justify-content: center;
        margin-bottom: 0.1rem;
        .avatar {
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
        }
      }
      h3 {
        font-size: 15px;
        line-height: 0.21rem;
        margin-bottom: 0.05rem;
      }
      h4 {
        font-size: 12px;
        line-height: 0.17rem;
      }
    }
    .content_main {
      padding: 0 0.12rem;
      .title {
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
        border-bottom: 1px solid #ededed;
        height: 0.35rem;
        -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
        padding: 0 0.1rem;
        font-size: 14px;
        .title_left {
          font-weight: 600;
        }
        .title_right {
          color: $lightBlack;
          display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
          -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
        }
      }
      .item_content {
        padding: 0.1rem;
      }
      .numbers {
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        width: 100%;
        height: 0.5rem;
        margin-bottom: 0.1rem;
        -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
        background: #fff;
        font-size: 15px;
        .numbers_item {
          flex: 1;
          text-align: center;
        }
      }
      .family {
        width: 100%;
        height: 1.21rem;
        background: #fff;
        border-radius: 0.05rem;
        margin-bottom: 0.1rem;
        .family_content {
          display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
          .avatar_box {
            width: 0.7rem;
            height: 0.7rem;
            margin-right: 0.11rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .family_right {
            flex: 1;
            h3 {
              font-size: 14px;
              line-height: 0.2rem;
              margin-bottom: 0.1rem;
            }
            .add_btn {
              width: 1.81rem;
              height: 0.3rem;
              color: #fff;
              display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
              -moz-justify-content: center;
-webkit-justify-content: center;
justify-content: center;
              -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
              background: $defaultColor;
              border-radius: 0.08rem;
            }
          }
        }
      }
      .person {
        background: #fff;
        border-radius: 0.05rem;
        margin-bottom: 0.1rem;
        .person_content {
          .person_item {
            display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
            font-size: 14px;
            line-height: 0.2rem;
            margin-bottom: 0.1rem;
            .person_item_lable {
              width: 0.7rem;
              color: $lightBlack;
            }
            .person_item_des {
              flex: 1;
            }
          }
        }
      }
      .photo {
        background: #fff;
        border-radius: 0.05rem;
        margin-bottom: 0.1rem;
      }
      .messages{
        background: #fff;
        border-radius: 0.05rem;
        margin-bottom: 0.1rem;
      }
      .eulogys{
         background: #fff;
        border-radius: 0.05rem;
        margin-bottom: 0.1rem; 
      }
    }
  }
  .footer {
    width: 100%;
    height: 0.45rem;
    background: #d0d0d0;
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    padding: 0.04rem 0.12rem;
    /deep/ .van-cell {
      padding: 0.1rem !important;
      border-radius: 0.05rem;
    }
    .sub_btn {
      width: 0.6rem;
      height: 0.37rem;
      margin-left: 0.14rem;
      background: $defaultColor;
      color: #fff;
      border-radius: 0.05rem;
      text-align: center;
      line-height: 0.37rem;
    }
  }
}
</style>