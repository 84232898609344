<template>
  <div class="orderList wh100">
    <navBar
      title="订单列表"
      :leftArrowFlag="true"
      @clickNavBarRight="goAppointment">
    <i class="iconfont icon-yuyuezhongxin"></i>
    </navBar>
    <div class="list_content">
      <vantList
        v-if="list && list.length > 0"
        :list="list"
        :loading="loading"
        :finished="finished"
        :refreshing="refreshing"
        @getList="getList"
        @refresh="refresh"
      >
        <div class="list" v-for="(item, index) in list" :key="index">
          <orderItem :item="item" @del="del($event)"></orderItem>
        </div>
      </vantList>
      <van-empty v-else description="暂无数据" />
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import vantList from "@/components/list";
import orderItem from "./components/orderItem";
import {
  getAppointmentList,
  orderSacrificeManage_operation,
} from "@/api/appointment/appointmentQuery";
export default {
  data() {
    return {
      //订单状态0用户取消;1工作人员取消2:带审核3工作人员通过,4支付成功5完成
      loading: false,
      finished: false,
      refreshing: false,
      pageSize: 10,
      currentPage: 1,
      list: [],
    };
  },
  components: {
    navBar,
    vantList,
    orderItem,
  },
  created() {
    this.getList();
  },
  methods: {
    goAppointmentHistory() {
      sessionStorage.setItem("queryActive", 1);
      this.$router.push("/appointment/appointmentQuery");
    },
    //请求数据
    async getList() {
      this.loading = true;
      const { currentPage, pageSize } = this;
      let params = {
        currentPage,
        pageSize,
        type: "actingSacrifice",
      };
      let res = await getAppointmentList(params);
      console.log(res, "22222列表数据");
      
      if (res.code === "2000") {
        let list = res.data || [];
        this.list = [...this.list, ...list];
        //根据总条数处理
        if (pageSize > list.length) {
          this.finished = true;
        } else {
          this.currentPage++;
          this.finished = false;
        }
      }
      this.loading = false;
      this.refreshing = false;
    },
    getListReload(){
      this.list = []
      this.pageSize = 10
      this.currentPage = 1
      this.getList()
    },
    async del({ id, version }) {
      let postData = { id, version: version, sweepOrderState: 0 };
      console.log(postData, "postData");
      try {
        const res = await orderSacrificeManage_operation(postData);
        console.log(res, 200);
        if (res.code === "2000") {
          this.$toast.success("订单已取消");

          this.refresh();
          return;
        }
        this.$toast.fail(res.msg || "订单取消失败");
      } catch (err) {
        console.log(err, 404);
        this.$toast.fail(err.msg);
      }
    },
    //下拉刷新
    refresh() {
      this.refreshing = true;
      // 清空列表数据
      this.currentPage = 1;
      this.list = [];
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
    //返回预约中心
    goAppointment() {
      this.$router.push("/appointment");
    },
  },
};
</script>

<style lang="scss" scoped>
.orderList {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  .list_content {
    flex: 1;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 0.125rem 0;
    /deep/ .van-list {
      width: 100%;
    }
  }
}
</style>
