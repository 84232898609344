<template>
  <div class="i_follow wh100">
    <navBar title="我关注的纪念馆" :leftArrowFlag="true" />
    <div class="list_content">
      <vantList
        :list="list"
        :loading="loading"
        :finished="finished"
        :refreshing="refreshing"
        @getList="getList"
        @refresh="refresh"
      >
        <div class="list" v-for="(item, index) in list" :key="index">
          <myFollowItem :item="item"></myFollowItem>
        </div>
      </vantList>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import vantList from "@/components/list";
import myFollowItem from "@/components/memorialHallItem/myFollowItem";
import { getMyFollow } from "@/api/my/iFollowMemorialHalls";
export default {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      pageSize: 10,
      currentPage: 1,
      list: [
       
      ],
    };
  },
  components: {
    navBar,
    vantList,
    myFollowItem,
  },
  mounted() {
    this.getList();
  },
  methods: {
    //请求数据
    //请求数据
    async getList() {
      this.loading = true;
      const { currentPage, pageSize } = this;
      let params = {
        friendsTypes: ["gz"],
        currentPage,
        pageSize,
      };
      try {
        let res = await getMyFollow(params);
        this.loading = false;
        this.refreshing = false;
        if (res.code === "2000") {
          let list = JSON.parse(JSON.stringify(res.data)) || [];
          list.forEach((item) => {
            item.life = item.birthday + "~" + item.deathDay;
          });
          //根据总条数处理
          if (this.list.length <= res.total) {
            this.list = [...this.list, ...list];
            // this.currentPage++;
          } else {
            this.finished = true;
            this.loading = false;
          }
          // 如果总页数大于当前页码，页码+1
          if (res.currentPage > this.currentPage) {
            this.currentPage++;
          }
          // 如果当前页数 = 总页数，则已经没有数据
          else if (res.currentPage == this.currentPage) {
            this.finished = true;
            this.loading = false;
          }
        }
      } catch (err) {}
    },
    //下拉刷新
    refresh(refreshing) {
      this.refreshing = refreshing;
      // 清空列表数据
      this.currentPage = 1;
      this.list = [];
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.i_follow {
  background: #f9f9f9;
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  .list_content {
    flex: 1;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 0.125rem;
    /deep/ .van-list {
      width: 100%;
    }
  }
}
</style>
