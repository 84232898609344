// 代祭扫商品列表

import request from "@/utils/request";

//保存预约信息 返回预约码
export function replaceSacrifice_commodities(data) {
  return request({
    url: "/sys/replaceSacrifice/commodities",
    method: "post",
    data,
  });
}
// 调起微信支付
export function appWxpayPay(data) {
  return request({
    url: "/app/wxpay/pay",
    method: "post",
    data,
  });
}
