<template>
  <div class="appointmentTime wh100">
    <navBar title="选择预约时间" :leftArrowFlag="true"></navBar>
    <div class="content">
      <h2>
        当前时间段已预约<span class="appointmentNums">{{
          appointmentNums
        }}</span
        >人
      </h2>
      <h3>
        当前时间段最多可预约人数上限为
        <span class="allNums">{{ allNums }}</span> 人
      </h3>
      <van-field
        readonly
        clickable
        name="calendar"
        :value="appointmentDay"
        label="选择预约日期"
        placeholder="点击选择日期"
        @click="showCalendar = true"
      />
      <div class="times_box">
        <div class="times_title"><span>选择预约时间</span></div>
        <div class="times_list">
          <div
            class="times_item"
            :class="[
              selectId == item.id ? 'active' : '',
              item.disabled ? 'disabled' : '',
            ]"
            v-for="(item, index) in times"
            :key="index"
            @click="timeClick(item)"
          >
            <span>{{ item.time }}</span>
          </div>
        </div>
      </div>
      <div
        class="large_btn"
        :class="allNums == appointmentNums ? 'disabled' : ''"
        @click="handelClick"
      >
        <span>确定</span>
      </div>
      <van-calendar
        :max-date="maxDate"
        v-model="showCalendar"
        @confirm="onConfirm"
      />
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import { appointmentSacrifice_sacrificeDetail } from "@/api/appointment/appointmentTime";

export default {
  name: "appointmentTime",
  data() {
    return {
      maxDate: this.$dayjs(new Date()).add(7, "day").$d,
      appointmentDay: this.$dayjs(new Date()).format("YYYY-MM-DD"), //预约日期
      showCalendar: false,
      appointmentNums: 0, // 预约人数
      allNums: 0, // 预约总人数
      appointmentNum: 0, // 当前时段预约人数
      residueNum:0,//剩余预约人数
      selectTime: "",
      selectId: 0,
      maxAttendNum: 0, // 最大预约随从人数
      times: [],
    };
  },
  components: {
    navBar,
  },
  created() {
    let time = JSON.parse(localStorage.getItem("adminconfig"))
      .appointmentEndNum;
    this.maxDate = this.$dayjs(new Date()).add(time - 1, "day").$d;
    this.onload();
  },
  mounted(){
    
     
  },
  watch: {
    selectId(val){
      
    }
  },

  methods: {
    //生成分钟总数
    minute(str) {
      console.log(str, "0");
      let index = str.indexOf(":");
      let h = parseInt(str.substring(0, index));
      console.log(h, "1");
      let m = parseInt(str.substring(index + 1, str.length));
      console.log(m, "2");
      return h * 60 + m;
    },
    async onload() {
      let postData = {
        appointmentId: this.selectId ? this.selectId : "",
        appointmentDate: this.appointmentDay,
      };
      // let now = this.$dayjs(new Date()).unix();
      try {
        const res = await appointmentSacrifice_sacrificeDetail(postData);
        console.log(res, "list");
        if (res.code === "2000") {
          let list = JSON.parse(JSON.stringify(res.data.appointmentInfos));
          list.forEach((item,index) => {
            if(item.isClock==0){
              item.disabled=true
            }else{
              item.disabled=false;
              if(item.isFlag==1){
                this.selectId=item.id;
                this.selectTime = item.appointmentAm + "-" + item.appointmentPm;
              }
            }
            item.time = item.appointmentAm + "-" + item.appointmentPm;
            
          });
          this.appointmentNums = res.data.presentNum;
          this.allNums = res.data.sumNum;
          this.maxAttendNum = res.data.maxAttendNum;
          this.residueNum = res.data.residueNum;
          this.times = list;
          
          return;
        }
        this.$toast.fail(res.msg || "数据请求失败");
      } catch (err) {
        console.log(err, 404);
        this.$toast.fail(err.msg || "数据请求失败");
      }
    },
    timeClick(item) {
      if (item.disabled) {
        return;
      } else {
        if(item.id==this.selectId){
          return
        }
         console.log(item,'789');
        this.selectTime = item.time;
        this.selectId = item.id;
        this.appointmentNum = item.appointmentNum;
        // 选中时间段是发送请求
        this.onload();
      }
    },
    onConfirm(date) {
      console.log(date);
      //this.appointmentTime = `${date.getMonth() + 1}/${date.getDate()}`;
      this.appointmentDay = this.$dayjs(date).format("YYYY-MM-DD");
      this.showCalendar = false;
      this.selectId=0;
      this.selectTime=''
      // 日期发生改变时发送请求
      this.onload();
    },
    handelClick() {
      if (this.selectTime == "") {
        this.$toast.fail("预约时间必选");
        return;
      }
      if (this.allNums == this.appointmentNums) {
        this.$toast.fail("当前时间段预约人数已满,不可预约");
        return;
      }
      this.$router.push({
        path: "/appointment/editAppointmentInfo",
        query: {
          appointmentId: this.selectId,
          day: this.appointmentDay,
          selectTime: this.selectTime,
          residueNum: this.residueNum,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentTime {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  /deep/ .van-calendar {
    .van-calendar__selected-day {
      background-color: $defaultColor;
    }
    .van-button--danger {
      background-color: $defaultColor;
      border: 1px solid $defaultColor;
    }
  }
  .content {
    flex: 1;
    padding: 0.12rem;
    /deep/ .van-field__control {
      color: $defaultColor;
    }
    h2 {
      font-size: 16px;
      line-height: 0.35rem;
      margin-bottom: 0.1rem;
      text-align: center;
      .appointmentNums {
        font-size: 20px;
        color: $defaultColor;
        font-weight: 600;
        line-height: 0.35rem;
      }
    }
    h3 {
      font-size: 14px;
      line-height: 0.2rem;
      text-align: center;
      color: $lightBlack;
      margin-bottom: 0.25rem;
    }
    .times_box {
      margin-top: 0.2rem;
      min-height: 3.5rem;
      .times_title {
        font-size: 14px;
        line-height: 0.2rem;
        color: $defaultColor;
      }
      .times_list {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        .times_item {
          width: 1.06rem;
          height: 0.44rem;
          border: 1px solid $lightBlack;
          text-align: center;
          line-height: 0.44rem;
          color: $lightBlack;
          margin: 0.1rem 0;
          margin-right: 0.16rem;
          border-radius: 0.03rem;
        }
        .times_item:nth-child(3n) {
          margin-right: 0;
        }
        .times_item.disabled {
          background-color: #d4d4d4;
          border: 1px solid #d4d4d4;
          // color: #fff;
          cursor: not-allowed;
          opacity: 0.5;
        }
        .times_item.active {
          background: $defaultColor;
          border: 1px solid $defaultColor;
          color: #fff;
        }
      }
    }
    .disabled {
      background-color: #d4d4d4;
      border: 1px solid #d4d4d4;
      // color: #fff;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
</style>
