<template>
  <div class="orderItem" @click="goDetail(item.orderNo)">
    <div class="item_top">
      <div class="top">
        <div class="top_name">
          预约人姓名：<span>{{ item.appointmentName }}</span>
        </div>
        <div class="top_status">
          <span
            :class="[
              item.asUse == '0' ? 'status1' : '',
              item.asUse == '1' ? 'status2' : '',
              item.asUse == '2' ? 'status3' : '',
              item.asUse == '3' ? 'status4' : '',
              item.asUse == '4' ? 'status5' : '',
            ]"
            >{{ item.asUse  | formatStatus(item.orderType) }}</span
          >
        </div>
      </div>
      <div class="date">
        预约日期：<span>{{ item.apointmentSegment }}</span>
      </div>
    </div>
    <div class="item_bottom" v-if="item.asUse==3||item.asUse==2">
      <div class="bottom_btn" v-if="item.asUse == 3"><span>去付款</span></div>
      <div
        class="bottom_btn"
        v-if="item.asUse == 2 || item.asUse == 3"
        @click="del(item.id, item.version)"
      >
        <span>取消祭扫</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //asUse:0用户取消;1工作人员取消2:带审核3工作人员通过,4支付成功5完成
    };
  },
  props: {
    item: {
      type: Object,
      dafault: {},
    },
  },
  filters: {
   formatStatus(val,type) {
     console.log(val,type,'88888')
      if(val==0){
        return "用户取消";
      }else if(val==1){
         return "工作人员取消";
      }else if(val==2){
         return "待审核";
      }else if(val==3){
        return "审核通过";
      }else if(val==4&&type==0){
         return "免费祭扫";
      }else if(val==4&&type==1){
         return "支付成功";
      }
      else if(val==5){
          return "完成";
      }
    },
  },
  methods: {
    goDetail(id) {
      this.$router.push(`/appointment/orderDetail/${id}`);
    },
    del(id, version) {
       this.$dialog.confirm({
        title: '提示',
        message: '您确定取消吗',
      })
  .then(() => {
    // on confirm
     this.$emit("del", { id, version });
  })
  .catch(() => {
    // on cancel
  });
    },
  },
};
</script>

<style lang="scss" scoped>
.orderItem {
  padding: 0.15rem 0.1rem 0;
  // margin: 0.1rem 0;
  border-radius: 0.05rem;
  // box-shadow: 0px 0px 0.1rem 0.05rem #f0f0f0;
  .item_top {
    border-bottom: 1px solid #ededed;
    .top {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      font-size: 15px;
      line-height: 0.21rem;
      margin-bottom: 0.05rem;
      .top_name {
      }
      .top_status {
        .status1 {
          color: #ff6666;
        }
        .status2 {
          color: #cc00ff;
        }
        .status3 {
          color: #e7643c;
        }
        .status4 {
          color: #1882ec;
        }
        .status5 {
          color: $defaultColor;
        }
      }
    }
    .date {
      font-size: 12px;
      line-height: 0.17rem;
      margin-bottom: 0.22rem;
    }
  }
  .item_bottom {
    padding: 0.05rem 0.1rem;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-end;
    .bottom_btn {
      border: 1px solid $defaultColor;
      border-radius: 0.05rem;
      width: 0.8rem;
      height: 0.25rem;
      text-align: center;
      line-height: 0.25rem;
      color: $defaultColor;
      margin-left: 0.25rem;
    }
  }
}
</style>
