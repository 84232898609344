<template>
  <div class="life wh100">
    <van-nav-bar
      title="简介"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="content">
      <div class="tabs">
        <div class="tab active"><span>简介</span></div>
        <div class="tab" @click="goAlbum"><span>相册</span></div>
        <div class="tab" @click="goEulogy"><span>悼文</span></div>
        <div class="tab" @click="goMessage"><span>留言</span></div>
      </div>
      <div class="life_main">
        <div class="life_item">
          <div class="item_title">
            <div class="line"></div>
            <span>个人信息</span>
          </div>
          <div class="item_content">
            <div
              class="dead"
              v-for="(item, index) in detail.deads"
              :key="index"
            >
              <div class="dead_info">
                <div class="info_lable">姓名:</div>
                <div class="info_des">{{ item.deadName }}</div>
              </div>
              <div class="dead_info">
                <div class="info_lable">性别:</div>
                <div class="info_des">
                  {{ item.genders == 0 ? "男" : "女" }}
                </div>
              </div>
              <div class="dead_info">
                <div class="info_lable">出生日期:</div>
                <div class="info_des">{{ item.birthday }}</div>
              </div>
              <div class="dead_info">
                <div class="info_lable">逝世日期:</div>
                <div class="info_des">{{ item.deathday }}</div>
              </div>
              <!-- <div class="dead_info">
                <div class="info_lable">个人简介:</div>
                <div class="info_des">{{ item.personalProfile }}</div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="life_item">
          <div class="item_title">
            <div class="line"></div>
            <span>生平事迹</span>
          </div>
          <div class="item_content">
            <p>{{ detail.des }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { memorialInfo_detail } from "@/api/memorialHall/album";

export default {
  data() {
    return {
      memoriaId: "",
      detail: {
        deads: [
          // {
          //   id: 1,
          //    deadName: "王二牛",
          //   genders: "男",
          //   birthday: "1960-02-11",
          //   deathday: "2020-02-10",
          //   personalProfile:
          //     "王二牛生于一九六零年一月一日，逝世日期二零二一年一月一日，山东济南人",
          // },
          // {
          //   id: 1,
          //    deadName: "王二牛",
          //   genders: "男",
          //   birthday: "1960-02-11",
          //   deathday: "2020-02-10",
          //   personalProfile:
          //     "王二牛生于一九六零年一月一日，逝世日期二零二一年一月一日，山东济南人",
          // },
        ],
        des: "",
        // "老人生平为人正直、关爱他人、平易近人、品德高尚。作为家庭的长辈，她勤劳善良、以身作则，一生辛勤劳作，是一位伟大的挚友",
      },
    };
  },
  created() {
    console.log(this.$route.params.id, "生平");
    this.memoriaId = this.$route.params.id;
    this.onload();
  },
  methods: {
    async onload() {
      let postData = {
        id: this.memoriaId,
      };
      console.log(postData, "postData");
      try {
        const res = await memorialInfo_detail(postData);
        console.log(res, 200);
        if (res.code === "2000") {
          this.detail.des = res.data.biography; //生平简介
          // let dataList = JSON.parse(JSON.stringify( res.data.deadInfoDetails));

          this.detail.deads = res.data.deadInfoDetails;
          return;
        }
        this.$toast.fail(res.msg || "数据请求失败");
      } catch (err) {
        console.log(err, 404);
        this.$toast.fail(err.msg);
      }
    },
    //返回纪念馆
    onClickLeft() {
      this.$router.push(`/memorialHall/${this.$route.params.id}`);
    },
    //去相册
    goAlbum() {
      this.$router.push(`/memorialHall/album/${this.$route.params.id}`);
    },
    //去悼文
    goEulogy() {
      this.$router.push(
        `/memorialHall/eulogyManagement/${this.$route.params.id}`
      );
    },
    //去留言
    goMessage() {
      this.$router.push(
        `/memorialHall/messageManagement/${this.$route.params.id}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.life {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  /deep/ .van-nav-bar {
    background-color: $defaultColor;
    .van-icon,
    .van-nav-bar__text {
      color: #fff;
    }
  }
  .content {
    flex: 1;
    padding: 0.12rem;
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
    overflow-y: scroll;
    .tabs {
      display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
      -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
      justify-content: space-around;
      margin-bottom: 0.2rem;
      .tab {
        width: 0.8rem;
        height: 0.3rem;
        border-radius: 0.05rem;
        background: #f5f5f5;
        color: #b7b7b7;
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        -moz-justify-content: center;
-webkit-justify-content: center;
justify-content: center;
        -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
        span {
          font-size: 15px;
        }
      }
      .tab.active {
        background: $defaultColor;
        span {
          color: #fff;
        }
      }
    }
    .life_main {
      flex: 1;
      overflow-y: scroll;
      .life_item {
        .item_title {
          display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
          -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
          margin-bottom: 0.15rem;
          .line {
            width: 3px;
            height: 0.17rem;
            background: $defaultColor;
            margin-right: 0.04rem;
            border-radius: 0.03rem;
          }
          span {
            font-size: 15px;
            line-height: 0.21rem;
          }
        }
        .item_content {
          .dead {
            margin: 0.1rem 0 0.1rem;
            border-bottom: 1px solid #ececec;
            .dead_info {
              display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
              // -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
              font-size: 14px;
              line-height: 0.25rem;
              .info_lable {
                min-width: 0.6rem;
                color: $lightBlack;
              }
            }
          }
          .dead:last-child {
            border: unset;
          }
          p {
            font-size: 14px;
            line-height: 0.2rem;
          }
        }
      }
    }
  }
}
</style>
