import request from "@/utils/request";

//生平简介
export function memorialInfo_detail(data) {
  return request({
    url: "/app/memorialInfo/detail",
    method: "post",
    data,
  });
}
//相册图片列表
export function memorialInfo_photoAlbum(data) {
  return request({
    url: "/app/memorialInfo/photoAlbum",
    method: "post",
    data,
  });
}

// 相册图片上传
export function memorialInfo_photoAlbum_save(data) {
  return request({
    url: "/app/memorialInfo/photoAlbum/save",
    method: "post",
    data,
  });
}
// 相册图片删除
export function memorialInfo_photoAlbum_delete(data) {
  return request({
    url: "/app/memorialInfo/photoAlbum/delete",
    method: "post",
    data,
  });
}

// 新增留言/悼文
export function note_operation(data) {
  return request({
    url: "/app/note/operation",
    method: "post",
    data,
  });
}

