<template>
  <div class="login_register wh100">
    <navBar title="修改密码" :leftArrowFlag="true"></navBar>
    <div class="login_form_wrap">
      <van-form @submit="Submit" class="form">
        <div class="show_phone">
          <span class="tips">当前手机号:</span
          ><span class="phone">{{ phone }}</span>
        </div>
        <div class="form_item">
          <van-field
            class="form_code"
            v-model="form.verificationCode"
            center
            name="verificationCode"
            clearable
            label="验证码"
            :rules="[{ required: true, message: '请填写验证码！' }]"
            placeholder="请输入短信验证码"
          />
          <van-button
            class="code_btn"
            size="small"
            type="primary"
            @click="sendCode"
            :disabled="!disabledCodeBtn"
            >{{ btnText }}</van-button
          >
        </div>

        <div class="form_btn">
          <van-button round block type="primary" native-type="submit"
            >下一步</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import { getVerificationCode, checkMsgCode } from "@/api/user";
export default {
  name: "",
  data() {
    return {
      disabledCodeBtn: true, //验证码按钮文字
      phone: "",
      form: {
        
        verificationCode: "",
      },

      timer: null, //定时器标识
      btnText: "发送验证码", //按钮文字
      passErrMessage: "",
    };
  },
  created() {
    this.phone = this.$route.params.phone;
    //this.sendCode();
  },
  components: {
    navBar,
  },
  methods: {
    //手机验证码登录
    async Submit(vals) {
      const {  verificationCode } = this.form;
      const {  phone } = this;
      let params = {
        phone,
        code: verificationCode,
        codeType:2
      };
      let res = await checkMsgCode(params);
      if (res.code != "2000") {
        this.$toast.fail(res.msg || "数据请求失败");
        return;
      } else {
        this.$router.push({
          path: "/resetPassword",
        });
      }
    },
    //发送验证码
    async sendCode() {
      let params = {
        mobile: this.phone,
        msgType: 2,
      };
      let result = await getVerificationCode(params);
      if (result.code == "2000") {
        this.$toast.success(result.data);
        this.countDown(10);
      } else {
        this.$toast.fail(res.result);
      }
      //
    },
    // 倒计时方法
    countDown(time) {
      if (time === 0) {
        this.disabledCodeBtn = true;
        this.btnText = "发送验证码";
        return;
      } else {
        this.disabledCodeBtn = false;
        this.btnText = "重新发送(" + time + ")";
        time--;
      }
      setTimeout(() => {
        this.countDown(time);
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.login_register {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
 /deep/ .van-button--primary{
    width: 100%;
    margin:0;
    border-radius: 0.1rem;
    background-color: $defaultColor;
    border: 1px solid $defaultColor;
    box-shadow:0px 0px 10px $defaultColor;
}
  .login_form_wrap {
    flex: 1;
    padding: 0.5rem 0.25rem 0;
    .form {
      .show_phone {
        margin: 0.25rem 0;
        font-size: 15px;
        line-height: 0.225rem;
        color: $textColor;
        .tips {
          color: $lightBlack;
        }
        .phone {
          font-weight: 600;
        }
      }
      .form_item {
        width: 100%;
        height: 0.44rem;
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
        margin-bottom: 1.8rem;
        .form_code {
          width: 1.9rem;
          height: 100%;
          background: #f8f8f8;
          /deep/ .van-field__label {
            width: auto;
            padding-left: 0.1rem;
          }
        }
        /deep/ .van-button--primary {
          width: 1.17rem !important;
          height: 0.44rem !important;
        }
      }
    }
  }
}
</style>