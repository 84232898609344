<template>
  <div class="appointmentInfo wh100">
    <navBar title="填写预约信息" :leftArrowFlag="true" />
    <div class="content">
      <van-form @submit="onSubmit" class="form">
        <van-field
          v-model="form.appointmentName"
          name="appointmentName"
          label="预约人姓名"
          error-message=""
          placeholder="预约人姓名"
          :rules="[{ required: true, message: '请填写预约人姓名' }]"
        />
        <van-field
          v-model="form.appointmentPhone"
          name="appointmentPhone"
          type="digit"
          label="预约人手机号"
          placeholder="预约人手机号"
          :rules="[
            { required: true, message: '请填写预约人手机号' },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
          ]"
        />
        <van-field
          v-model="form.appointmentCardNo"
          name="appointmentCardNo"
          label="预约人身份证号"
          placeholder="预约人身份证号"
          :rules="[
            { required: true, message: '请填写预约人身份证号' },
            { validator: idCardChange, message: '请填写正确的身份证号' },
          ]"
        />
        <van-field
          v-model="form.appointmentDate"
          name="appointmentDate"
          label="预约日期"
          disabled
          placeholder="预约日期"
          :rules="[{ required: true, message: '请填写预约日期' }]"
        />
        <van-field
          v-model="form.appointmentTime"
          name="appointmentTime"
          label="预约时间"
          disabled
          placeholder="预约时间"
          :rules="[{ required: true, message: '请填写预约时间' }]"
        />
        <van-field
          readonly
          clickable
          name="attendNum"
          :value="form.attendNum"
          label="随行人数"
          placeholder="请选择随行人数"
          right-icon="arrow-down"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="personNums"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <div class="accompanyingList">
          <div
            class="accompanying_item"
            v-for="(item, index) in form.friendReqList"
            :key="index"
          >
            <div class="item_title">
              第<span>{{ index + 1 }}</span
              >位随行人信息
            </div>
            <van-field
              v-model="form.friendReqList[index].friendName"
              label="随行人姓名"
              placeholder="随行人姓名"
              :rules="[{ required: true, message: '请填写随行人姓名' }]"
            />
            <van-field
              v-model="form.friendReqList[index].friendPhone"
              type="digit"
              label="随行人手机号"
              placeholder="随行人手机号"
              :rules="[{ required: true, message: '请填写随行人手机号' },{ pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },]"
            />
            <van-field
              v-model="form.friendReqList[index].friendCard"
              name="appointmentCardNo"
              label="随行人身份证号"
              placeholder="随行人身份证号"
              :rules="[
                { required: true, message: '请填写随行人身份证号' },
                { validator: idCardChange, message: '请输入正确的身份证号' },
              ]"
            />
          </div>
        </div>
        <div style="margin: 16px">
          <van-button :loading='btnLoading' :disabled='btnDisabled' round block type="primary" native-type="submit"
            >确认预约</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import { getUserId } from "@/utils/auth";
import { appointmentSacrifice_operation } from "@/api/appointment/editAppointmentInfo";
export default {
  data() {
    return {
       btnLoading:false,
      btnDisabled:false,
      showPicker: false,
      maxNum: 7,
      personNums: [],
      userId: "",
      appointmentId: "",
      maxAttendNum: 0, // 最大随从人数上限
      form: {
        appointmentId: "",
        appointmentName: "", //预约人名称
        appointmentPhone: "", //预约电话
        appointmentCardNo: "", //预约人身份证
        appointmentDate: "", //预约日期
        appointmentTime: "", // 预约时间
        attendNum: 0, //预约随从人数
        friendReqList: [],
        loginUserId: "", //登陆人id
      },
    };
  },
  components: {
    navBar,
  },
  created() {
    // 将保存中的数据加载到页面
    if (JSON.parse(localStorage.getItem("editAppointmentInfo"))) {
      this.form = JSON.parse(localStorage.getItem("editAppointmentInfo"));
    }
    console.log(this.$route.query, getUserId(), "88");
    // let { appointmentId } = this.$route.query;
    // this.form.appointmentId = appointmentId;
    this.form.appointmentDate = this.$route.query.day;
    this.form.appointmentTime = this.$route.query.selectTime;
    this.form.loginUserId = getUserId();
    this.form.appointmentId = JSON.parse(this.$route.query.appointmentId);
    this.maxNum = parseInt(this.$route.query.residueNum)-1;
    // this.userId = getUserId();
    // this.appointmentId = JSON.parse(this.$route.query.appointmentId);

    // // localStorage.removeItem("editAppointmentInfo") // 清除本页保存的数据
    // 获取后台配置的最大预约随从人数
    this.maxAttendNum = JSON.parse(
      localStorage.getItem("adminconfig")
    ).maxAttendNum-1;
    this.getPersons(this.maxNum);
  },
  mounted() {},
  watch: {
    form: {
      handler(newName) {
        // console.log(newName);
        localStorage.setItem("editAppointmentInfo", JSON.stringify(newName));
      },
      deep: true,
    },
  },
  methods: {
    renderName(index, val) {
      return `${val}${index}`;
    },
    // 加载可选择的随从人数数量
    getPersons(num = 0) {
      console.log(this.maxAttendNum,'');
      let arr = [];
      let people = num > this.maxAttendNum ? this.maxAttendNum : num;
      for (let i = 0; i <= people; i++) {
        arr.push(i);
      }
      this.personNums = arr;
    },
    // 选择随从人数,并加载相应数量的随行人的信息输入框
    onConfirm(value) {
      console.log(value, "88");
      this.form.attendNum = value;
      let arr = [];
      for (let i = 0; i < value; i++) {
        let obj = {
          friendName: "",
          friendPhone: "",
          friendCard: "",
        };
        arr.push(obj);
      }
      this.form.friendReqList = arr;
      this.showPicker = false;
    },
    async onSubmit() {
      if (this.form.friendReqList.length >= 1) {
        let arr = [this.form.appointmentCardNo];
        this.form.friendReqList.forEach((item) => {
          arr.push(item.friendCard);
        });
        console.log(arr);
        let nary = arr.sort();
        for (var i = 0; i < arr.length; i++) {
          if (nary[i] == nary[i + 1]) {
            this.$toast("人员信息不可重复");
            return;
          }
        }
      }
      let Data = JSON.parse(JSON.stringify(this.form));
      // localStorage.setItem("editAppointmentInfo", JSON.stringify(this.form)); // 保存到本地存储
      // Data.appointmentDate =
      //   this.$route.query.day + " " + this.$route.query.selectTime;

      let {
        appointmentId,
        appointmentName, //预约人名称
        appointmentPhone, //预约电话
        appointmentCardNo, //预约人身份证
        appointmentDate, //预约日期
        appointmentTime, // 预约时间
        attendNum, //预约随从人数
        friendReqList,
        loginUserId, //登陆人id
      } = Data;

      let postData = {
        appointmentId,
        appointmentName, //预约人名称
        appointmentPhone, //预约电话
        appointmentCardNo, //预约人身份证
        appointmentDate,
        appointmentTime,
        attendNum, //预约随从人数
        friendReqList,
        loginUserId, //登陆人id
      };

      console.log(postData, "postData");
      // return;
      this.btnLoading=true
      this.btnDisabled=true
      try {
        const res = await appointmentSacrifice_operation(postData);
        console.log(res, 200);
        if (res.code === "2000") {
          this.$toast.success("预约成功");
          this.btnLoading=false
                this.btnDisabled=false
          localStorage.setItem("ORDER_NUM", res.data);
          localStorage.removeItem("editAppointmentInfo");
          this.$router.push("/appointment/appointmentSuccess");
          return;
        }
        this.$toast.fail(res.msg || "预约失败");
        this.btnLoading=false
        this.btnDisabled=false
      } catch (err) {
        console.log(err, 404);
        this.$toast.fail(err.msg);
        this.btnLoading=false
                this.btnDisabled=false
      }
    },

    // 身份证号验证
    idCardChange(idCard) {
      var flag = false;
      //15位和18位身份证号码的正则表达式
      var regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;

      //如果通过该验证，说明身份证格式正确，但准确性还需计算
      if (regIdCard.test(idCard)) {
        if (idCard.length == 18) {
          var idCardWi = new Array(
            7,
            9,
            10,
            5,
            8,
            4,
            2,
            1,
            6,
            3,
            7,
            9,
            10,
            5,
            8,
            4,
            2
          ); //将前17位加权因子保存在数组里
          var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2); //这是除以11后，可能产生的11位余数、验证码，也保存成数组
          var idCardWiSum = 0; //用来保存前17位各自乖以加权因子后的总和
          for (var i = 0; i < 17; i++) {
            idCardWiSum += idCard.substring(i, i + 1) * idCardWi[i];
          }

          var idCardMod = idCardWiSum % 11; //计算出校验码所在数组的位置
          var idCardLast = idCard.substring(17); //得到最后一位身份证号码

          //如果等于2，则说明校验码是10，身份证号码最后一位应该是X
          if (idCardMod == 2) {
            if (idCardLast == "X" || idCardLast == "x") {
              flag = true;
            } else {
              flag = false;
            }
          } else {
            //用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
            if (idCardLast == idCardY[idCardMod]) {
              flag = true;
            } else {
              flag = false;
            }
          }
        } else if (idCard.length == 15) {
          var id17 = idCard.substring(0, 6) + "19" + idCard.substring(6);
          var parityBit = getParityBit(id17);
          var tempIdCard = id17 + parityBit;
          flag = validateIdCard(tempIdCard);
        }
      } else {
        flag = false;
      }
      return flag;
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentInfo {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  .content {
    flex: 1;
    overflow-y: scroll;
    .form {
      background: #f9f9f9;
      // /deep/ .van-field__error-message {
      //   display: block;
      // }
      /deep/ .van-button--primary {
        width: 100%;
        margin: 0;
        border-radius: 0.1rem;
        background-color: $defaultColor;
        border: 1px solid $defaultColor;
        box-shadow: 0px 0px 10px $defaultColor;
      }

      /deep/ .van-field__label {
        width: 1rem;
      }
      /deep/ .van-cell {
        padding: 0.1rem 0.12rem !important;
      }
      .accompanyingList {
        .accompanying_item {
          background: #fff;
          margin-top: 0.1rem;
          padding: 0.2rem 0;
          .item_title {
            color: $defaultColor;
            font-size: 12px;
            padding: 0 0.12rem;
            line-height: 0.17rem;
          }
        }
      }
    }
  }
}
</style>
