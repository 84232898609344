<template>
  <div class="createMemorialHall wh100">
    <navBar title="创建纪念馆" :leftArrowFlag="true" />
    <div class="form_wrap">
      <van-form @submit="onSubmit" class="form">
        <van-field class="mb20" name="radio" label="纪念馆类型">
          <template #input>
            <van-radio-group
              v-model="form.memorialType"
              direction="horizontal"
              @change="typeChange"
            >
              <van-radio name="1">单人馆</van-radio>
              <van-radio name="2">双人馆</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <div class="form_item">
          <van-field name="uploader" label="逝者照片" class="avatar_box">
            <template #input>
              <van-uploader
                :after-read="afterUpload"
                :max-count="1"
                :before-read="beforeUpload"
                v-model="form.deadList[0].uploader"
              >
                <img class="avatar" :src="avatar" alt="" />
              </van-uploader>
            </template>
          </van-field>
          <van-field
            v-model="form.deadList[0].deadName"
            name="deadName"
            label="逝者姓名"
            placeholder="请输入逝者姓名"
            :rules="[{ required: true, message: '请填写逝者姓名' }]"
          />
          <van-field name="radio" label="逝者性别">
            <template #input>
              <van-radio-group
                v-model="form.deadList[0].genders"
                direction="horizontal"
              >
                <van-radio :name="0">男</van-radio>
                <van-radio :name="1">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field name="calendar" label="时间类型">
            <template #input>
              <van-radio-group
                v-model="form.deadList[0].calendar"
                direction="horizontal"
              >
                <van-radio :name="1">阴历</van-radio>
                <van-radio :name="0">阳历</van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <van-field
            readonly
            clickable
            name="birthday"
            :value="form.deadList[0].birthday"
            label="出生日期"
            placeholder="点击选择日期"
            @click="showBirthday1 = true"
          />
          <van-popup v-model="showBirthday1" position="bottom">
            <van-datetime-picker
              type="date"
              title="选择年月日"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="onConfirmBirthday1"
              @cancel="showBirthday1 = false"
            />
          </van-popup>

          <van-field
            class="mb20"
            readonly
            clickable
            :value="form.deadList[0].deathday"
            label="死亡日期"
            placeholder="点击选择日期"
            @click="showDeathDate1 = true"
          />
          <van-popup v-model="showDeathDate1" position="bottom">
            <van-datetime-picker
              type="date"
              title="选择年月日"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="onConfirmDeathDate1"
              @cancel="showDeathDate1 = false"
            />
          </van-popup>
        </div>
        <div v-if="showNext">
          <van-field name="uploader" label="逝者照片" class="avatar_box">
            <template #input>
              <van-uploader
                :after-read="afterUpload"
                :max-count="1"
                :before-read="beforeUpload"
                v-model="form.deadList[1].uploader"
              >
                <img class="avatar" :src="avatar" alt="" />
              </van-uploader>
            </template>
          </van-field>
          <van-field
            v-model="form.deadList[1].deadName"
            name="deadName"
            label="逝者姓名"
            placeholder="请输入逝者姓名"
            :rules="[{ required: true, message: '请填写逝者姓名' }]"
          />
          <van-field name="genders" label="逝者性别">
            <template #input>
              <van-radio-group
                v-model="form.deadList[1].genders"
                direction="horizontal"
              >
                <van-radio :name="0">男</van-radio>
                <van-radio :name="1">女</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field name="calendar" label="时间类型">
            <template #input>
              <van-radio-group
                v-model="form.deadList[1].calendar"
                direction="horizontal"
              >
                <van-radio :name="1">阴历</van-radio>
                <van-radio :name="0">阳历</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            readonly
            clickable
            name="birthday"
            :value="form.deadList[1].birthday"
            label="出生日期"
            placeholder="点击选择日期"
            @click="showBirthday2 = true"
          />
          <van-popup v-model="showBirthday2" position="bottom">
            <van-datetime-picker
              type="date"
              title="选择年月日"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="onConfirmBirthday2"
              @cancel="showBirthday2 = false"
            />
          </van-popup>

          <van-field
            class="mb20"
            readonly
            clickable
            :value="form.deadList[1].deathday"
            label="死亡日期"
            placeholder="点击选择日期"
            @click="showDeathDate2 = true"
          />
          <van-popup v-model="showDeathDate2" position="bottom">
            <van-datetime-picker
              type="date"
              title="选择年月日"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="onConfirmDeathDate2"
              @cancel="showDeathDate2 = false"
            />
          </van-popup>
        </div>
        <van-field
          readonly
          clickable
          name="deadRelation"
          :value="form.deadRelation"
          label="您是逝者的"
          placeholder="点击选择关系"
          @click="showPicker = true"
          :rules="[{ required: true, message: '请选择关系' }]"
        />
        <van-popup
          class="relationship_pop"
          v-model="showPicker"
          style="height: 50%"
          position="bottom"
        >
          <div class="popup_title">
            <div class="title_left" @click="showPicker = false">
              <span>取消</span>
            </div>
            <div class="title_center"><span>请选择</span></div>
            <div class="title_right" @click="getRelationship">
              <span>确定</span>
            </div>
          </div>
          <van-tabs v-model="active">
            <van-tab
              :title="item.name"
              v-for="(item, index) in relationship"
              :key="index"
            >
              <div class="relationship_list">
                <div
                  :class="[
                    selectRelationshipItem.id == child.id ? 'active' : '',
                    'relationship_item',
                  ]"
                  @click="clickRelationshipItem(child)"
                  v-for="(child, index) in item.deadRelationRespList"
                  :key="index"
                >
                  <span>{{ child.name }}</span>
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </van-popup>
        <van-field
          v-model="form.epitaph"
          rows="4"
          autosize
          label="墓志铭"
          type="textarea"
          maxlength="100"
          placeholder="请输入墓志铭"
          show-word-limit
        />
        <van-field
          v-model="form.biography"
          rows="4"
          autosize
          label="个人简介"
          type="textarea"
          maxlength="100"
          placeholder="请输入个人简介"
          show-word-limit
        />

        <div class="btn_box">
          <van-button :loading='btnLoading' :disabled='btnDisabled' round block type="primary" native-type="submit"
            >创建纪念馆</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import { memorialInfoOperation } from "@/api/memorialHall/createMemorialHall";
import { fetchRelationshipList } from "@/api/relationship";
export default {
  data() {
    return {
      btnLoading:false,
      btnDisabled:false,
      avatar: require("../../assets/imgs/avatar.png"),
      relationship: [],
      active: 0,
      selectRelationshipItem: {},
      showNext: false,
      showBirthday1: false,
      showDeathDate1: false,
      showBirthday2: false,
      showDeathDate2: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      columns: ["朋友", "家人", "同学"],
      showPicker: false,
      form: {
        memorialType: "1",
        deadList: [
          {
            id: 1,
            uploader: [],
            deadName: "",
            genders: 0,
            calendar: 1,
            birthday: this.$dayjs(new Date()).format("YYYY-MM-DD"),
            deathday: this.$dayjs(new Date()).format("YYYY-MM-DD"),
          },
          {
            id: 2,
            uploader: [],
            deadName: "",
            genders: 0,
            calendar: 1,
            birthday: this.$dayjs(new Date()).format("YYYY-MM-DD"),
            deathday: this.$dayjs(new Date()).format("YYYY-MM-DD"),
          },
        ],
        deadRelation: "",
        deadRelationId: "",
        biography: "",
        epitaph: "",
      },
    };
  },
  components: {
    navBar,
  },
  watch: {},
  created() {
    //获取逝者关系
    this.getRelationshipList();
  },
  methods: {
    //获取逝者关系
    async getRelationshipList() {
      let res = await fetchRelationshipList({});
      console.log(res, "9999");
      if (res.code != "2000"){
        this.$toast.fail(res.msg || "数据请求失败");
        return 
      } 
      this.relationship = res.data;
    },
    typeChange(e) {
      if (e == 2) {
        this.showNext = true;
      } else {
        this.showNext = false;
      }
    },
    beforeUpload(file) {
      // if (file.type !== 'image/jpeg') {
      //   this.$toast('请上传 jpg 格式图片');
      //   return false;
      // }
      return true;
    },
    afterUpload(file) {
      console.log(file, "111");
    },
    onConfirmBirthday1(date) {
      console.log(date, "生日1");
      this.form.deadList[0].birthday = this.$dayjs(date).format("YYYY-MM-DD");
      this.showBirthday1 = false;
    },
    onConfirmDeathDate1(date) {
      let now=this.$dayjs(new Date()).unix();
      let birthday=this.$dayjs(this.form.deadList[0].birthday).unix();
      let deathday=this.$dayjs(date).unix();
      if(deathday<birthday){
        this.$toast.fail('出生日期应小于死亡日期');
        return 
      }else if(deathday>now){
        this.$toast.fail('死亡日期应小于当前时间');
        return 
      }
      console.log(this.form.deadList[0].birthday,date,birthday,deathday,'012')
      this.form.deadList[0].deathday = this.$dayjs(date).format("YYYY-MM-DD");
      this.showDeathDate1 = false;
    },
    onConfirmBirthday2(date) {
      this.form.deadList[1].birthday = this.$dayjs(date).format("YYYY-MM-DD");
      this.showBirthday2 = false;
    },
    onConfirmDeathDate2(date) {
      let now=this.$dayjs(new Date()).unix();
      let birthday=this.$dayjs(this.form.deadList[1].birthday).unix();
      let deathday=this.$dayjs(date).unix();
      if(deathday<birthday){
        this.$toast.fail('出生日期应小于死亡日期');
        return 
      } else if(deathday>now){
        this.$toast.fail('死亡日期应小于当前时间');
        return 
      }
      this.form.deadList[1].deathday = this.$dayjs(date).format("YYYY-MM-DD");
      this.showDeathDate2 = false;
    },
    onRelationshipConfirm(value) {
      this.form.deadRelation = value;
      this.showPicker = false;
    },
    onSubmit() {
      let data = this.form;
      let params = JSON.parse(JSON.stringify(data));
      params.deadList[0].deadPortraitUrl = data.deadList[0].uploader.length
        ? data.deadList[0].uploader[0].content
        : "";
      params.deadList[1].deadPortraitUrl = data.deadList[1].uploader.length
        ? data.deadList[1].uploader[0].content
        : "";
      if (data.memorialType === "1") {
        params.deadList.pop();
      }
      this.btnLoading=true
      this.btnDisabled=true
      memorialInfoOperation(params)
        .then(() => {
          this.$toast({
            type:'success',
            message:'创建成功',
             onClose: () => {
               this.btnLoading=false
                this.btnDisabled=false
              this.$router.go(-1);
            },
          });
         
        })
        .catch(() => {
          this.$toast({
            type:'fail',
            message:'创建失败',
             onClose: () => {
              this.btnLoading=false
                this.btnDisabled=false
            },
          });
        });
    },
    //关系每项点击
    clickRelationshipItem(item) {
      this.selectRelationshipItem = item;
    },
    //确定获取选择的关系
    getRelationship() {
      this.form.deadRelation = this.selectRelationshipItem.name;
      this.form.deadRelationId = this.selectRelationshipItem.id;
      console.log(this.form.deadRelation, "999");
      this.showPicker = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.createMemorialHall {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
/deep/ .van-radio__icon--checked .van-icon{
    background-color: $defaultColor;
  border-color: $defaultColor;
}
    /deep/ .van-button--primary{
    width: 100%;
    margin:0;
    border-radius: 0.1rem;
    background-color: $defaultColor;
    border: 1px solid $defaultColor;
    box-shadow:0px 0px 10px $defaultColor;
}
  /deep/ .van-cell {
    padding: 0.1rem 0.16rem !important;
  }
  .form_wrap {
    width: 100%;
    flex: 1;
    background: #f9f9f9;
    overflow-y: scroll;
    .form {
      color: $lightBlack;
      margin-bottom: 0.15rem;
      .mb20 {
        margin-bottom: 0.1rem;
      }
      /deep/ .van-field__control:disabled {
        color: $textColor;
        -webkit-text-fill-color: $textColor;
      }
      /deep/ .van-field__label {
        color: $lightBlack;
        font-size: 14px;
      }
      /deep/ .van-uploader {
        // .van-uploader__file {
        //   width: 0.6rem;
        //   height: 0.6rem;
        //   margin: 0;
        //   position: absolute;
        //   background: transparent;
        //   .van-uploader__upload-icon {
        //     display: none;
        //   }
        // }
        // .van-uploader__upload {
        //   width: 0.6rem;
        //   height: 0.6rem;
        //   margin: 0;
        //   position: absolute;
        //   background: transparent;
        //   .van-uploader__upload-icon {
        //     display: none;
        //   }
        // }
        .van-uploader__preview-image {
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
        }
      }
      /deep/ .van-uploader__preview {
        margin: 0;
      }

      .btn_box {
        margin: 0.4rem 0.15rem 0.2rem;
      }
      .avatar {
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: transparent;
      }
      .avatar_box {
        padding: 0.05rem 0;
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
        -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
        /deep/ .van-field__control--custom {
          justify-content: flex-end;
          .van-image {
            width: 0.6rem;
            height: 0.6rem;
            img {
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .relationship_pop {
    .popup_title {
      display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
      -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
      width: 100%;
      height: 0.44rem;
      -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
      padding: 0 0.12rem;
      .title_left {
        color: $lightBlack;
      }
      .title_center {
      }
      .title_right {
        color: rgb(68, 83, 152);
      }
    }
    /deep/ .van-tab--active {
      background: $defaultColor;
      color: #fff;
    }
    /deep/ .van-tabs__line {
      display: none;
    }
    .relationship_list {
      display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
      flex-wrap: wrap;
      .relationship_item {
        border: #ddd solid 0.5px;
        border-radius: 0.05rem;
        margin: 0.05rem;
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
        -moz-justify-content: center;
-webkit-justify-content: center;
justify-content: center;
        height: 0.3rem;
        color: #333;
        background-color: #fff;
        padding: 0 0.15rem;
      }
      .relationship_item.active {
        background: $defaultColor;
        color: #fff;
      }
    }
  }
}
</style>
