<template>
  <div class="serviceItems wh100">
    <navBar title="选择服务项目" :leftArrowFlag="true" />
    <div class="content">
      <div class="title">
        <span>祭扫服务费用</span><span class="red">￥{{ sacrificePrice }}</span>
      </div>
      <div class="title"><span>其他服务项目：</span></div>
      <ul class="serviceItems_list" v-if="list.length > 0">
        <li class="service_item" v-for="(item, index) in list" :key="index">
          <div class="check_box">
            <van-checkbox :name="item.id" @click="itemCheckedChange" v-model="item.checked" />
          </div>
          <div class="img_box">
            <img :src="item.imgUrl1" alt="" />
          </div>
          <div class="item_info">
            <h3>{{ item.title }}</h3>
            <h5>{{ item.sacrificePrice }}</h5>
            <div class="info_bottom">
              <div class="price">
                <span>￥{{ item.price }}</span>
              </div>
              <div class="sales">
                <van-stepper @change="itemNumChange" v-model="item.sales" theme="round" button-size="22"
                  disable-input />
              </div>
            </div>
          </div>
        </li>
      </ul>
      <van-empty v-else description="暂无服务项目" />
    </div>
    <div class="footer">
      <!-- <div class="footer_left">
                <span>合计：￥{{totalPrice}}</span>
            </div>
            <div class="footer_right">
                <van-button round type="info">提交订单</van-button>
            </div> -->
      <van-submit-bar :price="totalPrice" button-text="提交订单" @submit="onSubmit">
        <van-checkbox @click="allCheckedChange" v-model="allChecked">全选</van-checkbox>
      </van-submit-bar>
    </div>
  </div>
</template>
<script>
import navBar from "@/components/navBar";
import { replaceSacrifice_commodities } from "@/api/appointment/serviceItems";
export default {
  data() {
    return {
      sacrificePrice: 0,
      list: [

      ],
      allChecked: false,
      form: {},
    };
  },
  components: {
    navBar,
  },
  computed: {
    totalPrice() {
      let total = 0;
      this.list.forEach((item) => {
        if (item.checked) {
          total += item.price * item.sales;
        }
      });
      return total * 100 + this.sacrificePrice * 100;
    },
  },
  watch: {
    list: {
      handler(newValue, oldValue) {
        // console.log(newValue,oldValue,'0000')
        let flag = newValue.every((item) => item.checked == true);
        if (flag) {
          this.allChecked = true;
        } else {
          this.allChecked = false;
        }
      },
      deep: true,
    },
  },
  created() {
    // 将保存中的数据加载到页面
    if (JSON.parse(localStorage.getItem("actingSacrificeSweep"))) {
      this.form = JSON.parse(localStorage.getItem("actingSacrificeSweep"));
    }
    // 获取商品列表
    this.onload();
  },
  methods: {
    //   获取商品列表
    async onload() {
      try {
        const res = await replaceSacrifice_commodities({});
        console.log(res, 200);
        if (res.code === "2000") {
          this.sacrificePrice = res.data.sacrificePrice;
          if (res.data.commoditiesList && res.data.commoditiesList.length > 0) {
            let lists = JSON.parse(JSON.stringify(res.data.commoditiesList));
            let haveList = this.form.commoditiesReqList;
            lists.forEach((item) => {
              item.sales = 1;
              item.checked = false;
              haveList.forEach((el) => {
                if (el.id == item.id) {
                  item.sales = el.sales;
                  item.checked = el.checked;
                }
              });
            });
            this.list = lists;
          } else {
            this.list = []
          }

          return;
        }
        this.$toast.fail(res.msg || "商品获取失败");
      } catch (err) {
        console.log(err, 404);
        this.$toast.fail(err.msg);
      }
    },
    //每项数量改变
    itemNumChange() {
      // this.totalPrice()
    },
    //每项选择改变
    // itemCheckedChange() {},
    //点击全选、反选
    allCheckedChange() {
      this.list.forEach((item) => (item.checked = this.allChecked));
    },
    //每项选择反选影响全选反选
    itemCheckedChange() {
      let flag = this.list.every((item) => item.checked == true);
      if (flag) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
    },
    onSubmit() {
      
      let selectItmes = this.list.filter((item) => item.checked);
      this.form.commoditiesReqList = [];
      selectItmes.forEach((item) => {
        let {
          id, //商品项id
          price, //现价
          title, //项目名称
          sales, //销售数量
          imgUrl1,
          checked,
        } = item;
        this.form.commoditiesReqList.push({
          id,
          price,
          imgUrl1,
          title,
          sales,
          checked,
        });
        // this.form.commoditiesReqList.push(item);
      });
      localStorage.setItem("actingSacrificeSweep", JSON.stringify(this.form));
      this.$router.push({
        path: "/appointment/order",
        query: {
          sacrificePrice: this.sacrificePrice * 100,
          total: this.totalPrice,
          appointmentCardNo: this.$route.query.appointmentCardNo
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.serviceItems {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;

  .content {
    flex: 1;
    overflow-y: scroll;
    padding: 0.15rem;

    .title {
      font-size: 15px;
      line-height: 0.21rem;
      margin-bottom: 0.25rem;

      .red {
        color: #e7643c;
      }
    }

    .serviceItems_list {
      .service_item {
        padding: 0.13rem 0.1rem;
        box-shadow: 0px 0px 0.1rem 0.05rem #f0f0f0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        border-radius: 0.05rem;
        margin-bottom: 0.15rem;

        /deep/ .van-checkbox__icon--checked .van-icon {
          background-color: $defaultColor;
          border-color: $defaultColor;
        }

        .check_box {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
          margin-right: 0.1rem;
        }

        .img_box {
          width: 0.81rem;
          height: 0.81rem;
          box-shadow: 0px 0px 0.1rem 0.05rem #f0f0f0;
          margin-right: 0.1rem;
          border-radius: 0.05rem;

          img {
            width: 100%;
            height: 100%;
            border-radius: 0.05rem;
          }
        }

        .item_info {
          flex: 1;

          h3 {
            font-size: 15px;
            line-height: 0.21rem;
            margin-top: 0.06rem;
            margin-bottom: 0.06rem;
          }

          h5 {
            font-size: 12px;
            color: $lightBlack;
            line-height: 0.17rem;
            margin-bottom: 0.08rem;
          }

          .info_bottom {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -moz-justify-content: space-between;
            -webkit-justify-content: space-between;
            justify-content: space-between;

            .price {
              color: #e7643c;
              font-size: 12px;
              font-weight: 600;
              line-height: 0.25rem;
            }

            /deep/ .van-stepper--round .van-stepper__plus {
              background-color: $defaultColor;
            }

            /deep/ .van-stepper--round .van-stepper__minus {
              color: $defaultColor;
              border: 1px solid $defaultColor;
            }
          }
        }
      }
    }
  }

  .footer {
    width: 100%;
    background: #f9f9f9;
    height: 0.5rem;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
    -moz-justify-content: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 0 0.15rem;

    /deep/ .van-submit-bar {
      background: transparent;
    }

    /deep/ .van-checkbox__icon--checked .van-icon {
      background-color: $defaultColor;
      border-color: $defaultColor;
    }

    /deep/ .van-button--info {
      width: 1.1rem;
      height: 0.4rem;
      background: linear-gradient(to right, #ff6034, #ee0a24);
      border: none;
    }
  }
}
</style>
