<template>
  <!-- 纪念馆 -->
  <div class="worship wh100" :style="{ 'background-image': 'url(' + bg + ')' }">
    <navBar :title="title + '的纪念馆'" :leftArrowFlag="true" @clickNavBarRight="goHome"
      ><i class="iconfont icon-shouyex"></i>
    </navBar>
    <div class="main">
      <div class="content_wrap">
        <!-- 头部 -->
        <!-- <div class="content_top lights">
          <div
            :class="[item.flag ? 'active' : '', 'light_item']"
            v-for="(item, index) in lights"
            :key="index"
            @click="goLighting(item)"
          >
            <img class="light" :src="item.url" alt="" />
          </div>
        </div> -->
        <!-- tab -->
        <div class="tabs_wrap">
          <div class="tabs_item" @click="goIndex"><span>首页</span></div>
          <div class="tabs_item active"><span>祭拜</span></div>
        </div>
        <!-- 主要内容区 -->
        <div class="content_main">
          <!-- 左侧 -->
          <div class="main_left">
            <div :class="[autoPlay ? 'run' : '', 'music_box']" @click="closeMusic">
              <i class="iconfont icon-yinle"></i>
            </div>
            <!-- <div class="left_item" @click="changeYunBg">
              <span>云墓图片</span>
            </div>
            <div class="left_item" @click="goShiMuPicList">
              <span>实墓图片</span>
            </div> -->
            <!-- <van-uploader
              capture="camera"
              v-model="fileList"
              :after-read="uploadImgAfter"
            >
              <div class="left_item">
                <span>实墓图片</span>
              </div>
            </van-uploader> -->
          </div>
          <!-- 中部 -->
          <div class="main_center">
            <div class="deads" v-if="backBgType == 'worship'">
              <div class="dead_item" v-for="(item, index) in detail.deads" :key="index">
                <div class="avatar_box">
                  <img class="avatar" v-if="item.avatar" :src="item.avatar" alt="" />
                  <img class="avatar" v-else :src="deadImg" alt="" />
                </div>
                <h3>{{ item.name }}</h3>
                <h5>{{ item.date }}</h5>
              </div>
            </div>
          </div>
          <!-- 右侧 -->
          <div class="main_right">
            <!-- <div
              :class="[autoPlay ? 'run' : '', 'right_item', 'music_box']"
              @click="closeMusic"
            >
              <i class="iconfont icon-yinle"></i>
            </div> -->
            <div class="right_item" @click="showWorshipPopup = true">
              <i class="iconfont icon-jibaitai"></i>
              <span>供奉</span>
            </div>
            <!-- <div class="right_item" @click="goLighting">
              <i class="iconfont icon-ai251"></i>
              <span>点灯</span>
            </div> -->
          </div>
          <!-- 左侧 底部-->
          <div class="main_right main_bottom" v-if="actionAuth">
            <div class="right_item" @click="goSetting">
              <i class="iconfont icon-shezhi1"></i>
              <span>设置</span>
            </div>
          </div>
        </div>
        <!-- 贡品展示区 -->
        <div class="offering offering1">
          <div class="offering_item" v-for="(item, index) in gongpin1" :key="index">
            <img class="offering_img" :src="item.img" alt="" />
          </div>
        </div>
        <div class="offering offering2">
          <div class="offering_item" v-for="(item, index) in gongpin2" :key="index">
            <img class="offering_img" :src="item.img" alt="" />
          </div>
        </div>
        <div class="offering offering3">
          <div class="offering_item" v-for="(item, index) in gongpin3" :key="index">
            <img class="offering_img" :src="item.img" alt="" />
          </div>
        </div>
      </div>
      <div class="footer">
        <van-field
          v-model="message"
          center
          clearable
          placeholder="留言寄哀思..."
          show-word-limit
        />
        <div class="sub_btn" @click="sendMessage">留言</div>
      </div>
    </div>
    <audio
      ref="music"
      autoplay
      preload="auto"
      loop
      v-if="detail.music"
      id="music"
      :src="detail.music"
    ></audio>
    <!-- 祭拜弹框 -->
    <van-popup
      :lock-scroll="false"
      class="popup_box"
      v-model="showWorshipPopup"
      position="bottom"
      :style="{ height: '55%' }"
    >
      <div class="popup_title"><span>请选择祭拜品</span></div>
      <div class="popup_content">
        <div class="left" ref="left">
          <ul class="content">
            <li
              v-for="(item, index) in left"
              :key="item"
              :class="{ current: currentIndex == index }"
              @click="selectItem(index, $event)"
            >
              <span class="left-item">{{ item }}</span>
            </li>
          </ul>
        </div>
        <div class="right" ref="right">
          <ul class="content">
            <li
              class="right-item right-item-hook"
              v-for="(item, index) in right"
              :key="index"
            >
              <h2>
                <span>{{ item.name }}</span>
              </h2>
              <ul>
                <li v-for="child in item.content" :key="child.id">
                  <div class="img_box">
                    <img :src="child.img" alt="" />
                  </div>
                  <div class="child_info">
                    <div class="info_left">
                      <h3>{{ child.name }}</h3>
                      <h4>
                        价格：<span>{{
                          child.price ? child.price / 100 + "元" : "免费"
                        }}</span>
                      </h4>
                    </div>
                    <div class="info_right" @click="gongfeng(child)">
                      <span>立即供奉</span>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="popup_bottom">
        <div class="bottom_left">
          <span>匿名赠送</span>
          <van-switch
            active-color="$defaultColor"
            inactive-color="#A4A4A4"
            v-model="isAnonymous"
          />
        </div>
        <div class="bottom_right" @click="goRecharge"><span>去充值</span></div>
      </div> -->
    </van-popup>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import BScroll from "better-scroll";
import {
  getMemorialAllInfo,
  oblationList,
  oblationSave,
  noteOperation,
  fetchOfferingList,
  fetchWorshipBg,
  fetchMediaAuth,
  fetchDefaultWorshipBg,
} from "@/api/memorialHall";
export default {
  data() {
    return {
      id: "",
      show: false, //
      actionAuth: false, //操作权限
      deadImg: require("@/assets/imgs/deadImg.png"),
      title: "",
      message: "",
      backBgType: "", //后台返回云墓实墓背景类型worship祭拜/grave墓穴
      bgType: "", //grave实墓 云墓为空
      yunBg: require("@/assets/imgs/yunmu.png"),
      autoPlay: true, //
      fileList: [],
      bg: "",
      lights: [],
      detail: {},
      classOption: {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
      showWorshipPopup: false, //祭拜底部弹框
      left: [],
      right: [],
      listHeight: [],
      scrollY: 0, //实时获取当前y轴的高度
      clickEvent: false,
      lefts: null,
      rights: null,
      offeringList: [], //供奉商品列表
      gongpin1: [], //蜡烛香
      gongpin2: [], //祭品
      gongpin3: [], //鲜花
    };
  },
  components: {
    navBar,
  },
  created() {
    this.id = this.$route.params.id;
    this.init();
    this.getOfferingList();
    if (this.$route.query.type) {
      this.bgType = this.$route.query.type;
    } else {
      this.bgType = "";
    }
    //获取背景
    this.getDefaultBg();
    this.getUserAuth(this.$route.params.id);
  },
  mounted() {
    // 播放音乐
    document.addEventListener("DOMContentLoaded", function () {
      this.audioAutoPlay();
    });
  },
  watch: {
    showWorshipPopup(newValue, oldValue) {
      if (newValue) {
        this.$nextTick(() => {
          this._initScroll();
          this._getHeight();
        });
      }
    },
  },
  computed: {
    //
    currentIndex() {
      const index = this.listHeight.findIndex((item, index) => {
        return (
          this.scrollY >= this.listHeight[index] &&
          this.scrollY < this.listHeight[index + 1]
        );
      });

      return index > 0 ? index : 0;
    },
  },
  methods: {
    //用户权限
    async getUserAuth(id) {
      let params = {
        id,
      };
      let res = await fetchMediaAuth(params);
      console.log(res, "操作全先");
      if (res.code != "2000") {
        this.$toast.fail(res.msg || "请求数据失败");
        return;
      } else {
        this.actionAuth = res.data;
      }
    },
    //初始化默认背景
    async getDefaultBg() {
      let params = {
        memoriaId: this.id,
      };
      let res = await fetchDefaultWorshipBg(params);
      console.log(res, "默认类型");
      if (res.code == "2000") {
        this.bg = res.data.sacrificeImg ? JSON.stringify(res.data.sacrificeImg) : this.yunBg;
        this.backBgType = res.data.type;
      }
    },
    //获取背景
    async getBg() {
      let params = {
        sourceType: this.bgType,
        memoriaId: this.id,
      };
      let res = await fetchWorshipBg(params);
      console.log(res, "背景");
      if (res.code == "2000") {
        this.bg = res.data.sacrificeImg ? JSON.stringify(res.data.sacrificeImg) : this.yunBg;
        this.backBgType = res.data.type;
      }
    },
    //去实墓列表
    goShiMuPicList() {
      if (!this.actionAuth) {
        this.$toast.fail("暂无权限");
        return;
      }
      this.$router.push(`/memorialHall/realTombPicList/${this.id}`);
    },
    //获取供奉商品列表
    async getOfferingList() {
      let params = {
        id: this.id,
      };
      let res = await fetchOfferingList(params);
      console.log(res, "贡品");
      if (res.code != "2000") {
        this.$toast.fail(res.msg || "数据请求失败");
      } else {
        if (res.data) {
          this.gongpin1 = [];
          //香
          if (res.data.Xiang && res.data.Xiang.length > 0) {
            this.gongpin1.push(res.data.Xiang[0]);
          }
          //蜡烛
          // if (res.data.LaZhu && res.data.LaZhu.length > 0) {
          //   this.gongpin1.push(res.data.LaZhu[0]);
          //   this.gongpin1.unshift(res.data.LaZhu[0]);
          // }

          // if (res.data.JiPin && res.data.JiPin.length > 0) {
          //   this.gongpin2 = res.data.JiPin.slice(0, 3);
          // }
           if (res.data['LaZhu,ZhaZhu'] && res.data['LaZhu,ZhaZhu'].length > 0) {
            this.gongpin2=res.data['LaZhu,ZhaZhu'].slice(0, 3);
            
          }

          // if (res.data.JiPin && res.data.JiPin.length > 0) {
          //   this.gongpin2 = res.data.JiPin.slice(0, 3);
          // }
          if (res.data.XianHua && res.data.XianHua.length > 0) {
            this.gongpin3 = res.data.XianHua.slice(0, 5);
          }
        }

        //console.log(this.gongpin1, this.gongpin2, this.gongpin3, "02222");
        // if (res.data && res.data.urls) {
        //   this.offeringList = res.data.urls;
        // }
      }
    },
    //获取数据转化为页内所需格式
    init() {
      //获取纪念馆所有信息
      getMemorialAllInfo({ id: this.id }).then((res) => {
        let { data } = res;
        let { deaderInfos, memorialInfo, memorialSpuImgs, commodities } = data;
        //将值转换为当前页面值
        this.title = memorialInfo.memorialName;
        this.detail.deads = deaderInfos.map((el) => {
          return {
            id: el.idCardNo,
            name: el.deadName,
            avatar: el.deadPortraitUrl,
            date: el.birthday.slice(0, 4) + "-" + el.deathday.slice(0, 4),
          };
        });
        for (let i = 0; i < memorialSpuImgs.length; i++) {
          let el = memorialSpuImgs[i];
          if (el.specificType === "backgroundImg") {
            //背景图
          } else if (el.specificType === "sacrificeImg") {
            //祭祀图
            //this.bg = JSON.stringify(el.fileUrl);
            this.yunBg = JSON.stringify(el.fileUrl);
          } else if (el.specificType === "backgroundMp3") {
            //背景音乐
            this.detail.music = el.fileUrl;
          }
        }
        this.lights = commodities.map((el) => {
          if (el.flag) {
            return {
              id: el.id,
              url: el.imgUrl2,
              flag: el.flag,
            };
          } else {
            return {
              id: el.id,
              url: el.imgUrl1,
              flag: el.flag,
            };
          }
        });
        console.log(commodities);
      });
      //获取供奉相关信息
      oblationList({}).then((res) => {
        console.log(res, "供奉商品列表");
        let { data } = res;
        this.left = [];
        this.right = data.map((el) => {
          let name = el.title;
          this.left.push(name);
          let content = el.commoditiesList.map((item) => {
            let { title, img, id, price } = item;
            return {
              id,
              name: title,
              price,
              img,
            };
          });
          return {
            name,
            content,
          };
        });

        console.log(data);
      });
    },
    //回首页
    goHome() {
      this.$router.push("/");
    },
    //去纪念馆首页
    goIndex() {
      this.$router.push(`/memorialHall/${this.$route.params.id}`);
    },
    //去点灯页面
    goLighting(item) {
      console.log(item);
      this.$router.push({
        path: `/memorialHall/lighting/${this.id}`,
        query: {
          active: item.id,
        },
      });
    },
    //音乐播放
    audioAutoPlay() {
      this.$nextTick(() => {
        let audio = this.$refs.music;
        audio.play();
        document.addEventListener(
          "WeixinJSBridgeReady",
          function () {
            audio.play();
          },
          false
        );
      });
    },
    //音乐暂停
    audioAutoPause() {
      let audio = this.$refs.music;
      audio.pause();
      document.addEventListener(
        "WeixinJSBridgeReady",
        function () {
          audio.pause();
        },
        false
      );
    },
    //音乐暂停
    closeMusic() {
      this.autoPlay = !this.autoPlay;
      if (this.autoPlay) {
        //播放
        this.audioAutoPlay();
      } else {
        //暂停
        this.audioAutoPause();
      }
    },
    //留言
    sendMessage() {
      if (!this.message) {
        return;
      }
      let params = {
        memoriaId: this.id,
        contentType: "ly",
        content: this.message,
        userId: localStorage.getItem("userId") || "",
      };
      noteOperation(params).then(() => {
        this.message = "";
        this.$toast.success("已留言");
      });
    },
    //去充值
    goRecharge() {
      this.$router.push("/my/recharge");
    },
    //初始化betterscroll
    _initScroll() {
      //better-scroll的实现原理是监听了touchStart,touchend事件，所以阻止了默认的事件（preventDefault）
      //所以在这里做点击的话，需要在初始化的时候传递属性click,派发一个点击事件
      //在pc网页浏览模式下，点击事件是不会阻止的，所以可能会出现2次事件，所以为了避免2次，可以在绑定事件的时候把$event传递过去
      if (!this.lefts) {
        this.lefts = new BScroll(this.$refs.left, {
          click: true,
        });
      } else {
        this.lefts.refresh();
      }
      if (!this.rights) {
        this.rights = new BScroll(this.$refs.right, {
          click: true,
          probeType: 3, //探针的效果，实时获取滚动高度
        });
      } else {
        this.rights.refresh();
      }
      //rights这个对象监听事件，实时获取位置pos.y
      this.rights.on("scroll", (pos) => {
        this.scrollY = Math.abs(Math.round(pos.y));
      });
    },
    //获取滚动区域高度数组
    _getHeight() {
      let rightItems = this.$refs.right.getElementsByClassName("right-item-hook");
      let height = 0;
      this.listHeight.push(height);
      for (let i = 0; i < rightItems.length; i++) {
        let item = rightItems[i];
        height += item.clientHeight;
        this.listHeight.push(height);
      }
      console.log(this.listHeight, "98989");
    },
    //点击左侧分类
    selectItem(index, event) {
      this.clickEvent = true;
      //在better-scroll的派发事件的event和普通浏览器的点击事件event有个属性区别_constructed
      //浏览器原生点击事件没有_constructed所以当时浏览器监听到该属性的时候return掉
      if (!event._constructed) {
        return;
      } else {
        let rightItems = this.$refs.right.getElementsByClassName("right-item-hook");
        let el = rightItems[index];
        this.rights.scrollToElement(el, 300);
      }
    },
    //供奉
    gongfeng(good) {
      let params = {
        memoriaId: this.id,
        sacrificeName: good.name,
        sacrificeNum: 1,
        sacrificeId: good.id,
        sacrificePrice: good.price,
        userId: localStorage.getItem("userId") || "",
      };
      oblationSave(params).then((res) => {
        this.showWorshipPopup = false;
        this.$toast({
          type: "success",
          message: "供奉成功",
          onClose: () => {
            this.init();
            this.getOfferingList();
          },
        });
      });
    },
    //纪念馆设置
    goSetting() {
      this.$router.push({
        path: `/memorialHall/memorialHallSetting/${this.id}`,
        query: {
          isDelete: "no",
        },
      });
    },
    //切换云墓背景
    changeYunBg() {
      if (!this.actionAuth) {
        this.$toast.fail("暂无权限");
        return;
      }
      if (this.backBgType == "worship") {
        this.$toast("已是云墓背景");
      } else {
        this.bgType = "";
        this.getBg();
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("DOMContentLoaded", () => {});
  },
};
</script>

<style lang="scss" scoped>
.worship {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: 3.75rem 100% !important;
  position: relative;
  /deep/ .van-uploader__preview {
    display: none;
  }
  .main {
    flex: 1;
    padding: 0.15rem 0.12rem 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    .content_wrap {
      flex: 1;
      // 头部
      .content_top {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center;
        margin-bottom: 0.15rem;
        min-height: 0.54rem;
        .light_item {
          width: 0.54rem;
          height: 0.54rem;
          margin: 0 0.075rem;
          background: #a6a6a6;
          border-radius: 50%;
          overflow: hidden;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
          -moz-justify-content: center;
          -webkit-justify-content: center;
          justify-content: center;
          img {
            height: 100%;
          }
        }
        .light_item.active {
          background: #996112;
        }
      }
      //tab
      .tabs_wrap {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        width: 100%;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        padding: 0 1.05rem;
        -moz-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        margin-bottom: 0.25rem;
        .tabs_item {
          width: 0.65rem;
          height: 0.3rem;
          background: #efefef;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -moz-justify-content: center;
          -webkit-justify-content: center;
          justify-content: center;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
          border-radius: 0.3rem;
          span {
            line-height: 0.3rem;
            font-size: 16px;
            color: $lightBlack;
          }
        }
        .tabs_item.active {
          background: $defaultColor;
          span {
            color: #fff;
          }
        }
      }
      //主要内容区
      .content_main {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        margin-bottom: 0.35rem;
        // 左侧
        .main_left {
          .left_item {
            width: 0.55rem;
            height: 0.28rem;
            background: $defaultColor;
            color: #fff;
            border-radius: 0.08rem;
            text-align: center;
            line-height: 0.28rem;
            font-size: 12px;
            margin-bottom: 0.2rem;
          }
          .music_box {
            margin-bottom: 0.25rem;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -moz-flex-direction: column;
            -webkit-flex-direction: column;
            flex-direction: column;
            -moz-justify-content: center;
            -webkit-justify-content: center;
            justify-content: center;
            -moz-align-items: center;
            -webkit-align-items: center;
            align-items: center;
            color: #fff;
            background: $defaultColor;
            width: 0.55rem;
            height: 0.55rem;
            border-radius: 0.08rem;
            i {
              font-size: 23px;
              margin-bottom: 0.04rem;
            }
            span {
              font-size: 12px;
              line-height: 0.17rem;
            }
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.4);
          }
          .run {
            animation: turn 10s linear infinite;
          }
        }

        .main_right {
          position: fixed;
          right: 0.1rem;
          bottom: 0.4rem;
          .right_item {
            margin-bottom: 0.15rem;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -moz-flex-direction: column;
            -webkit-flex-direction: column;
            flex-direction: column;
            -moz-justify-content: center;
            -webkit-justify-content: center;
            justify-content: center;
            -moz-align-items: center;
            -webkit-align-items: center;
            align-items: center;
            color: #fff;
            background: $defaultColor;
            width: 0.55rem;
            height: 0.55rem;
            border-radius: 0.08rem;
            i {
              font-size: 23px;
              margin-bottom: 0.04rem;
            }
            span {
              font-size: 12px;
              line-height: 0.17rem;
            }
          }
          .music_box {
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.4);
          }
          .right_item.run {
            animation: turn 10s linear infinite;
          }
        }
        .main_bottom {
          display: inline-block;
          left: 0.1rem;
          width: 0.55rem;
        }
      }
      //贡品区域
      .offering {
        position: fixed;
        left: 50%;

        transform: translateX(-50%);
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center;
        .offering_item {
          width: 0.4rem;
          height: 0.4rem;
          // margin:0 0.1rem;
          .offering_img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .offering1 {
        bottom: 35%;
      }
      .offering2 {
        bottom: 26%;
      }
      .offering3 {
        bottom: 10%;
      }
    }
    .footer {
      width: 100%;
      height: 0.45rem;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      padding: 0.04rem 0;
      /deep/ .van-cell {
        padding: 0.1rem !important;
        border-radius: 0.05rem;
      }
      .sub_btn {
        width: 0.6rem;
        height: 0.37rem;
        margin-left: 0.14rem;
        background: $defaultColor;
        color: #fff;
        border-radius: 0.05rem;
        text-align: center;
        line-height: 0.37rem;
      }
    }
    .main_center {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-flex-direction: column;
      -webkit-flex-direction: column;
      flex-direction: column;
      -moz-justify-content: center;
      -webkit-justify-content: center;
      justify-content: center;
      .deads {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        justify-content: center;
        margin-bottom: 0.15rem;
        .dead_item {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -moz-flex-direction: column;
          -webkit-flex-direction: column;
          flex-direction: column;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
          color: #000;
          margin: 0 0.05rem;
          .avatar_box {
            width: 0.6rem;
            height: 0.75rem;
            margin-bottom: 0.1rem;
            padding: 0.05rem;
            background: url("../../assets/imgs/border.png") no-repeat top center;
            background-size: 100% 100%;
            img {
              width: 100%;
              height: 100%;
            }
          }
          h3 {
            font-size: 12px;
            line-height: 0.25rem;
          }
          h5 {
            font-size: 11px;
            line-height: 0.21rem;
          }
        }
      }
      .intr {
        width: 2rem;
        p {
          font-size: 12px;
          line-height: 0.2rem;
          color: $lightBlack;
        }
      }
    }
  }
  //祭拜弹框
  .popup_box {
    background: #f1f1f1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    .popup_title {
      width: 100%;
      height: 0.44rem;
      background: $defaultColor;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-align-items: center;
      -webkit-align-items: center;
      align-items: center;
      color: #fff;
      padding-left: 0.15rem;
      font-size: 16px;
    }
    .popup_content {
      flex: 1;
      // margin-bottom: 0.12rem;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      overflow: hidden;
      .left {
        flex: 0 0 1rem;
        background-color: #fff;
        margin-right: 0.1rem;
        li {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -moz-align-items: center;
          -webkit-align-items: center;
          align-items: center;
          -moz-justify-content: center;
          -webkit-justify-content: center;
          justify-content: center;
          width: 100%;
          height: 0.5rem;
          position: relative;
        }
        .current {
          background: #f1f1f1;
          color: $defaultColor;
        }
        .current::after {
          content: "";
          width: 4px;
          height: 15px;
          border-radius: 5px 0 0 5px;
          position: absolute;
          background-color: $defaultColor;
          top: 0;
          right: 0px;
          bottom: 0;
          margin: auto;
        }
      }

      .right {
        flex: 1;

        .right-item {
          margin-top: 0.1rem;
          background-color: #fff;
          h2 {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -moz-align-items: center;
            -webkit-align-items: center;
            align-items: center;
            padding-left: 0.15rem;
            height: 0.5rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            span {
              margin-left: 0.05rem;
            }
          }
          h2::before {
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: $defaultColor;
          }
          li {
            width: 100%;
            padding: 0.15rem 0.1rem 0.15rem 0.15rem;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -moz-align-items: center;
            -webkit-align-items: center;
            align-items: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            .img_box {
              width: 0.6rem;
              height: 0.6rem;
              background: #efefef;
              border-radius: 0.03rem;
              padding: 0.1rem;
              margin-right: 0.08rem;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .child_info {
              flex: 1;
              display: -webkit-box;
              display: -moz-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -moz-align-items: center;
              -webkit-align-items: center;
              align-items: center;
              -moz-justify-content: space-between;
              -webkit-justify-content: space-between;
              justify-content: space-between;
              .info_left {
                h3 {
                  font-size: 14px;
                  line-height: 0.2rem;
                  margin-bottom: 0.05rem;
                }
                h4 {
                  display: -webkit-box;
                  display: -moz-box;
                  display: -ms-flexbox;
                  display: -webkit-flex;
                  display: flex;
                  -moz-align-items: center;
                  -webkit-align-items: center;
                  align-items: center;
                  font-size: 12px;
                  color: $lightBlack;
                  span {
                    color: $defaultColor;
                  }
                }
              }
              .info_right {
                width: 0.72rem;
                height: 0.3rem;
                color: #fff;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -moz-align-items: center;
                -webkit-align-items: center;
                align-items: center;
                -moz-justify-content: center;
                -webkit-justify-content: center;
                justify-content: center;
                background: $defaultColor;
                border-radius: 0.06rem;
              }
            }
          }
        }
      }
    }
    .popup_bottom {
      width: 100%;
      background: #ffff;
      height: 0.44rem;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      padding: 0 0.12rem;
      -moz-align-items: center;
      -webkit-align-items: center;
      align-items: center;
      -moz-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      .bottom_left {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        span {
          margin-right: 0.08rem;
        }
        /deep/ .van-switch {
          width: 0.5rem;
          height: 0.25rem;
          border-radius: 0.25rem;
          .van-switch__node {
            width: 0.24rem;
            height: 0.24rem;
          }
        }
        /deep/ .van-switch--on .van-switch__node {
          transform: translateX(0.25rem);
        }
      }
    }
  }
  //点灯成功
}
@keyframes turn {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(72deg);
  }

  40% {
    transform: rotate(144deg);
  }

  60% {
    transform: rotate(216deg);
  }

  80% {
    transform: rotate(288deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
