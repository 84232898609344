<template>
  <div class="memorialHallList wh100">
    <navBar title="纪念馆列表" :leftArrowFlag="true" />
    <div class="list_content">
      <vantList
        :list="list"
        :loading="loading"
        :finished="finished"
        :refreshing="refreshing"
        @getList="getList"
        @refresh="refresh"
      >
        <div class="list" v-for="(item, index) in list" :key="index">
          <homeItem :item="item"></homeItem>
        </div>
      </vantList>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import vantList from "@/components/list";
import homeItem from "@/components/memorialHallItem/homeItem";
import { memorialInfo_search } from "@/api/home/index";

export default {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      pageSize: 10,
      currentPage: 1,
      list: [],
    };
  },
  components: {
    navBar,
    vantList,
    homeItem,
  },
  mounted() {
    this.getList();
  },
  methods: {
    //请求数据
    async getList() {
      this.loading = true;
      const { currentPage, pageSize } = this;
      let params = {
        currentPage,
        pageSize,
      };
      let res = await memorialInfo_search(params);
      
      console.log(res, "9999");
      if (res.code != "2000"){
        this.$toast.fail(res.msg ||  "数据请求失败");
        return 
      }
      let list = JSON.parse(JSON.stringify(res.data));
      list.forEach((item) => {
        item.memorialInfoId = item.id;
        // 在世时间处理
        item.life = item.segDates ? item.segDates[0] : "";
        // 图片
        item.avatar = item.deadPortraitUrls
          ? item.deadPortraitUrls[0]
          : require("@/assets/imgs/deadImg.png");
        // 离世时长;
        item.leave = item.deathYears ? item.deathYears[0] : "";
        // 纪念馆名称
        // item.name = item.deadNames ? item.deadNames[0] : "";
         let name='';
            item.deadNames.forEach(item=>{
              name+=item+','
            })
            item.name = name.substring(0,name.length-1);
      });
      this.list = [...this.list, ...list];
      //根据总条数处理
      if (pageSize > list.length) {
        this.finished = true;
      } else {
        this.currentPage++;
        this.finished = false;
      }
      this.loading = false;
      this.refreshing = false;
    },
    //下拉刷新
    refresh() {
      this.refreshing = true;
      // 清空列表数据
      this.currentPage = 1;
      this.list = [];
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.memorialHallList {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  .list_content {
    flex: 1;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 0.1rem;
    /deep/ .van-list {
      width: 100%;
    }
  }
}
</style>
