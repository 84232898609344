<template>
    <div class="writeBless wh100">
         <navBar title="填写愿望" :leftArrowFlag="true" />
         <div class="content">
             <van-form @submit="onSubmit" class="form">
                <van-field
                    v-model="username"
                    name="username"
                    placeholder="请输入您的姓名"
                    :rules="[{ required: true, message: '请输入您的姓名' }]"
                />
                <van-field
                    v-model="bless"
                    name="bless"
                    rows="5"
                    autosize
                    type="textarea"
                    placeholder="请输入您要祈福的内容"
                     maxlength="100"
                    show-word-limit
                    :rules="[{ required: true, message: '请输入您要祈福的内容' }]"
                />
                <div class="des">
                    <p>真诚许愿，祈福内容应真实，言论恰当。支付前请确认自己的信息输入是否有误，确认后不可更改</p>
                </div>
                <div style="margin: 16px;">
                    <van-button round block type="primary" native-type="submit">立即许愿</van-button>
                </div>
                </van-form>
        </div>
    </div>
</template>

<script>
import navBar from "@/components/navBar";
export default {
  data() {
    return {
        id:'',
        username: '',
      bless: '',
    };
  },
  components: {
    navBar,
  },
  created(){
     this.id=this.$route.params.id;
     console.log(this.id)
  },
  methods:{
     onSubmit(values) {
      console.log('submit', values);
    },
  }
};
</script>

<style lang="scss" scoped>
.writeBless {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  .content {
    flex: 1;
    padding:0.15rem;
    .form{
        /deep/ .van-cell::after{
            border:none;
        }
        /deep/ .van-field{
            border:1px solid #ebedf0;
            margin-bottom: 0.15rem;
            border-radius: 0.05rem;
            padding: 0.1rem!important;
        }
        .des{
            margin:0.25rem 0 1.25rem;
            background: #D8F6E3;
            color: $defaultColor;
            padding:0.1rem 0.14rem;
            border-radius: 0.05rem;;
            p{
                font-size:12px;
                line-height: 0.21rem;
            }
        }
    }
  }
}
</style>