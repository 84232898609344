<template>
    <div class="selectMusic wh100">
         <navBar title="选择音乐" :leftArrowFlag="true"  /> 
         <div class="content">
             <!-- <van-radio-group v-model="selectMusicId" @change='musicChange'>
                <div class="content_item" v-for="(item,index) in musics" :key="index">
                    <div class="item_left">
                        <span>{{item.title}}</span>
                    </div>
                    <div class="item_right">
                        <van-radio :name="item.id"></van-radio>
                    </div>
                </div>
            </van-radio-group> -->
            <van-radio-group v-model="selectMusicId" @change='musicChange'>
            <van-cell-group>
                <van-cell :title="item.title" v-for="(item,index) in musics" :key="index" clickable @click="selectMusicId=item.id">
                    <template #right-icon>
                        <van-radio :name="item.id" />
                    </template>
                    </van-cell>
                </van-cell-group>
            </van-radio-group>
         </div>
    </div>
</template>

<script>
import navBar from "@/components/navBar";
import { memorialInfoDetailImgOrMp3,setMemorialInfo } from '@/api/memorialHall/memorialHallSetting'
export default {
    data() {
        return {
            id:'',
            selectMusicId:'',
            musics:[]
        }
    }, 
    components: {
        navBar,
    },
    created(){
        this.id=this.$route.params.id
        memorialInfoDetailImgOrMp3({id:this.id,fileType:'backgroundMp3'}).then(res=>{
          this.musics=res.data.map(el=>{
              let { flagId,id } = el
              if(flagId){
                  this.selectMusicId=id
              }
              return {
                  id:id,
                  url:el.fileUrl,
                  title:el.title
              }
          })
      })
    },
    methods:{
        musicChange(e){
            setMemorialInfo({
                memoriaSpuImgId:e,
                id:this.id,
                spuFileType:'backgroundMp3'
            }).then(()=>{
                this.$toast.success('修改成功');
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.selectMusic{
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
 /deep/ .van-radio__icon--checked .van-icon{
    background-color: $defaultColor;
  border-color: $defaultColor;
}
    .content{
        flex:1;
         padding:0.1rem;
        .content_item{
            display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
            -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
            -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
            padding:0.15rem 0;
            border-bottom: 1px solid #EDEDED;
            .item_left{
                display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
                -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
                .iconfont{
                    color: $defaultColor;
                    margin-right: 0.1rem;
                }
            }
            .item_right{
                display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
                -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
               
            }
        }
    }
}
</style>