<template>
  <div class="myHome wh100">
    <navBar title="我的家园" :leftArrowFlag="true" />
    <div class="content">
      <div class="box">
        <div class="box_item" @click="goMyFamily">
          <i class="iconfont icon-jiatingguanli"></i>
          <span>我的家人</span>
        </div>
        <div class="box_item" @click="goBlessingTree">
          <i class="iconfont icon-Tree"></i>
          <span>祈福树</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
export default {
  data() {
    return {
      
    };
  },
  components: {
    navBar,
  },
  created(){
    
  },
  methods: {
    //我的家人
    goMyFamily() {
      this.$toast('暂未开放')
     //this.$router.push(`/my/myFamily`)
    },
    //祈福树
    goBlessingTree() {
      //this.$toast('暂未开放')
       this.$router.push(`/my/blessingTree`)
    },
   
  },
};
</script>

<style lang="scss" scoped>
.myHome {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  .content {
    flex: 1;
    padding: 0.25rem 0.225rem;
    .box {
      display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .box_item {
        width: 1.1rem;
        height: 1.1rem;
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        margin: 0.15rem 0.275rem;
        -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
        -moz-justify-content: center;
-webkit-justify-content: center;
justify-content: center;
        -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
        border-radius: 0.05rem;
        box-shadow: 0px 0px 0.1rem 0.05rem #f0f0f0;
        .iconfont {
          font-size: 38px;
          color: $defaultColor;
        }
        span {
          font-size: 16px;
          line-height: 0.25rem;
        }
      }
    }
  }
}
</style>