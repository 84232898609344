<template>
  <div class="album wh100">
      <van-nav-bar
      title="实墓列表"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="content">
      <div class="content_main">
        <div class="media" v-if="pics.length > 0">
            <div
              v-for="(item, index) in pics"
              :key="index"
              :class="['media_item',item.state==1?'active':'']"
              @click="selectThisPic(item)"
            >
                <img v-show="item.state==1" class="tip" :src="selectTips" alt="">
                <van-icon v-show="item.state!=1" class="delete" name="delete" @click.native.stop="deleteItem(item.id)" />
              <van-image
                v-if="item.fileUrl"
              
                class="media_img"
                fit="fill"
                :src="item.fileUrl"
              />
              <van-image
                v-else
                class="media_img"
                fit="fill"
                :src="defaultImg"
              />
            </div>
        </div>
        <van-empty v-else description="暂无数据" />
      </div>
      <div class="footer">
        
        <div class="large_btn" @click="addClick" v-if="actionAuth">
          <span>添加照片</span>
          <input id="filePic" type="file" @change="changePhoto" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
 import navBar from "@/components/navBar";
import { ImagePreview } from "vant";
import { getUserId } from "@/utils/auth";
import { fetchMediaAuth, fetchWorshipBg,changeWorshipBg} from "@/api/memorialHall";
import {
  memorialInfo_photoAlbum,
  memorialInfo_photoAlbum_save,
  memorialInfo_photoAlbum_delete,
} from "@/api/memorialHall/album";
export default {
  data() {
    return {
        id:'',
        selectTips:require('@/assets/imgs/selected.png'),
      flag: false,
      actionAuth: false, //操作权限
      startPosition: 0, //图片预览下标
      selectResult: [],
      pageSize: 20, //分页条数
      currentPage: 1, //当前页
      loginUserId: "",
      fileBase64List: [],
      pics: [],
      defaultImg: require("@/assets/imgs/deadImg.png"),
    };
  },
  components: {
    navBar,
  },
  computed: {
    rightText() {
      if (this.flag && this.actionAuth) {
        return "完成";
      } else if (this.actionAuth) {
        return "编辑";
      } else {
        return "";
      }
    },
  },
  created() {
      this.id=this.$route.params.id;
    this.loginUserId = getUserId();
   // this.onload();

    //获取相册操作权限
    this.getMediaAuth(this.$route.params.id);
    //获取实墓图片
    this.getRealTombList()
  },
  methods: {
      //
      //返回纪念馆
    onClickLeft() {
      this.$router.push({
          path:`/memorialHall/worship/${this.$route.params.id}`,
          query:{
              type:'grave'
          }
      });
    },
      //切换背景
      async selectThisPic(item){
          this.pics.forEach(child=>{
              child.state=0
          })
          item.state=1;
        let params={
            memoriaId:this.id,
            id:item.id,
            updateType:'state'
        }
        let res= await changeWorshipBg(params)
        console.log(res);
        if(res.code=='2000'){
            
            this.getRealTombList();
            this.$toast({
            type: 'success',
            message: '操作成功',
            onClose: () => {
             
            },
          });
            // this.$notify({ type: 'success', message: '操作成功',duration: 1000, })
        }
      },
      //删除
      async deleteItem(id){
           let params={
            id,
            updateType:'delete'
        }
        let res= await changeWorshipBg(params)
        if(res.code=='2000'){
            //获取实墓图片
            this.getRealTombList()
        }
      },
      //
      async getRealTombList(){
          let params={
            sourceType:'grave',
            memoriaId:this.id
        }
        let res= await fetchWorshipBg(params)
        console.log(res,'99999')
        if(res.code=='2000'){
            if(res.data.userFileInfos&&res.data.userFileInfos.length>0){
                this.pics=res.data.userFileInfos;

            }else{
                this.pics=[]
            }
        }
      },
    async getMediaAuth(id) {
      let params = {
        id,
      };
      let res = await fetchMediaAuth(params);
      console.log(res, "操作全先");
      if (res.code != "2000") {
        this.$toast.fail(res.msg || "请求数据失败");
        return;
      } else {
        this.actionAuth = res.data;
      }
    },
    addClick() {
      document.querySelector("#filePic").click();
    },
    changePhoto() {
      // 选择图片
      let file = document.querySelector("#filePic").files[0];
      var reader = new FileReader();
      var that = this;
      console.log("base64", file);
      if (file.type != "image/jpeg" && file.type != "image/png") {
        this.$toast("请选择静态图片文件进行上传!");
        return;
      }
      reader.onloadend = function() {
        //把转换后的数据给id为base64Img的src属性
        console.log(reader.result);
        that.fileBase64List.push(reader.result);
        //这里调用了向后台发请求的代码
        that.addPhoto();
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    async addPhoto() {
      let postData = {
        memorialInfoId: this.$route.params.id, //纪念馆id
        fileBase64List: this.fileBase64List, //图片64转码
        type: "image", //文件类型(image:图片;music:音乐;视频:video)
        sourceType: "grave", //图片来源photo相册/grave墓穴
        loginUserId: this.loginUserId,
      };
      console.log(postData, "1111111");
      try {
        const res = await memorialInfo_photoAlbum_save(postData);
        console.log(res, 200);
        if (res.code === "2000") {
          this.$toast.success("照片添加成功");
          this.pics = [];
          this.fileBase64List = [];
           this.getRealTombList()
          return;
        }
        this.$toast.fail(res.msg || "数据请求失败");
      } catch (err) {
        console.log(err, 404);
        this.$toast.fail(err.msg);
      }
    },

    //图片预览
    imgPreview(index) {
      //   this.startPosition=index;
      ImagePreview({
        images: this.imgs,
        startPosition: index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.album {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  /deep/ .van-nav-bar {
    background-color: $defaultColor;
    .van-icon,
    .van-nav-bar__text {
      color: #fff;
    }
  }
  .content {
    flex: 1;
    padding: 0.12rem;
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
    .content_main {
      flex: 1;
      .media {
                    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
          flex-wrap: wrap;
          // height: calc(100% - 446px );
          overflow: auto;

          .media_item {
            position: relative;
            width: 1.1rem;
            height: 1.25rem;
            margin-right: 0.1rem;
            margin-bottom: 0.1rem;
            .delete{
                 position: absolute;
              right: 0rem;
              top: 0rem;
              z-index: 25;
              font-size: 20px;
            }
             .tip{
                  position: absolute;
                  right:0;
                  bottom:0;
                  width: 0.25rem;
                  height: 0.25rem;
                  z-index: 33;
              }
            .media_img {
              width: 100%;
              height: 100%;
            }
          }
          .media_item.active{
              border:1px solid $defaultColor;
          }
          .media_item:nth-child(3n) {
            margin-right: 0;
          }
        }
      
    }
    .footer {
      #filePic {
        display: none;
      }
    }
  }
}
</style>
