import request from '@/utils/request'

//获取信息
export function line_list(data) {
    return request({
      url:'/app/line/list',
      method: 'post',
      data
    })
  }
  
//获取新闻详情

export function getNewDetail(data) {
  return request({
    url:'/app/line/detail',
    method: 'post',
    data
  })
}