<template>
  <div class="news wh100 pb50">
    <!-- <navBar title="动态" backgroundColor="transparent"></navBar> -->
    <div class="content">
      <van-tabs
      v-model="active"
      animated
      swipeable
      :before-change="beforeChange"
      @click="tabChange"
      @change="tabChange"
    >
      <van-tab title="殡葬法规" name="0">
        <div class="list_content">
          <vantList
            :list="list"
            :loading="loading"
            :finished="finished"
            :refreshing="refreshing"
            @getList="getList()"
            @refresh="refresh"
          >
            <div class="list" v-for="(item, index) in list" :key="index">
              <newsItem :item="item"></newsItem>
            </div>
          </vantList>
        </div>
      </van-tab>
      <van-tab title="殡葬文化" name="1">
        <div class="list_content">
          <vantList
            :list="list"
            :loading="loading"
            :finished="finished"
            :refreshing="refreshing"
            @getList="getList()"
            @refresh="refresh"
          >
            <div class="list" v-for="(item, index) in list" :key="index">
              <newsItem :item="item"></newsItem>
            </div>
          </vantList>
        </div>
      </van-tab>
      <van-tab title="殡葬服务" name="2">
        <div class="list_content">
          <vantList
            :list="list"
            :loading="loading"
            :finished="finished"
            :refreshing="refreshing"
            @getList="getList()"
            @refresh="refresh"
          >
            <div class="list" v-for="(item, index) in list" :key="index">
              <newsItem :item="item"></newsItem>
            </div>
          </vantList>
        </div>
      </van-tab>
    </van-tabs>
    </div>
    <tabBar />
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import tabBar from "@/components/tabBar";
import vantList from "@/components/list";
import newsItem from "./components/newsItem";

import { line_list } from "@/api/news/index";
export default {
  name: "news",
  data() {
    return {
      active: 0,
      list: [],
      loading: true,
      finished: false,
      refreshing: false,
      pageSize: 10,
      currentPage: 1,
    };
  },
  components: {
    navBar,
    tabBar,
    vantList,
    newsItem,
  },
  created(){
    let newsType= sessionStorage.getItem('newsType')
    if(newsType){
      this.active=newsType
    }else{
      sessionStorage.setItem('newsType',0)
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    //tab切换之前
    beforeChange() {
      if (this.loading) {
        return false;
      }
    },
    //tab切换
    tabChange(name, title) {
      console.log(name, title);
      sessionStorage.setItem('newsType',name)
      this.list = [];
      this.active = name;
      this.currentPage = 1;
      this.finished = false;
      this.loading = true;
      this.refreshing = false;
      this.getList();
    },
    //请求数据
    async getList() {
      this.loading = true;
      const { currentPage, pageSize } = this;
      let params = {
        currentPage,
        pageSize,
        newsType: this.active,
      };
      let res = await line_list(params);
      this.loading = false;
      this.refreshing = false;
      console.log(res,'新闻');
      if (res.code === "2000") {
        let list =  res.data|| [];
        this.list=[...this.list,...list];
        //根据总条数处理
        if(pageSize>list.length){
          
          this.finished = true;
        }else{
          this.currentPage++
          this.finished = false;
        }
      }
    },
    //下拉刷新
    refresh() {
      this.refreshing = true;
      // 清空列表数据
      this.currentPage = 1;
      this.list = [];
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  .content{
    flex:1;
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
overflow: hidden;
    /deep/ .van-tabs{
      flex:1;
      display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
      -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
overflow-y: scroll;
      .van-tabs__content{
        flex:1;
        .van-tab__pane{
          height: 100%;
        }
      }
    }
  }
  /deep/ .van-nav-bar__title {
    color: $textColor !important;
  }
  /deep/ .van-tabs--line .van-tabs__wrap {
      padding-top: 0.05rem;
    .van-tabs__nav {
      justify-content: space-around;
      .van-tabs__line {
        display: none;
      }
      .van-tab {
        width: 1rem;
        height: 0.3rem;
        background: #efefef;
        color: $lightBlack;
        flex: none;
        border-radius: 0.05rem;
      }
      .van-tab--active {
        background: $defaultColor;
        color: #fff;
      }
    }
  }
  .list_content {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: #f9f9f9;
    height: 100%;
    padding: 0 0.1rem;
    width: 100%;
    /deep/ .van-list {
      width: 100%;
      .list_item {
        width: 100%;
        height: 0.8rem;
      }
    }
  }
}
</style>
