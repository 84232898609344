<template>
  <div class="blessimgCard wh100">
    <navBar title="许愿牌" :leftArrowFlag="true" />
    <div class="content">
      <div class="bless_list">
        <div
          class="bless_item"
          v-for="(item, index) in blesss"
          :key="index"
          @click="goBless(item.id)"
        >
          <img class="item_img" :src="item.little" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
export default {
  data() {
    return {
      blesss: [
        {
          id: 1,
          little: require("../../../../assets/imgs/bless/aiqing.png"),
          cover: require("../../../../assets/imgs/bless/aiqingcover.png"),
          big: require("../../../../assets/imgs/bless/aiqing_.png"),
          des: "爱情啊，我爱了",
          collectNums: 7,
          isCollected: false,
          name: "罗杰",
          title: "爱情",
        },
        {
          id: 2,
          little: require("../../../../assets/imgs/bless/caiyun.png"),
          cover: require("../../../../assets/imgs/bless/caiyuncover.png"),
          big: require("../../../../assets/imgs/bless/caiyun_.png"),
          des: "希望工作一切顺利",
          collectNums: 5,
          isCollected: false,
          name: "李博儿",
          title: "财运",
        },
        {
          id: 3,
          little: require("../../../../assets/imgs/bless/jiankang.png"),
          cover: require("../../../../assets/imgs/bless/jiankangcover.png"),
          big: require("../../../../assets/imgs/bless/jiankang_.png"),
          des: "健康永久，安详快乐",
          collectNums: 3,
          isCollected: false,
          name: "我",
          title: "健康",
        },
        {
          id: 4,
          little: require("../../../../assets/imgs/bless/pingan.png"),
          cover: require("../../../../assets/imgs/bless/pingancover.png"),
          big: require("../../../../assets/imgs/bless/pingan_.png"),
          des:
            "㊗️愿天下的父母身体安康㊗️朋友们一切顺利㊗️孩子学业有成㊗️有情人终成眷属🙏🙏🙏",
          collectNums: 7,
          isCollected: false,
          name: "苏娜",
          title: "平安",
        },
        {
          id: 5,
          little: require("../../../../assets/imgs/bless/shiye.png"),
          cover: require("../../../../assets/imgs/bless/shiyecover.png"),
          big: require("../../../../assets/imgs/bless/shiye_.png"),
          des: "祝一家人平安快乐！",
          collectNums: 3,
          isCollected: false,
          name: "卡斯",
          title: "事业",
        },
        {
          id: 6,
          little: require("../../../../assets/imgs/bless/xueye.png"),
          cover: require("../../../../assets/imgs/bless/xueyecover.png"),
          big: require("../../../../assets/imgs/bless/xueye_.png"),
          des: "学业有成",
          collectNums: 7,
          isCollected: false,
          name: "路飞",
          title: "学业",
        },
      ],
    };
  },
  components: {
    navBar,
  },
  methods: {
    goBless(id) {
      this.$router.push({
        path: `/my/writeBless/${id}`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.blessimgCard {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  // background: url("../../../../assets/imgs/blesscardbg.png") no-repeat;
  background-size: 100% 100%;
  .content {
    flex: 1;
    // background: url("../../../../assets/imgs/blesscard.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 0.1rem;
    .bless_list {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 0 0.2rem;
      .bless_item {
        margin: 0.58rem 0.1rem 0;
        .item_img {
          width: 0.75rem;
          height: 1.28rem;
        }
      }
    }
  }
}
</style>
