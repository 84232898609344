import request from "@/utils/request";

//根据id请求预约详情（扫码成功后）

export function fetchAppointmentInfo(data) {
  return request({
    url: "/sys/appointmentSacrifice/msg",
    method: "post",
    data,
  });
}