<template>
  <div class="appointmentQuery wh100">
    <navBar title="预约查询" :leftArrowFlag="true" @clickNavBarRight="goAppointment">
      <i class="iconfont icon-yuyuezhongxin"></i>
    </navBar>
    <div class="content">
      <div class="list_content">
        <vantList :list="list" :loading="loading" :finished="finished" :refreshing="refreshing" @getList="getList"
          @refresh="refresh">
          <div class="list" v-for="(item, index) in list" :key="index">
            <!-- <historyItem v-if='type="actingSacrifice"'  :item="item" @del="del($event)"></historyItem> -->
            <appointmentHistoryItem :item="item"></appointmentHistoryItem>
          </div>
        </vantList>
      </div>
      <!-- <van-tabs
        v-model="active"
        animated
        swipeable
        :before-change="beforeChange"
        @click="tabChange"
        @change="tabChange"
      >
        <van-tab title="预约祭扫" name="0">
          <div class="tab_content">
            <div class="list_content">
              <vantList
                :list="list"
                :loading="loading"
                :finished="finished"
                :refreshing="refreshing"
                @getList="getList"
                @refresh="refresh"
              >
                <div class="list" v-for="(item, index) in list" :key="index">
                  <appointmentHistoryItem :item="item"></appointmentHistoryItem>
                </div>
              </vantList>
            </div>
          </div>
        </van-tab>
        <van-tab title="代为祭扫" name="1">
          <div class="list_content">
            <vantList
              :list="list"
              :loading="loading"
              :finished="finished"
              :refreshing="refreshing"
              @getList="getList"
              @refresh="refresh"
            >
              <div class="list" v-for="(item, index) in list" :key="index">
                <historyItem :item="item" @del="del($event)"></historyItem>
              </div>
            </vantList>
          </div>
        </van-tab>
      </van-tabs> -->
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import vantList from "@/components/list";
import appointmentHistoryItem from "../appointmentSacrificeSweep/components/historyItem";
import historyItem from "../actingSacrificeSweep/components/historyItem";
import {
  getAppointmentList,
  orderSacrificeManage_operation,
} from "@/api/appointment/appointmentQuery";
export default {
  name: "news",
  data() {
    return {
      searchName: "",
      active: 0,
      type: "appointmentSacrifice", //类型预约祭扫
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageSize: 10,
      currentPage: 1,
    };
  },
  components: {
    navBar,
    vantList,
    historyItem,
    appointmentHistoryItem,
  },
  watch: {},
  created() {
    let queryActive = sessionStorage.getItem("queryActive");
    this.tabChange(queryActive);
    console.log(queryActive,'queryActive')
    if (queryActive) {
      this.active = queryActive;
      this.type = "appointmentSacrifice";
    } else {
      sessionStorage.setItem("queryActive", 0);
      this.active = 0;
      this.type = "appointmentSacrifice";
    }
    //  this.getList();
  },

  methods: {
    //返回预约中心
    goAppointment() {
      this.$router.push("/appointment");
    },
    //tab切换之前
    beforeChange() {
      if (this.loading) {
        return false;
      }
    },
    //tab切换
    tabChange(name, title) {
      if (name == 0) {
        this.type = "appointmentSacrifice";
        sessionStorage.setItem("queryActive", 0);
      } else {
        this.type = "appointmentSacrifice";
        sessionStorage.setItem("queryActive", 1);
      }
      this.list = [];
      this.active = name;
      this.currentPage = 1;
      this.finished = false;
      this.loading = false;
      this.refreshing = false;
      this.getList(true);
    },
    //请求数据
    async getList() {
      this.loading = true;
      const { currentPage, pageSize, type } = this;
      let params = {
        currentPage,
        pageSize,
        type,
      };
      console.log(params, "22");
      let res = await getAppointmentList(params);

      console.log(res, "fanhui");
      if (res.code != "2000") {
        this.$toast.fail(res.msg || "数据请求失败");
        return;
      }
      let list = JSON.parse(JSON.stringify(res.data)) || [];
      // list.forEach((item) => {
      //   if (item.osPrice == 0 && item.asUse == 4) {
      //     item.asUse = 5;
      //   }
      // });
      this.list = [...this.list, ...list];
      console.log(this.list, '列表')
      //根据总条数处理
      if (pageSize > list.length) {
        this.finished = true;
      } else {
        this.currentPage++;
        this.finished = false;
      }
      this.loading = false;
      this.refreshing = false;
    },
    async del({ id, version }) {
      let postData = { id, version: version, sweepOrderState: 0 };
      console.log(postData, "postData");
      try {
        const res = await orderSacrificeManage_operation(postData);
        console.log(res, 200);
        if (res.code === "2000") {
          this.$toast.success("订单已取消");

          this.refresh();
          return;
        }
        this.$toast.fail(res.msg || "订单取消失败");
      } catch (err) {
        console.log(err, 404);
        this.$toast.fail(err.msg);
      }
    },
    //下拉刷新
    refresh() {
      this.refreshing = true;
      // 清空列表数据
      this.currentPage = 1;
      this.list = [];
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentQuery {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;

  .content {
    flex: 1;
    // overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    overflow-y: scroll;
  }

  /deep/ .van-tabs {
    flex: 1;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    overflow: hidden;

    .van-tab__pane {
      padding-top: 0.05rem;
      background: #efefef;
    }

    .tab_content {
      height: 100%;
      background: #fff;
      overflow-y: scroll;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-flex-direction: column;
      -webkit-flex-direction: column;
      flex-direction: column;

      /deep/ .van-search {
        .van-cell {
          padding: 0.05rem 0 !important;
        }
      }
    }

    .van-tabs__content {
      flex: 1;

      .van-tab__pane {
        height: 100%;
      }
    }
  }

  /deep/ .van-tabs--line .van-tabs__wrap {
    padding: 0.1rem 0;
    height: auto;

    .van-tabs__nav {
      justify-content: space-around;
      padding-bottom: 0;

      .van-tabs__line {
        display: none;
      }

      .van-tab {
        width: 1.58rem;
        height: 0.3rem;
        background: #efefef;
        color: $lightBlack;
        flex: none;
        border-radius: 0.05rem;
      }

      .van-tab--active {
        background: $defaultColor;
        color: #fff;
      }
    }
  }

  .list_content {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding: 0 0.125rem;
    background: #fff;
    height: 5.65rem;
    flex: 1;
    width: 100%;

    /deep/ .van-list {
      width: 100%;

      .list_item {
        width: 100%;
        height: 0.8rem;
      }
    }
  }
}
</style>
