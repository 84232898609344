<template>
  <div class="upgradeDoubleRoom wh100">
    <navBar title="升级双人馆" :leftArrowFlag="true" />
    <div class="form_wrap">
      <van-form class="form" disabled>
        <!-- <div class="avatar_box">
          <div class="box_left"><span>头像</span></div>
          <div class="box_right">
            <van-uploader v-model="fileList"  :max-count="1">
              <img class="avatar" :src="avatar" alt="">
            </van-uploader>
          </div>
        </div> -->
        <van-field name="uploader" label="逝者照片" class="avatar_box">
          <template #input>
            <van-uploader
              :after-read="afterUpload"
              :max-count="1"
              :before-read="beforeUpload"
              v-model="form.uploader"
            >
              <img class="avatar" :src="form.avatar||avatar" alt="" />
            </van-uploader>
          </template>
        </van-field>
        <van-field v-model="form.userName" label="逝者姓名" />
        <van-field name="radio" label="逝者性别">
          <template #input>
            <van-radio-group v-model="form.sex" direction="horizontal" disabled>
              <van-radio :name="0">男</van-radio>
              <van-radio :name="1">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-model="form.birthday" name="birthday" label="出生日期" />
        <van-field v-model="form.deathDate" name="deathDate" label="死亡日期" />
      </van-form>
      <van-form @submit="onSubmit" class="form">
        <!-- <div class="avatar_box">
          <div class="box_left"><span>头像</span></div>
          <div class="box_right">
            <van-uploader v-model="fileList"  :max-count="1">
              <img class="avatar" :src="avatar" alt="">
            </van-uploader>
          </div>
        </div> -->
        <van-field name="uploader" label="逝者照片" class="avatar_box">
          <template #input>
            <van-uploader
              :after-read="afterUpload"
              :max-count="1"
              :before-read="beforeUpload"
              v-model="newForm.uploader"
            >
              <img class="avatar" :src="avatar" alt="" />
            </van-uploader>
          </template>
        </van-field>
        <van-field
          v-model="newForm.userName"
          name="userName"
          placeholder="请填写逝者姓名"
          label="逝者姓名"
          :rules="[{ required: true, message: '请填写逝者姓名' }]"
        />
        <van-field name="sex" label="逝者性别">
          <template #input>
            <van-radio-group v-model="newForm.sex" direction="horizontal">
              <van-radio name="0">男</van-radio>
              <van-radio name="1">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          readonly
          clickable
          name="birthday"
          :value="newForm.birthday"
          label="出生日期"
          placeholder="点击选择日期"
          @click="dateSelect('birthday')"
          :rules="[{ required: true, message: '请选择出生日期' }]"
        />
        
        <van-field
          readonly
          clickable
          name="deathDate"
          :value="newForm.deathDate"
          label="死亡日期"
          placeholder="点击选择日期"
          @click="dateSelect('deathDate')"
          :rules="[{ required: true, message: '请选择死亡日期' }]"
        />
        
        <div class="btn_box">
          <van-button round block type="primary" native-type="submit"
            >确认</van-button
          >
        </div>
      </van-form>
    </div>
    <van-popup v-model="showCalendar" position="bottom">
            <van-datetime-picker
              type="date"
              title="选择年月日"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="onConfirm"
              @cancel="showCalendar = false"
            />
          </van-popup>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import { memorialInfo_Detail,saveDeadInfo } from '@/api/memorialHall/memorialHallSetting'
export default {
  data() {
    return {
      id: "",
      avatar: require("../../../assets/imgs/avatar.png"),
      showCalendar:false,//
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      type:'',//日期类型
      form: {
        avatar:'',
        uploader: [],
        userName: "",
        sex: "1",
        birthday: "",
        deathDate: "",
      },
      newForm: {
        uploader: [],
        userName: "",
        sex: "1",
        birthday: this.$dayjs(new Date()).format('YYYY-MM-DD'),
        deathDate: this.$dayjs(new Date()).format('YYYY-MM-DD'),
      },
    };
  },
  components: {
    navBar,
  },
  created() {
    this.id=this.$route.params.id
    memorialInfo_Detail({id:this.id}).then(({data})=>{
      let obj=data.deadInfoDetails[0]
      
      this.form.avatar=obj.deadPortraitUrl
      this.form.userName=obj.deadName,
      this.form.sex=obj.genders
      this.form.birthday=obj.birthday,
      this.form.deathDate=obj.deathday
    })
  },
  methods: {
    dateSelect(type,current){
      this.showCalendar=true;
      this.type=type;
    },
    onConfirm(values) {
      const {type}=this
      this.newForm[type] = this.$dayjs(values).format('YYYY-MM-DD');
      let birthday=this.$dayjs(this.newForm.birthday).unix();
      let deathday=this.$dayjs(this.newForm.deathDate).unix();
      if(deathday<birthday){
        this.$toast.fail('出生日期应小于死亡日期');
        this.newForm.deathDate=''
        return 
      }
       this.showCalendar = false;
    },
    beforeUpload(file) {
      // if (file.type !== 'image/jpeg') {
      //   this.$toast('请上传 jpg 格式图片');
      //   return false;
      // }
      return true;
    },
    afterUpload(file) {
      console.log(file, this.form.uploader, "111");
    },
    onSubmit(data) {
      console.log("submit", data);
      let params={}
      params.id=this.id
      params.deadName=data.userName
      params.deadPortraitUrl=data.uploader.length>0?data.uploader[0].content:''
      params.genders=data.sex
      params.birthday=data.birthday
      params.deathday=data.deathDate
      this.$dialog.confirm({
                    title: '提交操作',
                    message: '您确定保存您所修改的信息吗？',
                })
                    .then(() => {
                    // on confirm
                      saveDeadInfo(params).then(()=>{
                          this.$toast.success('保存成功');
                          this.$router.push(`/memorialHall/memorialHallSetting/${this.id}`)
                      })
                    })
                    .catch(() => {
                    // on cancel
                        this.$toast.fail('取消提交');
                    });
    },
  },
};
</script>

<style lang="scss" scoped>
.upgradeDoubleRoom {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
 /deep/ .van-radio__icon--checked .van-icon{
    background-color: $defaultColor;
  border-color: $defaultColor;
}
    /deep/ .van-button--primary{
    width: 100%;
    margin:0;
    border-radius: 0.1rem;
    background-color: $defaultColor;
    border: 1px solid $defaultColor;
    box-shadow:0px 0px 10px $defaultColor;
}
  .form_wrap {
    width: 100%;
    flex: 1;
    background: #f9f9f9;

    overflow-y: scroll;
    .form {
      padding: 0.05rem 0.15rem;
      background: #fff;
      color: $lightBlack;
      margin-bottom: 0.15rem;
      /deep/ .van-field__control:disabled {
        color: $textColor;
        -webkit-text-fill-color: $textColor;
      }
      /deep/ .van-field__label {
        color: $lightBlack;
        font-size: 14px;
      }
      /deep/ .van-uploader {
        // .van-uploader__file {
        //   width: 0.6rem;
        //   height: 0.6rem;
        //   margin: 0;
        //   position: absolute;
        //   background: transparent;
        //   .van-uploader__upload-icon {
        //     display: none;
        //   }
        // }
        // .van-uploader__upload {
        //   width: 0.6rem;
        //   height: 0.6rem;
        //   margin: 0;
        //   position: absolute;
        //   background: transparent;
        //   .van-uploader__upload-icon {
        //     display: none;
        //   }
        // }
        .van-uploader__preview-image {
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
        }
      }
      /deep/ .van-uploader__preview {
        margin: 0;
      }

      .btn_box {
        margin-top: 0.4rem;
      }
      .avatar {
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: transparent;
      }
      .avatar_box {
        padding: 0.05rem 0;
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
        -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
        /deep/ .van-field__control--custom {
          justify-content: flex-end;
          .van-image {
            width: 0.6rem;
            height: 0.6rem;
            img {
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
</style>