<template>
  <div class="familyListItem">
    <div class="top">
      <div class="item_avatar">
        <img v-if="item.avatarUrl" :src="item.avatarUrl" class="avatar" alt="" />
        <img v-else :src="deadImg" class="avatar" alt="" />
      </div>
      <div class="item_info">
        <h3>{{ item.nickname }}</h3>
        <div class="gmtCreate">
          <span>创建于{{ item.gmtCreate }}</span>
        </div>
        <!-- <div class="leave">
          <span>{{ item.leave }}</span>
        </div> -->
      </div>
      <div
        :class="[
          'tips',
          item.mrfState == '0' ? 'status0' : '',
          item.mrfState == '1' ? 'status1' : '',
        ]"
      >
        <span>{{ item.mrfState | formatStatus }}</span>
      </div>
    </div>

    <div class="bottom">
      <div
        class="bottom_btn agree_btn"
        v-if="item.mrfState == '0'"
        @click="setItem(item.memorialRelativesFriendsId, 1)"
      >
        <span>同意</span>
      </div>
      <div
        class="bottom_btn rejected_btn"
        v-if="item.mrfState == '0'"
        @click="setItem(item.memorialRelativesFriendsId, 2)"
      >
        <span>驳回</span>
      </div>
      <div
        class="bottom_btn delete_btn"
        v-if="item.mrfState == '1'"
        @click="setItem(item.memorialRelativesFriendsId, 3)"
      >
        <span>删除</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deadImg: require('@/assets/imgs/deadImg.png'),
    };
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  filters: {
    formatStatus(val) {
      switch (
        val //0:删除;1:同意;2:驳回;3:退出
      ) {
        case 0:
          return "待审核";
          break;
        case 1:
          return "审核通过";
          break;
        // case 2:
        //   return "驳回";
        //   break;
        // case 3:
        //   return "退出";
        //   break;
        default:
          return "";
      }
    },
  },
  methods: {
    setItem(id, state) {
      this.$dialog
        .confirm({
          title:'提示',
          message: "请确认此操作",
        })
        .then(() => {
          console.log(id, "删除");
          this.$emit("setItem", { id, state });
        })
        .catch(() => {
          console.log(id, "取消删除");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.familyListItem {
  width: 100%;
  position: relative;
  background: #fff;
  border-radius: 0.08rem;
  padding: 0 0.07rem;
  margin-bottom: 0.1rem;
  .top {
    padding: 0.05rem 0;
    border-bottom: 1px solid #ededed;
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    .item_avatar {
      width: 0.7rem;
      height: 0.85rem;
      margin-right: 0.09rem;
      img {
        width: 100%;
        height: 100%;
        border-radius: 0.05rem;
      }
    }
    .item_info {
      h3 {
        margin-top: 0.04rem;
        font-size: 16px;
        line-height: 0.23rem;
      }
      .gmtCreate {
        font-size: 14px;
        line-height: 0.2rem;
        margin-bottom: 0.06rem;
      }
      .leave {
        font-size: 13px;
        line-height: 0.19rem;
        color: $lightBlack;
      }
    }
    .tips {
      position: absolute;
      right: 0;
      top: 0.1rem;

      color: #fff;
      padding: 0.03rem 0.09rem;
      border-top-left-radius: 0.125rem;
      border-bottom-left-radius: 0.125rem;
      span {
        font-size: 14px;
        line-height: 0.2rem;
      }
    }
    .status0 {
      background: #1882ec;
    }
    .status1 {
      background: $defaultColor;
    }
  }
  .bottom {
    width: 100%;
    height: 0.39rem;
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    justify-content: flex-end;
    -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
    .bottom_btn {
      width: 0.6rem;
      height: 0.25rem;
      margin-left: 0.25rem;
      border: 1px solid $defaultColor;
      color: $defaultColor;
      text-align: center;
      line-height: 0.25rem;
      font-size: 14px;
      border-radius: 0.05rem;
    }
    .agree_btn {
      border: 1px solid $defaultColor;
      color: $defaultColor;
    }
    .rejected_btn {
      border: 1px solid#1882ec;
      color: #1882ec;
    }
    .delete_btn {
      border: 1px solid #e7643c;
      color: #e7643c;
    }
  }
}
</style>
