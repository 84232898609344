<template>
    <li class="memorialHallItem" @click="goDetail(item.id)">
        <div class="item_avatar">
            <img v-if="item.avatar" :src="item.avatar" class="avatar" alt="">
            <img v-else :src="deadImg" class="avatar" alt="">
        </div>
        <div class="item_info">
            <h3>{{item.name}}</h3>
            <div class="life"><span>{{item.life}}</span></div>
            <div class="leave">离世<span>{{item.leave}}</span>年</div>
        </div>
    </li>
</template>

<script>
    export default {
        data() {
            return {
                deadImg: require('@/assets/imgs/deadImg.png'),
            }
        },
        props:{
            item:{
                type:Object,
                default:{}
            }
        },
        methods:{
            goDetail(id){
                this.$router.push(`/memorialHall/${id}`)
            }
        }
    }
</script>

<style lang="scss" scoped>
.memorialHallItem{
    width:100%;
    padding:0.05rem 0;
    position: relative;
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    background: #fff;
    color: $textColor;
    margin-top: 0.07rem;
    border-radius: 0.05rem;
    .item_avatar{
        width: 0.7rem;
        height: 0.85rem;
        margin-right: 0.09rem;
        img{
            width: 100%;
            height: 100%;
            border-radius: 0.05rem;
        }
    }
    .item_info{
        h3{
            margin-top: 0.04rem;
            font-size: 16px;
            line-height: 0.23rem;
        }
        .life{
            font-size: 14px;
            line-height: 0.2rem;
            margin-bottom: 0.06rem;
        }
        .leave{
            font-size: 13px;
            line-height: 0.19rem;
            color: $lightBlack;
        }
    }
    .tips{
        position: absolute;
        right:0rem;
        top:0.1rem;
        width: 0.6rem;
        height: 0.3rem;
        border-radius: 0.05rem;
        background: $defaultColor;
        text-align: center;
        color: #fff;
        span{
            font-size: 14px;
            line-height: 0.3rem;
        }
    }
    .hasFollow{
        background: #D4D4D4;
    }
}
</style>