<template>
    <li class="news_item" @click="goDetail(item.id)">
        <div class="item_img">
            <img :src="item.img" alt="">
        </div>
        <div class="item_info">
            <p>国家在推行殡葬改革已经几十年了，经过这么多年的政策宣传...</p>
            <div class="item_time">
                <span>{{item.time}}</span>
            </div>
        </div>
    </li>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        props:{
            item:{
                type:Object,
                default:{}
            }
        },
        methods:{
            goDetail(id){
                this.$router.push(`/newsDetail/${id}`)
            }
        }
    }
</script>

<style lang="scss" scoped>
.news_item{
    width: 100%;
    padding:0.1rem 0.12rem;
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    margin:0.05rem 0;
    background: #fff;
    border-radius: 0.08rem;
    //  box-shadow:0px 0px 0.1rem 0.05rem #F0F0F0;
    .item_img{
        width: 0.5rem;
        height: 0.7rem;
        margin-right: 0.1rem;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .item_info{
        flex:1;
        p{
            width: 2.17rem;
            height: 0.44rem;
            font-size:15px;
            line-height: 0.22rem;
            margin-bottom: 0.12rem;
        }
        .item_time{
            display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
            justify-content: flex-end;
            color: $lightBlack;
        }
    }
}
</style>