<template>
  <li class="news_item" @click="goDetail(item.id)">
    <div class="item_img">
      <img v-if="item.url" :src="item.url" alt="" />
      <img v-else :src="newsImg" alt="" />
    </div>
    <div class="item_info">
      <div class="info_top">
        <h3>{{ item.title }}</h3>
        <span class="info_time">{{ item.gmtCreate | timeFormat }} </span>
        <!-- <h3>法律法规</h3>
                <span class="info_time">2021-01-22</span> -->
      </div>
      <!-- <p v-html="item.content"></p> -->
    </div>
  </li>
</template>

<script>
export default {
  data() {
    return {
      newsImg: require("../../../assets/imgs/newsImg.png"),
    };
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },

  methods: {
    goDetail(id) {
      this.$router.push(`/newsDetail/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.news_item {
  width: 100%;
  padding: 0.1rem 0.1rem;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: 0.05rem 0;
  // height: 6rem;
  background: #fff;
  .item_img {
    width: 0.65rem;
    height: 0.65rem;
    margin-right: 0.07rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .item_info {
    flex: 1;
    .info_top {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      margin-bottom: 0.05rem;
      h3 {
        flex:1;
        font-size: 14px;
        line-height: 0.2rem;
        margin-right: 0.05rem;
        display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      }
      .info_time {
        color: $lightBlack;
        font-size: 11px;
        line-height: 0.15rem;
      }
    }
    p {
      width: 2.17rem;
      height: 0.44rem;
      font-size: 12px;
      line-height: 0.15rem;
      color: $lightBlack;
    }
  }
}
</style>
