<template>
  <div class="appointment wh100">
    <!-- <navBar title='预约' backgroundColor="transparent"></navBar> -->
    <div class="nav_list">
      <div class="list_item" @click="goDetail(1)">
        <i class="iconfont icon-yuyuezhongxin"></i>
        <span>预约祭扫</span>
      </div>
      <div class="list_item" @click="goDetail(3)">
        <i class="iconfont icon-chaxun"></i>
        <span>预约查询</span>
      </div>
      <div class="list_item" @click="goDetail(2)">
        <i class="iconfont icon-lazhu"></i>
        <span>代为祭扫</span>
      </div>
      <div class="list_item" @click="goDetail(4)">
        <i class="iconfont icon-chaxun"></i>
        <span>代为祭扫查询</span>
      </div>
    </div>
    <div class="bottom">
      <!-- <span>咨询电话：0531-5555-5555</span> -->
      <a :href="tel">咨询电话：{{ from.servicePhone }}</a>
    </div>
    <tabBar />
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import tabBar from "@/components/tabBar";
import { configuration_loadList } from "@/api/appointment/index";
export default {
  name: "appointment",
  data() {
    return {
      from: {},
    };
  },
  components: {
    navBar,
    tabBar,
  },
  mounted() {
    this.onload();
    localStorage.removeItem('editAppointmentInfo')
    localStorage.removeItem('actingSacrificeSweep')
  },
  computed: {
    tel() {
      return 'tel:' + Number(this.from.servicePhone)
    }
  },
  methods: {
    async onload() {
      let postData = {};
      console.log(postData, "postData");
      try {
        const res = await configuration_loadList(postData);
        console.log(res, 200);
        if (res.code === "2000") {
          this.from = res.data[0];
          localStorage.setItem("adminconfig", JSON.stringify(this.from));
          return;
        }
        this.$toast.fail(res.msg || "数据请求失败");
      } catch (err) {
        console.log(err, 404);
        this.$toast.fail(err.msg);
      }
    },
    goDetail(val) {
      if (val === 1) {
        if (this.from.asOpenSacrificeTomb == 0) {
          this.$toast("预约祭扫暂未开启");
        } else {
          this.$router.push("/appointment/appointmentSacrificeSweep");
        }
      } else if (val === 2) {
        this.$router.push("/appointment/actingSacrificeSweep");
        // if (this.from.asOpenReplaceSacrifice == 0) {
        //   this.$toast("预约代祭扫暂未开启");
        // } else {
        //   this.$router.push("/appointment/actingSacrificeSweep");
        // }
      } else if (val === 4) {
        this.$router.push("/appointment/orderList");
      } else {
        sessionStorage.setItem("queryActive", 0);
        this.$router.push("/appointment/appointmentQuery");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.appointment {
  padding: 1.5rem 0.38rem 0;
  background: url("../../assets/imgs/appointment/bg.jpg") no-repeat;
  background-size: 100% 100%;

  /deep/ .van-nav-bar__title {
    color: $textColor !important;
  }

  .nav_list {

    // margin-top: 0.9rem;
    .list_item {
      background: $defaultColor;
      color: #fff;
      height: 0.44rem;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-align-items: center;
      -webkit-align-items: center;
      align-items: center;
      -moz-justify-content: center;
      -webkit-justify-content: center;
      justify-content: center;
      border-radius: 0.08rem;
      margin-bottom: 0.34rem;
      box-shadow: $boxShow;

      .iconfont {
        margin-right: 0.08rem;
      }
    }
  }

  .bottom {
    margin-top: 2.3rem;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 0.2rem;
    color: $defaultColor;

    a {
      color: $defaultColor;
    }
  }
}
</style>
