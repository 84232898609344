<template>
  <div class="selectHomeBg wh100">
    <navBar title="纪念馆首页背景" :leftArrowFlag="true" />
    <div class="content">
      <div class="imgs_wrap">
        <div
          class="img_item"
          :class="[item.checked ? 'active' : '']"
          v-for="(item, index) in bgs"
          :key="index"
          :style="{ 'background-image': 'url(' + item.url + ')' }"
          @click="selectChange(item)"
        >
          <img v-show="item.checked" class="tip" :src="selectTips" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import {
  memorialInfoDetailImgOrMp3,
  setMemorialInfo,
} from "@/api/memorialHall/memorialHallSetting";
export default {
  data() {
    return {
      id: "",
      selectTips: require("../../../assets/imgs/selected.png"),
      bgs: [],
    };
  },
  components: {
    navBar,
  },
  created() {
    this.id = this.$route.params.id;
    memorialInfoDetailImgOrMp3({ id: this.id, fileType: "backgroundImg" }).then(
      (res) => {
        this.bgs = res.data.map((el) => {
          return {
            id: el.id,
            url: el.fileUrl,
            checked: !!el.flagId,
          };
        });
      }
    );
  },
  methods: {
    selectChange(item) {
      this.bgs.forEach((item) => (item.checked = false));
      item.checked = !item.checked;
      setMemorialInfo({
        memoriaSpuImgId: item.id,
        id: this.id,
        spuFileType: "backgroundImg",
      }).then(() => {
        this.$toast.success("修改成功");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.selectHomeBg {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  .content {
    flex: 1;
    padding: 0.1rem 0.17rem;
    .imgs_wrap {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-wrap: wrap;
      -moz-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      .img_item {
        position: relative;
        width: 1.61rem;
        height: 2rem;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 0.1rem;
        .tip {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 0.25rem;
          height: 0.25rem;
        }
      }
      .img_item.active {
        border: 1px solid $defaultColor;
      }
    }
  }
}
</style>
