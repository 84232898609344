<template>
  <div class="messageManagement wh100">
    <van-nav-bar
      title="留言管理"
      left-text="返回"
      left-arrow
      :right-text="rightText"
      @click-left="onClickLeft"
      @click-right="navAction"
    />
    <div class="content">
      <div class="tabs">
        <div class="tab" @click="goInfo"><span>简介</span></div>
        <div class="tab" @click="goAlbum"><span>相册</span></div>
        <div class="tab" @click="goEulogy"><span>悼文</span></div>
        <div class="tab active"><span>留言</span></div>
      </div>
      <div class="list_content">
        <vantList
          :list="list"
          :loading="loading"
          :finished="finished"
          :refreshing="refreshing"
          @getList="getList"
          @refresh="refresh"
        >
          <van-checkbox-group
            ref="checkboxGroup"
            v-model="selectResult"
            @change="selectChange"
          >
            <div class="list" v-for="(item, index) in list" :key="index">
              <messageItem
                :item="item"
                :flag="flag"
                @reload="reload"
              ></messageItem>
            </div>
          </van-checkbox-group>
        </vantList>
      </div>
    </div>

    <div class="footer">
      <div class="footer_top" v-show="selectResult.length > 0">
        <div class="footer_left" @click="checkAll"><span>全选</span></div>
        <div class="footer_right" @click="deleteEulogy"><span>删除</span></div>
      </div>
      <div class="footer_bottom">
        <van-field
          v-model="message"
          center
          clearable
          placeholder="留言寄哀思..."
          show-word-limit
        />
        <div class="sub_btn" @click="sendMessage">留言</div>
      </div>
    </div>
  </div>
</template>

<script>
import vantList from "@/components/list";
import messageItem from "./components/messageItem";
import { getUserInfo } from "@/api/user"; // 获取用户信息
import { getList, remove } from "@/api/my/eulogyManagement";
import { note_operation } from "@/api/memorialHall/album";
import { fetchMediaAuth } from "@/api/memorialHall";
export default {
  data() {
    return {
      message: "", //留言
      flag: false,
      loading: false,
      finished: false,
      refreshing: false,
      actionAuth: false, //操作权限
      pageSize: 10,
      currentPage: 1,
      selectResult: [],
      list: [],
      userInfo: {},
      memoriaId: "",
    };
  },
  components: {
    vantList,
    messageItem,
  },
  created() {
    console.log(this.$route.params.id, "留言编辑");
    this.memoriaId = this.$route.params.id;
    this.getUserInfo();
    this.getList();
    //获取相册操作权限
    this.getMediaAuth(this.$route.params.id);
  },
  computed: {
    rightText() {
      if (this.flag && this.actionAuth) {
        return "完成";
      } else if (this.actionAuth) {
        return "编辑";
      } else {
        return "";
      }
    },
  },
  methods: {
    //重新加载
    reload(){
      location.reload()
    },
    //获取相册操作权限
    async getMediaAuth(id) {
      let params = {
        id,
      };
      let res = await fetchMediaAuth(params);
      console.log(res, "操作全先");
      if (res.code != "2000") {
        this.$toast.fail(res.msg || "请求数据失败");
        return;
      } else {
        this.actionAuth = res.data;
      }
    },
    // 获取用户信息
    async getUserInfo() {
      let postData = {};
      console.log(postData, "postData");
      try {
        const res = await getUserInfo(postData);
        console.log(res, 200);
        if (res.code === "2000") {
          this.userInfo = res.data;
          // this.form.operatorVia = res.data.avatarUrl ? res.data.avatarUrl : "";
          // this.form.userId = res.data.id;
          // this.form.operatorNick = res.data.nickname ? res.data.nickname : "";
          return;
        }
        this.$toast.fail(res.msg || "数据请求失败");
      } catch (err) {
        console.log(err, 404);
        this.$toast.fail(err.msg);
      }
    },
    //留言
    async sendMessage() {
      if(this.message.trim()){
         let postData = {
        content: this.message,
        contentType: "ly",
        memoriaId: this.memoriaId,
        operatorNick: this.userInfo.nickname,
        operatorVia: this.userInfo.avatarUrl,
        state: 0,
        title: "",
        userId: this.userInfo.id,
      };

      console.log(postData, "postData");
      try {
        const res = await note_operation(postData);
        console.log(res, 200);
        if (res.code === "2000") {
          this.$toast.success("发布成功");
          this.message = "";
          this.list = [];
          this.currentPage = 1;
          this.getList();
          return;
        }
        this.$toast.fail(res.msg || "数据请求失败");
      } catch (err) {
        console.log(err, 404);
        this.$toast.fail(err.msg);
      }
      }else{
        this.$toast.fail("留言不能为空");
        return 
      }
     
    },
    //返回纪念馆
    onClickLeft() {
      this.$router.push(`/memorialHall/${this.$route.params.id}`);
    },
    //
    goInfo() {
      this.$router.push(`/memorialHall/albumLife/${this.$route.params.id}`);
    },
    //去相册
    goAlbum() {
      this.$router.push(`/memorialHall/album/${this.$route.params.id}`);
    },
    //去悼文
    goEulogy() {
      this.$router.push(
        `/memorialHall/eulogyManagement/${this.$route.params.id}`
      );
    },
    //点击编辑或完成
    navAction() {
      if(this.list.length>0){
         this.flag = !this.flag;
      this.selectResult = [];
      }else{
        this.$toast('暂无可管理数据')
      }
    },
    //选中改变
    selectChange(e) {
      console.log(e, "选中");
    },
    //全选
    checkAll() {
      //console.log(this.$refs.checkboxGroup)
      this.$refs.checkboxGroup.toggleAll(true);
    },
    //删除
    deleteEulogy() {
      this.$dialog
        .confirm({
          title: "删除操作",
          message: "您确定删除吗？",
        })
        .then(() => {
          // on confirm
          let ids = this.selectResult.map((el) => el);
          remove({ ids }).then((res) => {
            console.log(res, 200);
            if (res.code !== "2000") {
              this.$toast.fail("删除失败!");
              return;
            }
            this.$toast.success("删除成功!");
            this.reload()
            // this.list = [];
            // this.currentPage = 1;
            // this.getList();
          });
        })
        .catch(() => {
          // on cancel
          this.$toast("取消删除");
        });
    },
    //请求数据
    async getList() {
      this.loading = true;
      const { currentPage, pageSize } = this;
      let params = {
        currentPage,
        pageSize,
        contentType: "ly",
        memoriaId: this.memoriaId,
      };
      let res = await getList(params);
      console.log(res);
      this.loading = false;
      this.refreshing = false;
      if (res.code === "2000") {
        let list = res.data || [];
        //根据总条数处理
        console.log(this.list.length, res.total, this.list.length < res.total);
        // if (this.list.length < res.data.total) {
        list = list.map((el) => {
          let {
            id,
            content,
            gmtCreate,
            operatorNick,
            operatorVia,
            title,
            state,
          } = el;
          return {
            id,
            name: operatorNick,
            date: gmtCreate,
            avatar: operatorVia,
            status: state,
            title,
            des: content,
          };
        });
        this.list = [...this.list, ...list];
        this.currentPage++;
        console.log(this.list.length, res.total, this.list.length < res.total);
        if (this.list.length >= res.total) {
          this.finished = true;
          this.loading = false;
        }
        // }
        //根据总页数处理
        // if (this.currentPage > 1) {
        //   this.list = [...this.list, ...list];
        // } else {
        //   this.list = list;
        // }
        // // 如果当前页数 = 总页数，则已经没有数据
        // if (res.data.pager.currentPage == 6) {
        //   this.finished = true;
        //   this.loading = false;
        // }
        // // 如果总页数大于当前页码，页码+1
        // if (6 > this.currentPage) {
        //   this.currentPage++;
        // }
      }
    },
    //下拉刷新
    refresh() {
      this.refreshing = true;
      // 清空列表数据
      this.currentPage = 1;
      this.list = [];
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.messageManagement {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  /deep/ .van-nav-bar {
    background-color: $defaultColor;
    .van-icon,
    .van-nav-bar__text {
      color: #fff;
    }
  }
  .content {
    flex: 1;
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
    //   padding:0.12rem;
    background: #f9f9f9;
    overflow: hidden;
    margin-bottom: 0.5rem;
    .tabs {
      display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
      background: #fff;
      -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
      justify-content: space-around;
      margin-bottom: 0.1rem;
      padding: 0.1rem 0;
      .tab {
        width: 0.8rem;
        height: 0.3rem;
        border-radius: 0.05rem;
        background: #f5f5f5;
        color: #b7b7b7;
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        -moz-justify-content: center;
-webkit-justify-content: center;
justify-content: center;
        -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
        span {
          font-size: 15px;
        }
      }
      .tab.active {
        background: $defaultColor;
        span {
          color: #fff;
        }
      }
    }
    .list_content {
      flex: 1;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      padding: 0 0.125rem;
      /deep/ .van-list {
        width: 100%;
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    .footer_top {
      width: 100%;
      height: 0.5rem;
      padding: 0 0.125rem;
      display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
      -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
      -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
    }
    .footer_bottom {
      width: 100%;
      height: 0.45rem;
      background: #d0d0d0;
      display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
      padding: 0.04rem 0.12rem;
      /deep/ .van-cell {
        padding: 0.1rem !important;
        border-radius: 0.05rem;
      }
      .sub_btn {
        width: 0.6rem;
        height: 0.37rem;
        margin-left: 0.14rem;
        background: $defaultColor;
        color: #fff;
        border-radius: 0.05rem;
        text-align: center;
        line-height: 0.37rem;
      }
    }
  }
}
</style>
