<template>
  <div class="memorialHallItem">
    <div class="top" @click="goDetail(item.memorialInfoId)">
      <div class="item_avatar">
        <img v-if="item.deadPortraitUrl" :src="item.deadPortraitUrl" class="avatar" alt="" />
        <img v-else :src="deadImg" class="avatar" alt="" />
      </div>
      <div class="item_info">
        <h3>{{ item.deadName }}</h3>
        <div class="life">
          <span>{{ item.life }}</span>
        </div>
        <div class="passAwayYear">
          <span>离世{{ item.passAwayYear }}年</span>
        </div>
      </div>
      <div class="label">
        <!-- <div class="tips admin" v-if="item.friendsType">
          <span>管理者</span>
        </div> -->
        <div class="tips qinyou" v-if="item.friendsType == 'qy'">
          <span>亲友团</span>
        </div>
      </div>
      <div class="many" v-if="item.memorialType == 2">
        <span>双人馆</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deadImg: require('@/assets/imgs/deadImg.png'),
    };
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  methods: {
    //进入详情
    goDetail(id) {
      this.$router.push(`/memorialHall/${id}`);
    }
  },
};
</script>

<style lang="scss" scoped>
.memorialHallItem {
  width: 100%;
  position: relative;
  background: #fff;
  color: $textColor;
  margin-bottom: 0.07rem;
  padding: 0 0.07rem;
  overflow: hidden;
  .top {
    padding: 0.05rem 0;
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    .item_avatar {
      width: 0.7rem;
      height: 0.85rem;
      margin-right: 0.09rem;
      img {
        width: 100%;
        height: 100%;
        border-radius: 0.05rem;
      }
    }
    .item_info {
      h3 {
        margin-top: 0.04rem;
        font-size: 16px;
        line-height: 0.23rem;
      }
      .life {
        font-size: 14px;
        line-height: 0.2rem;
        margin-bottom: 0.06rem;
      }
      .leave {
        font-size: 13px;
        line-height: 0.19rem;
        color: $lightBlack;
      }
    }
    .label {
      position: absolute;
      right: 0;
      top: 0;
      .tips {
        // position: absolute;
        // right: 0;
        // top: 0.1rem;
        margin-top: 0.05rem;
        color: #fff;
        padding: 0.03rem 0.09rem;
        border-top-left-radius: 0.125rem;
        border-bottom-left-radius: 0.125rem;
        span {
          font-size: 14px;
          line-height: 0.2rem;
        }
      }
      .qinyou {
        background: #eda425;
      }
      .admin {
        background: $defaultColor;
      }
    }
    .many {
      position: absolute;
      background: $defaultColor;
      padding: 0.02rem 0.15rem;
      color: #fff;
      left: -0.16rem;
      top: 0.08rem;
      // z-index: 44;
      transform: rotate(-45deg);
      span {
        font-size: 12px;
        // line-height: 0.2rem;
      }
    }
  }
  .bottom {
    width: 100%;
    height: 0.39rem;
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    justify-content: flex-end;
    -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
    .sign_out_btn {
      margin-left: 0.05rem;
      width: 0.6rem;
      height: 0.25rem;
      border: 1px solid #eda425;
      color: #eda425;
      text-align: center;
      line-height: 0.25rem;
      font-size: 14px;
      border-radius: 0.05rem;
    }
    .bottom_admin {
      border: 1px solid $defaultColor;
      color: $defaultColor;
    }
  }
}
</style>
