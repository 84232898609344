<template>
  <div class="appointmentSacrificeSweep wh100">
    <navBar title="预约祭扫" :leftArrowFlag="true"></navBar>
    <div class="content">
      <h3>温馨提示</h3>
      <p>
        为做好寒衣节期间疫情防控工作，各级组织、个人不得组织集中祭扫、集体共祭、骨灰撒海等集体性活动。严格落实防控措施，防止交叉感染，每个骨灰盒参加祭扫人员不超过{{
          peopleNum
        }}人。
      </p>
      <div class="large_btn" @click="goNext"><span>我知道了</span></div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
export default {
  data() {
    return {
      peopleNum: 0,
    };
  },
  components: {
    navBar,
  },
  created() {
    this.peopleNum = JSON.parse(
      localStorage.getItem("adminconfig")
    ).maxAttendNum;
  },
  methods: {
    goNext() {
      this.$router.push("/appointment/appointmentTime");
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentSacrificeSweep {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  .content {
    flex: 1;
    padding: 0.15rem;
    h3 {
      font-size: 16px;
      line-height: 0.27rem;
      text-align: center;
      margin-bottom: 0.15rem;
    }
    p {
      font-size: 14px;
      line-height: 0.3rem;
    }
    .large_btn {
      margin-top: 3rem;
    }
  }
}
</style>
