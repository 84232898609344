<template>
  <div class="eulogyManagement wh100">
    <navBar
      title="悼文管理"
      :leftArrowFlag="true"
      :rightText="rightText"
      @clickNavBarRight="navAction"
    />
    <div class="list_content">
      <vantList
        :list="list"
        :loading="loading"
        :finished="finished"
        :refreshing="refreshing"
        @getList="getList"
        @refresh="refresh"
      >
      <van-checkbox-group ref="checkboxGroup" v-model="selectResult" @change="selectChange">
        <div class="list" v-for="(item, index) in list" :key="index">
          
            <eulogyItem :item="item" :flag='flag' @load="getList" ></eulogyItem>
 
        </div>
      </van-checkbox-group>
      </vantList>
    </div>
    <div class="footer" v-show="selectResult.length>0">
      <div class="footer_left" @click="checkAll"><span>全选</span></div>
      <div class="footer_right" @click="deleteEulogy"><span>删除</span></div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import vantList from "@/components/list";
import eulogyItem from "./components/eulogyItem";
import { getList,remove } from '@/api/my/eulogyManagement'
export default {
  data() {
    return {
      flag: false,
      loading: false,
      finished: false,
      refreshing: false,
      pageSize: 10,
      currentPage: 1,
      selectResult:[],
      list: [],
    };
  },
  components: {
    navBar,
    vantList,
    eulogyItem,
  },
  computed: {
    rightText() {
      if (this.flag) {
        return "完成";
      } else {
        return "管理";
      }
    },
  },
  created(){
    this.getList()
  },
  methods: {
     //重新加载
    reload(){
      location.reload()
    },
    //点击管理或完成
    navAction() {
      if(this.list.length>0){
        this.flag=!this.flag;
        this.selectResult=[]
      }else{
        this.$toast('暂无可管理数据')
      }
      
     
    },
    //选中改变
    selectChange(e){
      console.log(e,'选中')
    },
    //全选
    checkAll(){
      //console.log(this.$refs.checkboxGroup)
      this.$refs.checkboxGroup.toggleAll(true);
    },
    //删除
    deleteEulogy(){
      this.$dialog.confirm({
        title: '删除操作',
        message: '您确定删除吗？',
      })
        .then(() => {
          // on confirm
          let ids=this.selectResult.map(el=>el)
          remove({ids}).then(()=>{
            this.$toast({
              type:'success',
              message:"删除成功",
              
            })
             this.list = [];
            this.currentPage = 1;
            this.getList();
          })
        })
        .catch(() => {
          // on cancel
          this.$toast({
              type:'success',
              message:"取消删除",
          })
        });
    },
    //请求数据
    async getList() {
      this.loading = true;
      const { currentPage, pageSize } = this;
      let params = {
        currentPage,
        pageSize,
        contentType:'dw'
      };
      let res = await getList(params);
      this.loading = false;
      this.refreshing = false;
      if (res.code === "2000") {
        let list = res.data || [];
        //根据总条数处理
        // if (this.list.length < res.data.total) {
        //   this.list = [...this.list, ...list];
        //   this.currentPage++;
        // } else {
        //   this.list = [ ...list].map(el=>{
        //     let { id,content,gmtCreate,operatorNick,operatorVia,title,state}=el
        //     return {
        //       id,
        //       name: operatorNick,
        //       date: gmtCreate,
        //       avatar:operatorVia,
        //       status:state,
        //       title,
        //       des:content
        //     }
        //   });
        //   this.finished = true;
        //   this.loading = false;
        // }
             list = list.map((el) => {
          let {
            id,
            content,
            gmtCreate,
            operatorNick,
            operatorVia,
            title,
            state,
          } = el;
          return {
            id,
            name: operatorNick,
            date: gmtCreate,
            avatar: operatorVia,
            status: state,
            title,
            des: content,
          };
        });
        this.list = [...this.list, ...list];
        this.currentPage++;
        console.log(this.list.length, res.total, this.list.length >= res.total);
        if (this.list.length >= res.total) {
          this.finished = true;
          this.loading = false;
        }
      }
    },
    //下拉刷新
    refresh() {
      this.refreshing = true;
      // 清空列表数据
      this.currentPage = 1;
      this.list = [];
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getList();
    },
  },
  watch: {
    list(val){
      if(val.length==0){
        this.flag=false
        this.selectResult=[]
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.eulogyManagement {
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  .list_content{
    flex:1;
    overflow-y: scroll;
    background: #F9F9F9;
    -webkit-overflow-scrolling: touch;
    padding: 0 0.125rem;
        /deep/ .van-list{
        width:100%;
        
    }
  }
  .footer{
    width: 100%;
    height: 0.5rem;
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    padding:0 0.15rem;
    -moz-align-items: center;
-webkit-align-items: center;
align-items: center;
    -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
  }
}
</style>