<template>
  <div class="historyItem" @click="goDetail(item.id)">
    <div class="item_top">
      <div class="top">
        <div class="top_name">
          预约人姓名：<span>{{ item.appointmentName }}</span>
        </div>
        <div class="top_status">
          <span :class="[
            item.asUse == '0' ? 'status1' : '',
            item.asUse == '1' ? 'status2' : '',
            item.asUse == '2' ? 'status3' : '',
          ]">{{ item.asUse | formatStatus }}</span>
        </div>
      </div>
      <div class="date">
        预约日期：<span>{{ item.apointmentSegment }}</span>
      </div>
    </div>
    <!-- <div class="item_bottom">
        <div class="bottom_btn"><span>去付款</span></div>
        <div class="bottom_btn"><span>取消祭扫</span></div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      dafault: {},
    },
  },
  filters: {
    formatStatus(val) {
      switch (val) {
        case 0:
          return "未使用";
          break;
        case 1:
          return "已使用";
          break;
        case 2:
          return "已过期";
          break;
        case 6:
          return "待支付";
          break;
        default:
          return "";
      }
    },
  },
  methods: {
    goDetail(id) {
      this.$router.push(`/appointment/appointmentDetail/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.historyItem {
  padding: 0.15rem 0.1rem 0;
  // margin: 0.1rem 0;
  border-radius: 0.05rem;

  // box-shadow: 0px 0px 0.1rem 0.05rem #f0f0f0;
  .item_top {
    border-bottom: 1px solid #ededed;

    .top {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-justify-content: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      font-size: 15px;
      line-height: 0.21rem;
      margin-bottom: 0.05rem;

      .top_name {}

      .top_status {
        .status1 {
          color: $defaultColor;
        }

        .status2 {
          color: #888888;
        }

        .status3 {
          color: #e7643c;
        }
      }
    }

    .date {
      font-size: 12px;
      line-height: 0.17rem;
      margin-bottom: 0.22rem;
    }
  }

  .item_bottom {
    padding: 0.05rem 0.1rem;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-end;

    .bottom_btn {
      border: 1px solid $defaultColor;
      border-radius: 0.05rem;
      width: 0.8rem;
      height: 0.25rem;
      text-align: center;
      line-height: 0.25rem;
      color: $defaultColor;
      margin-left: 0.25rem;
    }
  }
}
</style>
