<template>
  <div class="login wh100">
    <div class="login_title">欢迎使用云祭祀平台!</div>
    <van-tabs v-model="active" swipeable animated>
      <van-tab title="验证码登录">
        <van-form @submit="codeSubmit" class="code_form">
          <van-field
            v-model="codeForm.phone"
            type="tel"
            name="phone"
            clearable
            label="手机号"
            :rules="[
              { required: true, message: '请填写您的手机号码！' },
              { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
            ]"
            placeholder="请输入您的手机号"
          />
          <div class="codeForm_btn">
            <van-button round block type="primary" native-type="submit"
              >发送验证码</van-button
            >
            <!-- <div v-if="!openId" class="large_btn" @click="wxLogin"><span>微信登录</span></div> -->
          </div>
        </van-form>
      </van-tab>
      <van-tab title="密码登录">
        <van-form @submit="passwordSubmit" class="password_form">
          <van-field
            v-model="passwordForm.phone"
            type="tel"
            name="phone"
            clearable
            label="手机号"
            :rules="[
              { required: true, message: '请填写您的手机号码！' },
              { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
            ]"
            placeholder="请输入您的手机号"
          />
          <div class="passwordSubmit_btn">
            <van-button round block type="primary" native-type="submit"
              >下一步</van-button
            >
            <!-- <div v-if="!openId" class="large_btn" @click="wxLogin"><span>微信登录</span></div> -->
          </div>
        </van-form>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { getUserStatus, getVerificationCode } from "@/api/user";
export default {
  name: "login",
  data() {
    return {
      //openId:localStorage.getItem('openId'),//是否有openId
      msgType: 1, //1注册2登录
      active: 0, //默认tab
      passwordForm: {
        phone: "",
      }, //密码登录表单
      codeForm: {
        phone: "",
      }, //手机验证登录表单
    };
  },
  created(){
    //console.log(this.openId,'2222')
  },
   beforeRouteEnter (to, from, next) {
            let token=localStorage.getItem('token')
            if(token){
                next(false)
            }else{
                next()
            }
      },
  methods: {
    //密码登录
    async passwordSubmit(vals) {
      const { phone } = vals;
      let res = await getUserStatus({ phone });
      if (res.code == "2000") {
        if (res.data) {
          //注册过走登录
          this.msgType = 2;
           this.$router.push({
            path: "/passwordRegister",
            query: {
              phone,
              msgType: this.msgType,
            },
          });
        } else {
          //没注册过走注册
          this.msgType = 1;
           let params = {
          mobile: phone,
          msgType: this.msgType,
        };
        let result = await getVerificationCode(params);
        if (result.code == "2000") {
          this.$toast.success(result.data);
          this.$router.push({
            path: "/passwordRegister",
            query: {
              phone,
              msgType: this.msgType,
            },
          });
        } else {
          this.$toast.fail(res.result);
        }
        }
       
      } else {
        this.$toast.fail(res.msg || "数据请求失败");
      }
    },
    //手机验证码登录
    async codeSubmit(vals) {
      const { phone } = vals;
      let res = await getUserStatus({ phone });
      if (res.code == "2000") {
        if (res.data) {
          //注册过走登录
          this.msgType = 2;
        } else {
          //没注册过走注册
          this.msgType = 1;
        }
        let params = {
          mobile: phone,
          msgType: this.msgType,
        };
        let result = await getVerificationCode(params);

        if (result.code == "2000") {
          this.$toast.success(result.data);
          this.$router.push({
            path: "/loginRegister",
            query: {
              phone,
              msgType: this.msgType,
            },
          });
        } else {
          this.$toast.fail(result.msg);
        }
      } else {
        this.$toast.fail(res.msg || "数据请求失败");
      }
    },
    //
    // wxLogin(){
    //   this.$router.push('/binding')
    // }
  },
};
</script>

<style lang="scss" scoped>
.login {
  color: #fff;
  background: $defaultColor;
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  /deep/ .van-cell {
    background: #f8f8f8;
    border-radius: 0.1rem;
  }
  /deep/ .van-field__label {
    width: 0.5rem;
    text-align: right;
  }
  /deep/ .van-button--primary{
    width: 100%;
    margin:0;
    border-radius: 0.1rem;
    background-color: $defaultColor;
    border: 1px solid $defaultColor;
    box-shadow:0px 0px 10px $defaultColor;
}
// /deep/ .van-field__error-message{
//   display: none;
// }
  .login_title {
    padding-left: 0.3rem;
    font-size: 20px;
    margin-bottom: 0.37rem;
    line-height: 0.28rem;
    margin-top: 0.45rem;
  }
  .van-tabs {
    flex: 1;
    display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
    -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
    color: #141414;
    /deep/ .van-tabs__nav {
      background-color: transparent;
      .van-tab {
        color: rgba(255, 255, 255, 0.7);
      }
      .van-tab--active {
        color: rgba(255, 255, 255, 1);
      }
      .van-tabs__line {
        background-color: #fff;
      }
    }
    /deep/ .van-tabs__content {
      height: 100%;
      flex: 1;
      background: #fff;
      border-top-left-radius: 0.2rem;
      margin-top: 0.1rem;
      border-top-right-radius: 0.2rem;
      .van-tab__pane {
        height: 100%;
        padding: 0.9rem 0.3rem 0;
      }
    }
  }
  .codeForm_btn,
  .passwordSubmit_btn {
    margin-top: 0.7rem;
    .large_btn{
      margin-top: 0.15rem;
    }
  }
}
</style>