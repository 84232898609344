<template>
  <div class="actingSacrificeSweep wh100">
    <navBar title="填写预约信息" :leftArrowFlag="true" />
    <div class="content">
      <van-form @submit="onSubmit" class="form">
        <van-field
          v-model="form.appointmentName"
          name="appointmentName"
          label="预约人姓名"
          error-message=""
          placeholder="预约人姓名"
          :rules="[{ required: true, message: '请填写预约人姓名' }]"
        />
        <van-field
          v-model="form.appointmentPhone"
          name="appointmentPhone"
          type="digit"
          label="预约人手机号"
          placeholder="预约人手机号"
          :rules="[
            { required: true, message: '请填写预约人手机号' },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
          ]"
        />
        <van-field
          v-model="form.appointmentCardNo"
          name="appointmentCardNo"
          label="预约人身份证号"
          placeholder="预约人身份证号"
          :rules="[
            { required: true, message: '请填写预约人身份证号' },
            { validator: idCardChange, message: '请输入正确的身份证号' },
          ]"
        />
        <van-field
          readonly
          clickable
          name="calendar"
          :value="form.appointmentDate"
          label="选择预约日期"
          right-icon="arrow-down"
          placeholder="点击选择日期"
          @click="showCalendar = true"
        />
        <van-field
          v-model="form.deadName"
          name="deadName"
          label="逝者姓名"
          error-message=""
          placeholder="逝者姓名"
          :rules="[{ required: true, message: '请填写逝者姓名' }]"
        />
        <van-field
          v-model="form.deadCardNo"
          name="deadCardNo"
          label="逝者身份证号"
          placeholder="逝者身份证号"
          :rules="[
            { required: true, message: '请填写逝者身份证号' },
            { validator: idCardChange, message: '请输入正确的身份证号' },
          ]"
        />
        <van-field
          v-model="form.graveDetail"
          name="graveDetail"
          label="预约位置"
          placeholder="预约位置"
          :rules="[{ required: true, message: '请填写预约位置' }]"
        />
        <div class="btn_box">
          <van-button round block type="primary" native-type="submit"
            >确认预约</van-button
          >
        </div>
        <van-calendar
          :max-date="maxDate"
          v-model="showCalendar"
          @confirm="onConfirm"
        />
      </van-form>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";

export default {
  data() {
    return {
      showCalendar: false,
      maxDate: this.$dayjs(new Date()).add(7, "day").$d,
      form: {
        appointmentName: "", //预约人
        appointmentPhone: "", //电话
        appointmentCardNo: "", //预约人身份证
        appointmentDate: this.$dayjs(new Date()).format("YYYY-MM-DD"), // 预约日期
        deadName: "", // 逝者姓名
        deadCardNo: "", //逝者身份证
        graveDetail: "",
        graveSelectSuch: 0, //手动录入还是选择墓穴(0:手动;1:选择墓穴)
        commoditiesReqList: [], //所选商品信息
      },
    };
  },
  components: {
    navBar,
  },
  created() {
    let time = JSON.parse(localStorage.getItem("adminconfig"))
      .appointmentEndNum;
    this.maxDate = this.$dayjs(new Date()).add(time - 1, "day").$d;
    // 将保存中的数据加载到页面
    if (JSON.parse(localStorage.getItem("actingSacrificeSweep"))) {
      this.form = JSON.parse(localStorage.getItem("actingSacrificeSweep"));
    }
  },
  methods: {
    onConfirm(date) {
      this.form.appointmentDate = this.$dayjs(date).format("YYYY-MM-DD");
      this.showCalendar = false;
    },

    async onSubmit() {
      //预约人身份证号与逝者身份号不同
      const {appointmentCardNo, deadCardNo} = this.form
      if(appointmentCardNo==deadCardNo){
        this.$toast.fail('预约人身份证号不能和逝者身份证号相同！')
        return false
      }
      localStorage.setItem("actingSacrificeSweep", JSON.stringify(this.form));
      // return;
      this.$router.push({
        path: "/appointment/serviceItems",
        query: {appointmentCardNo: appointmentCardNo}
      });
    },
    // 身份证号验证
    idCardChange(idCard) {
      var flag = false;
      //15位和18位身份证号码的正则表达式
      var regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;

      //如果通过该验证，说明身份证格式正确，但准确性还需计算
      if (regIdCard.test(idCard)) {
        if (idCard.length == 18) {
          var idCardWi = new Array(
            7,
            9,
            10,
            5,
            8,
            4,
            2,
            1,
            6,
            3,
            7,
            9,
            10,
            5,
            8,
            4,
            2
          ); //将前17位加权因子保存在数组里
          var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2); //这是除以11后，可能产生的11位余数、验证码，也保存成数组
          var idCardWiSum = 0; //用来保存前17位各自乖以加权因子后的总和
          for (var i = 0; i < 17; i++) {
            idCardWiSum += idCard.substring(i, i + 1) * idCardWi[i];
          }

          var idCardMod = idCardWiSum % 11; //计算出校验码所在数组的位置
          var idCardLast = idCard.substring(17); //得到最后一位身份证号码

          //如果等于2，则说明校验码是10，身份证号码最后一位应该是X
          if (idCardMod == 2) {
            if (idCardLast == "X" || idCardLast == "x") {
              flag = true;
            } else {
              flag = false;
            }
          } else {
            //用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
            if (idCardLast == idCardY[idCardMod]) {
              flag = true;
            } else {
              flag = false;
            }
          }
        } else if (idCard.length == 15) {
          var id17 = idCard.substring(0, 6) + "19" + idCard.substring(6);
          var parityBit = getParityBit(id17);
          var tempIdCard = id17 + parityBit;
          flag = validateIdCard(tempIdCard);
        }
      } else {
        flag = false;
      }
      return flag;
    },
  },
};
</script>

<style lang="scss" scoped>
.actingSacrificeSweep {
  /deep/ .van-calendar__selected-day {
    background-color: $defaultColor !important;
  }
  /deep/ .van-button--primary {
    width: 100%;
    margin: 0;
    border-radius: 0.1rem;
    background-color: $defaultColor;
    border: 1px solid $defaultColor;
    box-shadow: 0px 0px 10px $defaultColor;
  }
  /deep/ .van-calendar__footer {
    .van-button--danger {
      background-color: $defaultColor !important;
      border: 1px solid $defaultColor !important;
    }
  }
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  .content {
    flex: 1;
    overflow-y: scroll;
    padding: 0.15rem;
    .form {
      // /deep/ .van-field__error-message {
      //   display: none;
      // }
      /deep/ .van-field__label {
        width: 1rem;
      }
      /deep/ .van-cell {
        padding: 0.15rem 0 !important;
      }
      .btn_box {
        margin-top: 1.25rem;
      }
    }
  }
}
</style>
