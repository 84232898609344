<template>
  <div class="order wh100">
    <navBar title="订单确认" :leftArrowFlag="true" />
    <div class="content">
      <div class="content_item">
        <div class="item_title"><span>祭扫服务</span></div>
        <van-card num="1" :price="sacrificePrice" title="祭扫服务基础费" :thumb="basciImg" />
      </div>
      <div class="content_item">
        <div class="item_title"><span>其他服务项目</span></div>
        <div class="card_list">
          <van-card v-for="(item, index) in selectItems" :key="index" :num="item.sales" :price="item.price"
            :title="item.title" :thumb="item.imgUrl1" />
        </div>
      </div>
      <div class="total_box">
        <span>合计：{{ totalPrice }}</span>
      </div>
      <!-- <div class="large_btn" @click="goOrderList">
        <span>提交</span>
      </div> -->
      <div class="btn_box">
        <van-button :loading='btnLoading' :disabled='btnDisabled' round block type="primary"
          @click="goOrderList">提交</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";

import { replaceSacrifice_add, wxpayUpdatePayInfo } from "@/api/appointment/actingSacrificeSweep";
import { setTimeout } from "timers";
import { json } from "body-parser";
export default {
  data() {
    return {
      btnLoading: false,
      btnDisabled: false,
      basciImg: require('@/assets/imgs/basic.png'),
      selectItems: [],
      totalPrice: 0,
      sacrificePrice: 0,
      goodsList: [],
      info: {
        wxPayReq: {}
      }
    };
  },
  components: {
    navBar,
  },
  created() {
    console.log(this.$route.query.total, this.$route.query.sacrificePrice);
    this.selectItems = JSON.parse(
      localStorage.getItem("actingSacrificeSweep")
    ).commoditiesReqList;
    this.sacrificePrice = `${(this.$route.query.sacrificePrice / 100).toFixed(2) }`;
    this.totalPrice = this.$route.query.total / 100;
  },
  methods: {
    goOrderList() {
      let info = JSON.parse(localStorage.getItem("actingSacrificeSweep"));
      let goodsList = JSON.parse(localStorage.getItem("actingSacrificeSweep"))
        .commoditiesReqList;
      info.commoditiesReqList = [];
      goodsList.forEach((item) => {
        let { id, price, title, sales } = item;
        info.commoditiesReqList.push({ id, price, title, sales });
      });
      this.goodsList = goodsList
      console.log(info, "info");
      this.info = info
      this.wxPay()
      //   return;
    },
    wxPay() {
      this.btnDisabled = true
      this.btnLoading = true
      // if (typeof WeixinJSBridge == "undefined") {
      //   if (document.addEventListener) {
      //     document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
      //   } else if (document.attachEvent) {
      //     document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
      //     document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
      //   }
      // } else {
      //   this.onBridgeReady();
      // }
      var charges = this.totalPrice;
      let selectedItems = this.goodsList.filter((item) => {
        return item
      }).map((item) => {
        return {
          "id": item.id.toString(),
          "serviceItemName": item.title.toString(),
          // "serviceItem": item.serviceItem.toString(),
          // "realCharge": item.realCharge.toString(),
          // "asFinished": item.asFinished.toString(),
          "price": item.price.toString(),
          "number": item.sales.toString(),
          // "charge": item.charge.toString()
        }
      })
      let serviceData = [{ "serviceItemName": '代为祭扫服务费', "price": this.sacrificePrice, "number": 1, }]
      let obj = {
        "amountTotal": charges,
        "payChannel": "WX",
        "payType": "wxXcxPay",
        "attachData": JSON.stringify({
          "selectedItems": [...serviceData,...selectedItems]
        }),
        // "attachCode": this.$route.query.appointmentCardNo,
        "attachCode": new Date().getTime(),
        "useType": "dai_wei_ji_sao_shou_fei",
        "description": "代为祭扫缴费",
        // "openId": 'oBksy1Tcw3e1McGqQLuIoUGsw306A',
        "openId": localStorage.getItem('openId'),
      }
      this.$set(this.info, 'wxPayReq', '')
      this.info.wxPayReq = obj
      console.log(this.info)
      replaceSacrifice_add(this.info).then(res => {
        if (res.code === "2000") {
          if (charges == 0) {
            this.$router.push("/appointment/orderList");
          }
          if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
              document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
            } else if (document.attachEvent) {
              document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
              document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
            }
          } else {
            this.onBridgeReady(res, res.data.outTradeNo);
          }
          this.btnLoading = false
          this.btnDisabled = false
        } else {
          this.$toast.fail(res.msg);
          this.btnDisabled = false
          this.btnLoading = false
        }

      })
        .catch(error => {
          console.log(error)
          this.btnDisabled = false
          this.btnLoading = false
        })
    },

    onBridgeReady(res, outTradeNo) {
      let that = this
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
        "appId": res.data.appId,   //公众号名称，由商户传入
        "timeStamp": res.data.timeStamp,     //时间戳，自1970年以来的秒数
        "nonceStr": res.data.nonceStr, //随机串
        "package": res.data.package,
        "signType": res.data.signType,       //微信签名方式：
        "paySign": res.data.paySign //微信签名
      },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。
            that.$toast.success("支付成功");
            if (outTradeNo) {
              that.paystatusave(outTradeNo)
            }
          } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
            that.$toast.fail("取消支付");
            that.$router.push("/appointment/orderList");
            localStorage.removeItem("actingSacrificeSweep");
          } else {
            that.$toast.fail(res.msg || "失败");
          }
          that.btnLoading = false
          that.btnDisabled = false
        }
      );
    },
    paystatusave(outTradeNo) {
      wxpayUpdatePayInfo({ outTradeNo: outTradeNo }).then(data => {
        this.$router.push("/appointment/orderList");
        localStorage.removeItem("actingSacrificeSweep");
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;

  /deep/ .van-button--primary {
    width: 100%;
    margin: 0;
    border-radius: 0.1rem;
    background-color: $defaultColor;
    border: 1px solid $defaultColor;
    box-shadow: 0px 0px 10px $defaultColor;
  }

  .content {
    flex: 1;
    padding: 0.15rem;
    overflow-y: scroll;

    .content_item {
      .item_title {
        border-left: 3px solid $defaultColor;
        text-indent: 0.1rem;
      }
    }

    .total_box {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      line-height: 0.28rem;
      padding-right: 0.2rem;
      margin: 0.2rem 0 1rem;
    }
  }
}
</style>
