<template>
  <div class="recharge wh100">
    <navBar
      title="请选择充值的套餐"
      :leftArrowFlag="true"
      rightText="充值记录"
      @clickNavBarRight="goRechargeHistory"
    />
    <div class="content">
      <div class="setMeals">
        <div
          class="setMealItem"
          :class="[active == index ? 'active' : '']"
          v-for="(item, index) in list"
          :key="index"
          @click="selectItem(item, index)"
        >
          <h4>{{ item.title }}</h4>
          <h3>
            ￥<span>{{ item.price }}</span>
          </h3>
          <h5>
            送<span>{{ item.commemorativeCoins }}</span
            >纪念币
          </h5>
        </div>
      </div>
      <div class="large_btn" @click="wxPlay">
        <span>微信支付</span>
      </div>
      <div class="infos">
        <div class="infos_title"><span>充值说明</span></div>
        <div class="infos_item">
          <div class="tips">1.</div>
          <p>
            充值后可获得祭祀币和纪念币，均可用于供奉祭品、点灯，或其他可使用的服务。
          </p>
        </div>
        <div class="infos_item">
          <div class="tips">2.</div>
          <p>
            充值后可获得祭祀币和纪念币，均可用于供奉祭品、点灯，或其他可使用的服务。
          </p>
        </div>
        <div class="infos_item">
          <div class="tips">3.</div>
          <p>
            充值后可获得祭祀币和纪念币，均可用于供奉祭品、点灯，或其他可使用的服务。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
export default {
  data() {
    return {
      active: 0,
      list: [
        {
          id: 1,
          title: "套餐一:10祭祀币",
          price: "5.00",
          commemorativeCoins: 50,
        },
        {
          id: 2,
          title: "套餐二:50祭祀币",
          price: "10.00",
          commemorativeCoins: 100,
        },
        {
          id: 3,
          title: "套餐三:200祭祀币",
          price: "35.00",
          commemorativeCoins: 450,
        },
        {
          id: 4,
          title: "套餐四:500祭祀币",
          price: "50.00",
          commemorativeCoins: 600,
        },
      ],
    };
  },
  components: {
    navBar,
  },
  methods: {
    //充值记录
    goRechargeHistory() {
      this.$router.push("/my/rechargeList");
    },
    //
    selectItem(item, index) {
      console.log(item);
      this.active = index;
    },
    wxPlay() {},
  },
};
</script>

<style lang="scss" scoped>
.recharge {
  background: #f8f8f8;
  display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
  -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
  .content {
    flex: 1;
    padding: 0.2rem;
    .setMeals {
      display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
      flex-wrap: wrap;
      -moz-justify-content: space-between;
-webkit-justify-content: space-between;
justify-content: space-between;
      .setMealItem {
        width: 1.55rem;
        height: 1rem;
        padding: 0.06rem 0;
        background: #fff;
        box-shadow: 0px 0px 0.1rem 0.05rem #f0f0f0;
        text-align: center;
        color: $lightBlack;
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        -moz-flex-direction: column;
-webkit-flex-direction: column;
flex-direction: column;
        justify-content: space-around;
        margin-bottom: 0.15rem;
        border-radius: 0.08rem;
        h4 {
          font-size: 14px;
          line-height: 0.2rem;
        }
        h3 {
          font-size: 14px;
          span {
            font-size: 20px;
            font-weight: 600;
            line-height: 0.28rem;
          }
        }
      }
      .setMealItem.active {
        color: $defaultColor;
      }
    }
    .large_btn {
      margin: 0.2rem 0;
    }
    .infos {
      .infos_title {
        font-size: 16px;
        line-height: 0.225rem;
        margin-bottom: 0.13rem;
      }
      .infos_item {
        display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex; 
display: flex;
        margin-bottom: 0.13rem;
        font-size: 14px;
        line-height: 0.2rem;
        p {
          flex: 1;
        }
      }
    }
  }
}
</style>