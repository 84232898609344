<template>
  <div class="historyItem" @click="goDetail(item.id)">
    <div class="item">
      <div class="item_item">
          <span class="label">预约人姓名：</span><span class="dec">{{ item.appointmentName }}</span>
      </div>
      <div class="item_item">
          <span class="label">预约人联系方式：</span><span class="dec">{{ item.appointmentPhone }}</span>
      </div>
      <div class="item_item">
        <span class="label">预约人身份证号：</span><span class="dec">{{ item.appointmentCardNo }}</span>
      </div>
       <div class="item_item">
        <span class="label">预约日期：</span><span class="dec">{{ item.appointmentDate }}</span>
      </div>
      <div class="item_item">
        <span class="label">订单状态：</span><span class="dec">{{ item.sweepOrderState | formatStatus(item.orderType) }}</span>
      </div>
    </div>
    <!-- <div class="item_bottom">
        <div class="bottom_btn"><span>去付款</span></div>
        <div class="bottom_btn"><span>取消祭扫</span></div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      dafault: {},
    },
  },
  filters: {

    formatStatus(val, type) {
      if (val == 0) {
        return "用户取消";
      } else if (val == 1) {
        return "工作人员取消";
      } else if (val == 2) {
        return "待审核";
      } else if (val == 3) {
        return "审核成功,待支付";
      } else if (val == 4 && type == 0) {
        return "免费祭扫";
      } else if (val == 4 && type == 1) {
        return "支付成功";
      } else if (val == 5) {
        return "完成";
      }
    
  },
  },
  methods: {
    goDetail(id) {
      this.$router.push(`/my/examineListDetail/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.historyItem {
  padding: 0.15rem 0.1rem 0;
  // margin: 0.1rem 0;
  border-radius: 0.05rem;
  // box-shadow: 0px 0px 0.1rem 0.05rem #f0f0f0;
  .item{
    border-bottom: 1px solid #ededed;
    padding:0.1rem 0;
    .item_item{
      display: flex;
      align-items: center;
      line-height: 0.25rem;;
      .label{
        width: 1.2rem;
      }
      .dec{
        flex:1;
      }
    }
  }
}
</style>
