<template>
  <div class="share wh100">
    <van-nav-bar title="邀请好友" left-arrow @click-left="onClickLeft" />
    <div class="content">
      <h2>邀请亲友加入亲友团</h2>
      <p>
        邀请亲友加入后，可与您一起编写逝者生平故事，留言、上传照片、跟您一起完善逝者信息、共同缅怀
      </p>
      <div class="share_item">
        <h3>方法一：长按海报发送朋友邀请加入</h3>
        <div class="item_content">
          <vue-canvas-poster
            :widthPixels="1000"
            :painting="painting"
            @success="success"
            @fail="fail"
          ></vue-canvas-poster>
          <div class="poster_box">
            <img
              v-if="showPoster&&detail.posterImg"
              class="poster_img"
              :src="detail.posterImg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="share_item">
        <h3>方法二：点击发送链接邀请亲朋加入</h3>
        <div class="item_content">
          <div class="large_btn" @click="wxShare">
            <span>点击发送链接邀请亲朋加入</span>
          </div>
        </div>
      </div>
    </div>
    <van-overlay class="overlay_box" :show="show" @click="show = false">
      <div class="mask_content" @click="show = false">
        <img
          class="arrow"
          :src="require('../../assets/imgs/arrow.png')"
          alt=""
        />
        <div class="mask_main">
          <div class="main_item">
            <div class="item_tip"><span>1</span></div>
            <div class="item_test">
              <span>点击右上角</span>
              <div class="item_btn">
                <span>. . .</span>
              </div>
              <span>按钮</span>
            </div>
          </div>
          <div class="main_item">
            <div class="item_tip"><span>2</span></div>
            <div class="item_test">
              <span>将本页面分享给好友</span>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <div id="qrcode"></div>
  </div>
</template>
<script>
import { NavBar } from "vant";
import { VueCanvasPoster } from "vue-canvas-poster";
import sdk from "@/utils/wxShare";
import { getShareData } from "@/api/memorialHall";
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      loading: null,
      painting: null,
      qrcode: "", //二维码
      defaultAvatar: require("@/assets/imgs/avatar.png"),
      defaultBg: require("@/assets/imgs/bg.png"),
      showPoster: false,
      show: false, //显示隐藏
      detail: {
        posterImg: null,
      },
    };
  },
  components: {
    NavBar,
    VueCanvasPoster,
  },
  created() {
    //console.log(this.detail.posterImg, "分享");
    this.getData(this.$route.params.id);
  },
  mounted() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      var u = navigator.userAgent,
        app = navigator.appVersion;
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        if (window.location.href.indexOf("#reloaded") == -1) {
          window.location.href = window.location.href + "#reloaded";
          window.location.reload();
        }
      }
    }
  },
  methods: {
    onClickLeft() {
      this.$router.push(`/memorialHall/${this.$route.params.id}`);
    },
    getCodeSrc(link) {
      return new Promise((resolve, reject) => {
        document.getElementById("qrcode").innerHTML = "";
        this.qrcode = new QRCode("qrcode", {
          width: 124,
          height: 124, // 高度
          text: link, // 二维码内容
          // render: 'canvas' ,   // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
          // background: '#f0f',   // 背景色
          // foreground: '#ff0'    // 前景色
        });
        console.log(this.qrcode, "kkkk");
        var canvas = this.qrcode._oDrawing._elCanvas;
        //如果有循环,此句必不可少 qrcode.find('canvas').remove();
        var src = canvas.toDataURL("image/jpg");
        resolve(src);
      });
    },
    async getData(id) {
      let params = {
        id,
      };
      let res = await getShareData(params);
      if (res.code != "2000") {
        this.$toast.fail(res.msg || "数据请求失败");
        return;
      }
      this.detail = res.data;
      this.ininShare(res.data);
      this.initPoster(res.data);
    },
    ininShare(data) {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        let obj = {
          title: `${data.deadName}的纪念馆`,
          des: `${data.biography}`,
          linkurl: `${process.env.VUE_APP_WEB_LINK}/memorialHall/${data.id}`, //分享链接
          img: `${data.deadPortraitUrl}`,
        };
        // let url = encodeURIComponent(location.href.split('#')[0]);
        let url = location.href.split("#")[0];
        sdk.getJSSDK(url, obj);
      } else {
      }
    },
    initPoster(data) {
      this.loading = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      this.getCodeSrc(
        `${process.env.VUE_APP_WEB_LINK}/memorialHall/${data.id}`
      ).then((res) => {
        this.painting = {
          width: "300px",
          height: "400px",
          borderRadius: "5px",
          background: this.defaultBg,
          views: [
            {
              type: "image",
              url: data.deadPortraitUrl
                ? `${data.deadPortraitUrl}`
                : this.defaultAvatar,
              css: {
                top: "20px",
                left: "120px",
                borderRadius: "30px",
                width: "60px",
                height: "60px",
              },
            },
            {
              type: "text",
              text: `${data.deadName}的纪念馆`,
              css: {
                top: "90px",
                fontSize: "14px",
                left: "105px",
                color: "#fff",
                textAlign: "center",
              },
            },
            {
              type: "text",
              text: `${data.birthday}—${data.deathday}`,
              css: {
                top: "120px",
                fontSize: "14px",
                left: "70px",
                color: "#fff",
                textAlign: "center",
              },
            },
            {
              type: "image",
              url: res,
              css: {
                background: "#fff",
                top: "250px",
                left: "100px",
                width: "100px",
                height: "100px",
                borderWidth: "6px",
                borderRadius: "1px",
                borderColor: "#fff",
              },
            },
            // {
            //   type: "qrcode",
            //   content: `http://http://renqiu.yunjisi.fushoubainian.com///memorialHall/${data.id}`,
            //   css: {
            //     background: "#fff",
            //     width: "100px",
            //     height: "100px",
            //     left: "100px",
            //     top: "210px",
            //     borderWidth: "10px",
            //     borderRadius: "1px",
            //     borderColor: "#fff",
            //   },
            // },
            {
              type: "text",
              text: "扫码查看在线纪念馆",
              css: {
                bottom: "20px",
                fontSize: "14px",
                left: "90px",
                color: "#fff",
                textAlign: "center",
              },
            },
          ],
        };
      });
    },
    success(src) {
      console.log(src, "哈哈哈哈");
      this.$toast.clear();
      this.detail.posterImg = src;
      this.showPoster = true;
    },
    fail(err) {
      console.log("fail", err);
    },
    wxShare() {
      this.show = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.share {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  /deep/ .van-nav-bar {
    background: $defaultColor;
    .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
  }
  #qrcode {
    display: none;
  }
  .content {
    flex: 1;
    padding: 0.05rem 0.37rem;
    // background-color: #3e3e3e;
    overflow-y: scroll;
    h2 {
      font-size: 14px;
      line-height: 0.2rem;
      font-weight: 600;
      margin-bottom: 0.07rem;
    }
    p {
      font-size: 14px;
      line-height: 0.2rem;
      color: $lightBlack;
      margin-bottom: 0.05rem;
    }
    .share_item {
      margin-bottom: 0.1rem;
      h3 {
        font-size: 14px;
        line-height: 0.2rem;
        font-weight: 600;
        margin-bottom: 0.07rem;
      }
      .item_content {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -moz-flex-direction: column;
        -webkit-flex-direction: column;
        flex-direction: column;
        -moz-align-items: center;
        -webkit-align-items: center;
        align-items: center;
        .poster_box {
          width: 3rem;
          height: 4rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.overlay_box {
  .mask_content {
    padding: 1rem 0.5rem;
    font-size: 14px;
    color: #fff;
  }
  .main_item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 0.2rem;
  }
  .item_test {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .item_tip {
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 50%;
    background: crimson;
    text-align: center;
    line-height: 0.2rem;
    margin-right: 0.15rem;
  }
  .item_btn {
    padding: 0.05rem 0.1rem;
    text-align: center;
    height: 0.3rem;
    background: #282d32;
    border-radius: 0.05rem;
    margin: 0 0.1rem;
  }
  .item_btn span {
    line-height: 0.2rem;
  }
  .arrow {
    position: absolute;
    right: 0.2rem;
    top: 0.2rem;
    display: block;
    width: 1rem;
    z-index: 6;
  }
}
</style>
