<template>
    <div>

    </div>
</template>

<script>
import {getOpenId} from "@/api/user";
    export default {
        created(){
            this.wxAuthorize()
        },
        methods: {
           getQueryString(name) {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        var r = window.location.search.substr(1).match(reg);
        if (r != null) {
            return unescape(r[2]);
        }
        return null;
    },
    // 截取code
    GetUrlParame(parameName) {
        /// 获取地址栏指定参数的值
        /// <param name="parameName">参数名</param>
        // 获取url中跟在问号后面的部分
        var parames = window.location.search
        // 检测参数是否存在
        if (parames.indexOf(parameName) > -1) {
            var parameValue = ''
            parameValue = parames.substring(parames.indexOf(parameName), parames.length)
            // 检测后面是否还有参数
            if (parameValue.indexOf('&') > -1) {
                // 去除后面多余的参数, 得到最终 parameName=parameValue 形式的值
                parameValue = parameValue.substring(0, parameValue.indexOf('&'))
                // 去掉参数名, 得到最终纯值字符串
                parameValue = parameValue.replace(parameName + '=', '')
                return parameValue
            }
            return ''
        }
    },
    wxAuthorize(){
       localStorage.setItem('firstLink',window.location.href)
        if (!this.getQueryString("code") && !localStorage.getItem('code')) {
            //let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx697f79dd0022a771&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect`
            let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx697f79dd0022a771&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
            window.location.href = url;
            console.log("第一次进入")
        } else if (!localStorage.getItem('code')) {
            console.log('第二次进入且路径中存在code')
            localStorage.setItem('code', this.GetUrlParame("code"));
            //后台请求微信返回openiD成功跳转登录界面
            let params = { code: localStorage.getItem('code') }
            console.log(params,'参数')
            getOpenId(params).then(res=>{
                console.log(res,'获取openId返回值')
                localStorage.setItem('openId',res.data.openId);
                localStorage.setItem('wxUser',JSON.stringify(res.data))
                this.$router.replace('/login')
            }).catch(err=>{
                console.log(err)
            })
        } else {
            console.log("2次以上进入")
        }
    }
        },
    }
</script>

<style lang="scss" scoped>

</style>
